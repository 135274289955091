import { useUserSessionContext } from '@momentum/contexts/UserSession'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { createProposal, updateProposal } from '@momentum/routes/proposals-create/mutations'
import { ProposalCreateForm } from '@momentum/routes/proposals-create/types'
import { convertToCreatorPricing } from '@momentum/utils/proposalUtils'
import { ProposalStatus, ValueTypes } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { uniqBy } from 'lodash'
import { v4 } from 'uuid'

export const useSaveProposal = (proposalId?: string) => {
  const { profile } = useUserSessionContext()
  const { setProposals } = useBrandContext()

  const handleSubmit = async (submitValues: ProposalCreateForm) => {
    const input: ValueTypes['ModelInputCreateProposal'] | ValueTypes['ModelInputUpdateProposal'] = {
      id: proposalId || v4(),
      title: submitValues.title,
      brandId: submitValues.brandId,
      durationWeeks: submitValues.durationWeeks,
      goal: submitValues.goal,
      estimatedUnitsSoldPerWeek: submitValues.estimatedUnitsSoldPerWeek,
      estimatedUnitsSoldPerMonth: submitValues.estimatedUnitsSoldPerMonth,
      productId: submitValues.productId,
      exchangeRate: submitValues.exchangeRate,
      productVariationSkus: submitValues.productVariationSkus,
      productPrice: submitValues.productPrice,
      productRating: submitValues.productRating,
      productRatingCount: submitValues.productRatingCount,
      productPriceOverride: submitValues.productPriceOverride ? submitValues.productPriceOverride : null,

      status: submitValues.isVisibleToClient ? ProposalStatus.PUBLISHED : ProposalStatus.DRAFT,
      statusChangedAt: submitValues.statusChangedAt || new Date().toISOString(),
      launchDate: submitValues.launchDate.toISODate()!,

      searchTerms: submitValues.searchTerms.filter(notEmpty),
      benchmarkProducts: submitValues.benchmarkProducts.filter(notEmpty),

      paymentType: submitValues.paymentType,

      invoiceDueDate: submitValues.invoiceDueDate?.toISODate() ?? null,
      invoiceLink: submitValues.invoiceLink,
      invoicePaidDate: submitValues.invoicePaidDate?.toISODate() ?? null,
      invoiceStatus: submitValues.invoiceStatus,
      invoicePONumber: submitValues.invoicePONumber,

      billingContacts: submitValues.billingContacts.filter(bc => bc.name && bc.email),
      isDailyScheduling: submitValues.isDailyScheduling,
      creatorPricing: convertToCreatorPricing(
        submitValues.creatorPricing.map(p => ({
          type: p.type,
          price: p.price,
          numCreators: p.numCreators,
          schedule: p.schedule,
          contentRequirements: p?.contentRequirements,
          socialCreatorPricing: p?.socialCreatorPricing
        }))
      ),

      totalCredits: submitValues.totalCredits,
      totalProductCostCredits: submitValues.totalProductCostCredits,

      updatedByUserId: profile.id,
      ...(!proposalId && { createdByUserId: profile.id }),

      estimatedAverageRating: submitValues.estimatedAverageRating,
      ratingGoal: submitValues.ratingGoal,
      hiddenNumReviews: submitValues.hiddenNumReviews,
      hiddenEndRating: submitValues.hiddenEndRating,
      hiddenPdpTraffic: submitValues.hiddenPdpTraffic,
      hiddenRetailEfficiency: submitValues.hiddenRetailEfficiency,
      hiddenSeo: submitValues.hiddenSeo,
      hiddenSocialContent: submitValues.hiddenSocialContent,
      hiddenUgcContent: submitValues.hiddenUgcContent,
      referralCost: submitValues.referralCost,
      operatingCost: submitValues.operatingCost,
      liftNumCreators: submitValues.liftNumCreators,
      liftCloseoutBonus: submitValues.liftCloseoutBonus,

      hubspotDealId: submitValues.hubspotDealLink
        ? new URL(submitValues.hubspotDealLink).pathname.split('/').pop()
        : undefined
    }

    const newProposal = proposalId
      ? await updateProposal(input)
      : await createProposal(input as ValueTypes['ModelInputCreateProposal'])

    setProposals(proposals => uniqBy([newProposal, ...(proposals || [])], 'id'))

    return newProposal
  }

  return { saveProposal: handleSubmit }
}
