import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { Stack, Typography } from '@mui/material'
import { sumBy } from 'lodash'
import { Metric } from '../../common/Metric'
import { useSeoContext } from '../context'
import { getCampaignSeoMetrics } from '@productwindtom/shared-momentum'

const MIN_AMAZON_CHOICE_BADGES = 3

export const OrganicRankSummary = () => {
  const { productSeoSummary, productKeywordSummaries } = useSeoContext()
  const { campaignDetails } = useCampaignContext()
  const { organicPageOneWinsSum, searchVolumeSum } = getCampaignSeoMetrics(campaignDetails.startDate, productSeoSummary)

  const improvedSlotsSum =
    productKeywordSummaries
      ?.map(k =>
        k.organicHighestRank && k.organicStartRank && k.organicHighestRank <= k.organicStartRank
          ? Math.abs(k.organicHighestRank - k.organicStartRank)
          : 0
      )
      .reduce((acc, curr) => acc + curr, 0) ?? 0
  const choiceBadgesSum = sumBy(productKeywordSummaries, k => ~~(k.hasAmazonChoiceBadge ?? false))

  return (
    <Stack spacing={3}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} width={'100%'}>
          <Typography variant={'h4'}>Campaign SEO summary</Typography>
        </Stack>
      </Stack>
      <Stack direction={'row'} justifyContent={'space-evenly'}>
        <Metric label={'organic search impressions'} value={searchVolumeSum.toLocaleString() ?? '--'} />
        <Metric label={'organic page 1 wins'} value={organicPageOneWinsSum.toLocaleString() ?? '--'} />
        <Metric label={'slots moved up in search results'} value={improvedSlotsSum.toLocaleString() ?? '--'} />
        {choiceBadgesSum >= MIN_AMAZON_CHOICE_BADGES && (
          <Metric label={'Amazon’s Choice badges'} value={choiceBadgesSum.toLocaleString() ?? '--'} />
        )}
      </Stack>
    </Stack>
  )
}
