/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const'

export const HOST = 'Specify host'

export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query)
    const wsString = queryString.replace('http', 'ws')
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString
    const webSocketOptions = options[1]?.websocket || [host]
    const ws = new WebSocket(...webSocketOptions)
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data)
            const data = parsed.data
            return e(data)
          }
        }
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e
      },
      open: (e: () => void) => {
        ws.onopen = e
      }
    }
  } catch {
    throw new Error('No websockets implemented')
  }
}
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then(text => {
          try {
            reject(JSON.parse(text))
          } catch (err) {
            reject(text)
          }
        })
        .catch(reject)
    })
  }
  return response.json() as Promise<GraphQLResponse>
}

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {}
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response)
          }
          return response.data
        })
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response)
        }
        return response.data
      })
  }

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars
}: {
  props: AllTypesPropsType
  returns: ReturnTypesType
  ops: Operations
  options?: OperationOptions
  scalars?: ScalarDefinition
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = []
  ): string => {
    const keyForPath = purifyGraphQLKey(k)
    const newPath = [p, keyForPath].join(SEPARATOR)
    if (!o) {
      return ''
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k
    }
    if (typeof o === 'string') {
      return `${k} ${o}`
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars
      })(o[0], newPath)
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}'
            )
          }
          const operationName = Object.keys(objectUnderAlias)[0]
          const operation = objectUnderAlias[operationName]
          return ibb(`${alias}:${operationName}`, operation, p, false, vars)
        })
        .join('\n')
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : ''
    const keyForDirectives = o.__directives ?? ''
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(e => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`
    }
    const varsString = vars.map(v => `${v.name}: ${v.graphQLType}`).join(', ')
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`
  }
  return ibb
}

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars
      }),
      ops?.variables
    ).then(data => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops
        })
      }
      return data
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options))

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars
      })
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops
              })
            )
          }
          return fnToCall(data)
        })
    }
    return returnedFunction
  }

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options))
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions
    scalars?: ScalarDefinition
  }
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars
  })(operation, o as VType)

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>()

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>()
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS
  }
})

export const ZeusScalars = ZeusSelect<ScalarCoders>()

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp
}: {
  ops: O
  response: any
  returns: ReturnTypesType
  scalars?: Record<string, ScalarResolver | undefined>
  initialOp: keyof O
  initialZeusQuery: InputValueType | VType
}) => {
  if (!scalars) {
    return response
  }
  const builder = PrepareScalarPaths({
    ops,
    returns
  })

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery)
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]])
    return r
  }
  return response
}

export const traverseResponse = ({
  resolvers,
  scalarPaths
}: {
  scalarPaths: { [x: string]: `scalar.${string}` }
  resolvers: {
    [x: string]: ScalarResolver | undefined
  }
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map(eachO => ibb(k, eachO, p))
    }
    if (o == null) {
      return o
    }
    const scalarPathString = p.join(SEPARATOR)
    const currentScalarString = scalarPaths[scalarPathString]
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode
      if (currentDecoder) {
        return currentDecoder(o)
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const)
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v
      return a
    }, {})
    return objectFromEntries
  }
  return ibb
}

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined
            }
      }
}

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined
      }
    | `scalar.${string}`
    | undefined
}
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType
}
export type VType = undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType

export type PlainType = boolean | number | string | null | undefined
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType
    }
  | Array<ZeusArgsType>

export type Operations = Record<string, string>

export type VariableDefinition = {
  [x: string]: unknown
}

export const SEPARATOR = '|'

export type fetchOptions = Parameters<typeof fetch>
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]]
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>
export type SubscriptionFunction = (query: string) => any
type NotUndefined<T> = T extends undefined ? never : T
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>

export type OperationOptions = {
  operationName?: string
}

export type ScalarCoder = Record<string, (s: unknown) => string>

export interface GraphQLResponse {
  data?: Record<string, any>
  errors?: Array<{
    message: string
  }>
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('')
    console.error(response)
  }
  override toString() {
    return 'GraphQL Response Error'
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? (typeof Ops)[O] : never
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders
}

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return
  }
  const oKey = mappedParts[0]
  const returnP1 = returns[oKey]
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]]
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns)
    }
    return undefined
  }
  return returnP1 as `scalar.${string}` | undefined
}

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey]
      const isScalar = ExtractScalar(extractionArray, returns)
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar
        }
        return partOfTree
      }
      return {}
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false)
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}'
            )
          }
          const operationName = Object.keys(objectUnderAlias)[0]
          const operation = objectUnderAlias[operationName]
          return ibb(alias, operationName, operation, p, pOriginals, false)
        })
        .reduce((a, b) => ({
          ...a,
          ...b
        }))
    }
    const keyName = root ? ops[k] : k
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false
        )
      })
      .reduce((a, b) => ({
        ...a,
        ...b
      }))
  }
  return ibb
}

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '')

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>')
  if (isField) {
    return {
      v: isField,
      __type: 'field'
    } as const
  }
  return {
    v: isArg,
    __type: 'arg'
  } as const
}

type Part = ReturnType<typeof mapPart>

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v]
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v]
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum'
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not'
      }
      const propsP2 = propsP1[mappedParts[1].v]
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map(mp => mp.v)
            .join(SEPARATOR)}`
        )
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not'
        }
        const propsP3 = propsP2[mappedParts[2].v]
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map(mp => mp.v)
              .join(SEPARATOR)}`
          )
        }
      }
    }
  }
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not'
    }
    const oKey = ops[mappedParts[0].v]
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v]
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not'
      const returnP2 = returnP1[mappedParts[1].v]
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map(mp => mp.v)
            .join(SEPARATOR)}`
        )
      }
    }
  }
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter(l => l.length > 0)
    const mappedParts = parts.map(mapPart)
    const propsP1 = ResolvePropsType(mappedParts)
    if (propsP1) {
      return propsP1
    }
    const returnP1 = ResolveReturnType(mappedParts)
    if (returnP1) {
      return returnP1
    }
    return 'not'
  }
  return rpp
}

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars
}: {
  props: AllTypesPropsType
  returns: ReturnTypesType
  ops: Operations
  scalars?: ScalarDefinition
  vars: Array<{ name: string; graphQLType: string }>
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR)
        const v = vars.find(v => v.name === varName)
        if (!v) {
          vars.push({
            name: varName,
            graphQLType
          })
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`
            )
          }
        }
        return varName
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p)
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.')
      const scalarKey = splittedScalar.join('.')
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a)
    }
    if (Array.isArray(a)) {
      return `[${a.map(arr => arb(arr, p, false)).join(', ')}]`
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a
      }
      return `${JSON.stringify(a)}`
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n')
      if (!root) {
        return `{${returnedObjectString}}`
      }
      return returnedObjectString
    }
    return `${a}`
  }
  return arb
}

export const resolverFor = <
  X,
  T extends keyof ResolverInputTypes,
  Z extends keyof ResolverInputTypes[T],
  RET = unknown
>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : RET
) => fn as (args?: any, source?: any) => RET

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>

export type WithTypeNameValue<T> = T & {
  __typename?: boolean
  __directives?: string
}
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>
}
type DeepAnify<T> = {
  [P in keyof T]?: any
}
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T
export type ScalarDefinition = Record<string, ScalarResolver>

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S
type IsArray<T, U, SCLR extends ScalarDefinition> =
  T extends Array<infer R> ? InputType<R, U, SCLR>[] : InputType<T, U, SCLR>
type FlattenArray<T> = T extends Array<infer R> ? R : T
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> =
  FlattenArray<SRC> extends ZEUS_INTERFACES | ZEUS_UNIONS
    ? {
        [P in keyof SRC]: SRC[P] extends '__union' & infer R
          ? P extends keyof DST
            ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
            : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : never, SCLR>
          : never
      }[keyof SRC] & {
        [P in keyof Omit<
          Pick<
            SRC,
            {
              [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P
            }[keyof DST]
          >,
          '__typename'
        >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>
      }
    : {
        [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
          ? IsScalar<SRC[P], SCLR>
          : IsArray<SRC[P], DST[P], SCLR>
      }

export type MapType<SRC, DST, SCLR extends ScalarDefinition> =
  SRC extends DeepAnify<DST> ? IsInterfaced<SRC, DST, SCLR> : never
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> =
  IsPayLoad<DST> extends { __alias: infer R }
    ? {
        [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>]
      } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
    : MapType<SRC, IsPayLoad<DST>, SCLR>
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void
  open: () => void
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>

export type ScalarResolver = {
  encode?: (s: unknown) => string
  decode?: (s: unknown) => unknown
}

export type SelectionFunction<V> = <T>(t: T | V) => T

type BuiltInVariableTypes = {
  ['String']: string
  ['Int']: number
  ['Float']: number
  ['ID']: unknown
  ['Boolean']: boolean

  ['AWSJSON']: string
  ['AWSDateTime']: string
  ['AWSDate']: string
  ['AWSTime']: string
  ['AWSEmail']: string
  ['AWSPhone']: string
  ['AWSURL']: string
  ['AWSIpAddress']: string
  ['AWSTimestamp']: number
}
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`
type VR<T extends string> = VariableRequired<VariableRequired<T>>

export type GraphQLVariableType = VR<AllVariableTypes>

type ExtractVariableTypeString<T extends string> =
  T extends VR<infer R1>
    ? R1 extends VR<infer R2>
      ? R2 extends VR<infer R3>
        ? R3 extends VR<infer R4>
          ? R4 extends VR<infer R5>
            ? R5
            : R4
          : R3
        : R2
      : R1
    : T

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
    ? NonNullable<DecomposeType<R, Type>>
    : Type | undefined

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
    ? BuiltInVariableTypes[T]
    : any

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K
}[keyof T]

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>

type OptionalKeys<T> = {
  [P in keyof T]?: T[P]
}

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name
  ' __zeus_type': T
}

export type ExtractVariables<Query> =
  Query extends Variable<infer VType, infer VName>
    ? { [key in VName]: GetVariableType<VType> }
    : Query extends [infer Inputs, infer Outputs]
      ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
      : Query extends string | number | boolean
        ? // eslint-disable-next-line @typescript-eslint/ban-types
          {}
        : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never

export const START_VAR_NAME = `$ZEUS_VAR`
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>
}
type ZEUS_INTERFACES = never
export type ScalarCoders = {}
type ZEUS_UNIONS = never

export type ValueTypes = {
  ['AdjustCampaignStartDateOutput']: AliasType<{
    startDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Agency']: AliasType<{
    clientPricing?: ValueTypes['Pricing']
    clientPricingId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    logo?: boolean | `@${string}`
    name?: boolean | `@${string}`
    paymentTermsCustomNetDays?: boolean | `@${string}`
    paymentTermsType?: boolean | `@${string}`
    pricing?: ValueTypes['Pricing']
    updatedAt?: boolean | `@${string}`
    websiteUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ApproveContentResonse']: AliasType<{
    userCampaignSocialContentId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BillingContact']: AliasType<{
    email?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Brand']: AliasType<{
    amazonBrandName?: boolean | `@${string}`
    amazonBrandStoreUrl?: boolean | `@${string}`
    brandApis?: ValueTypes['BrandApi']
    companyId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isBrandStoreScrapeEnabled?: boolean | `@${string}`
    isWalmartScrapeEnabled?: boolean | `@${string}`
    lastScrapedAt?: boolean | `@${string}`
    logo?: boolean | `@${string}`
    name?: boolean | `@${string}`
    nameAudioKey?: boolean | `@${string}`
    pricing?: ValueTypes['Pricing']
    products?: ValueTypes['ModelItemsProduct']
    proposals?: ValueTypes['Proposal']
    region?: boolean | `@${string}`
    subscriptionCredits?: ValueTypes['SubscriptionCreditAction']
    subscriptionEndsAt?: boolean | `@${string}`
    subscriptionStartsAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    walmartBrandName?: boolean | `@${string}`
    walmartLastScrapedAt?: boolean | `@${string}`
    walmartScrapeUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BrandApi']: AliasType<{
    accessToken?: boolean | `@${string}`
    api?: boolean | `@${string}`
    apiVersion?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    enabled?: boolean | `@${string}`
    isIntegrated?: boolean | `@${string}`
    refreshToken?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Campaign']: AliasType<{
    brandId?: boolean | `@${string}`
    cost?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    endDate?: boolean | `@${string}`
    goal?: boolean | `@${string}`
    id?: boolean | `@${string}`
    numCreators?: boolean | `@${string}`
    numCreatorsJoined?: boolean | `@${string}`
    preLaunchDate?: boolean | `@${string}`
    product?: ValueTypes['Product']
    proposal?: ValueTypes['Proposal']
    skuId?: boolean | `@${string}`
    startDate?: boolean | `@${string}`
    title?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignBenchmark']: AliasType<{
    benchmarkGoal?: boolean | `@${string}`
    benchmarkType?: boolean | `@${string}`
    benchmarkedProductIds?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignClick']: AliasType<{
    clicks?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignCreator']: AliasType<{
    content?: ValueTypes['ContentGroup']
    creator?: ValueTypes['CampaignCreatorCreator']
    creatorType?: boolean | `@${string}`
    leftProductFeedback?: boolean | `@${string}`
    purchaseDetails?: ValueTypes['PurchaseDetails']
    status?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignCreatorCreator']: AliasType<{
    email?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    id?: boolean | `@${string}`
    instagramProfile?: ValueTypes['SocialProfile']
    lastName?: boolean | `@${string}`
    sponsoredPosts?: boolean | `@${string}`
    tiktokProfile?: ValueTypes['SocialProfile']
    youtubeProfile?: ValueTypes['SocialProfile']
    __typename?: boolean | `@${string}`
  }>
  ['CampaignCreatorForApproval']: AliasType<{
    approvalState?: ValueTypes['CreatorApprovalState']
    creator?: ValueTypes['CampaignCreatorCreator']
    creatorType?: boolean | `@${string}`
    dateOfBirth?: boolean | `@${string}`
    locale?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignDetails']: AliasType<{
    anticipatedStartDate?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    cost?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    creators?: ValueTypes['CampaignCreator']
    endDate?: boolean | `@${string}`
    expectedClicksCounts?: boolean | `@${string}`
    expectedPlacementWins?: boolean | `@${string}`
    expectedPremiumUgcPostCount?: boolean | `@${string}`
    expectedReviewsCount?: boolean | `@${string}`
    expectedSocialPostCount?: boolean | `@${string}`
    expectedUgcPostCount?: boolean | `@${string}`
    goal?: boolean | `@${string}`
    id?: boolean | `@${string}`
    liftCampaignId?: boolean | `@${string}`
    numAdditionalBrandAdvocateCreators?: boolean | `@${string}`
    numAdditionalBrandAdvocateCreatorsJoined?: boolean | `@${string}`
    numBrandAdvocateCreators?: boolean | `@${string}`
    numCreators?: boolean | `@${string}`
    numCreatorsJoined?: boolean | `@${string}`
    numPremiumUgcCreators?: boolean | `@${string}`
    numSocialCreators?: boolean | `@${string}`
    numUgcCreators?: boolean | `@${string}`
    preLaunchDate?: boolean | `@${string}`
    product?: ValueTypes['Product']
    proposal?: ValueTypes['Proposal']
    releaseSchedule?: ValueTypes['ReleaseSchedule']
    skuId?: boolean | `@${string}`
    startDate?: boolean | `@${string}`
    title?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignPerformance']: AliasType<{
    campaignId?: boolean | `@${string}`
    comparisonMetric?: boolean | `@${string}`
    comparisonMetricValue?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    isBsrDataUnavailable?: boolean | `@${string}`
    isBsrVisible?: boolean | `@${string}`
    isTtrDataUnavailable?: boolean | `@${string}`
    isTtrVisible?: boolean | `@${string}`
    isVisible?: boolean | `@${string}`
    products?: ValueTypes['CampaignPerformanceProduct']
    promotedProductId?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    summaryTitle?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignPerformanceProduct']: AliasType<{
    bestSellerRankDataUploaded?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    comparisonMetric?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isCategoryAverage?: boolean | `@${string}`
    isVisible?: boolean | `@${string}`
    productImage?: boolean | `@${string}`
    productName?: boolean | `@${string}`
    productPerformanceRecords?: ValueTypes['ProductPerformanceRecord']
    productUrl?: boolean | `@${string}`
    reviewDataUploaded?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignProductReviewRatingMetric']: AliasType<{
    fromDate?: boolean | `@${string}`
    momentumRating?: boolean | `@${string}`
    momentumRatingCount?: boolean | `@${string}`
    momentumReviewCount?: boolean | `@${string}`
    momentumReviewRating?: boolean | `@${string}`
    numRatings?: boolean | `@${string}`
    numReviews?: boolean | `@${string}`
    organicRating?: boolean | `@${string}`
    organicRatingCount?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    ratingsInPeriod?: boolean | `@${string}`
    reviews?: ValueTypes['Review']
    reviewsInPeriod?: boolean | `@${string}`
    toDate?: boolean | `@${string}`
    vineRating?: boolean | `@${string}`
    vineReviewCount?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CategoryRank']: AliasType<{
    category?: boolean | `@${string}`
    rank?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Company']: AliasType<{
    agency?: ValueTypes['Agency']
    agencyId?: boolean | `@${string}`
    brands?: ValueTypes['Brand']
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    logo?: boolean | `@${string}`
    name?: boolean | `@${string}`
    paymentTermsCustomNetDays?: boolean | `@${string}`
    paymentTermsType?: boolean | `@${string}`
    pricing?: ValueTypes['Pricing']
    updatedAt?: boolean | `@${string}`
    websiteUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Content']: AliasType<{
    assets?: ValueTypes['ContentAsset']
    caption?: boolean | `@${string}`
    comments?: boolean | `@${string}`
    engagements?: boolean | `@${string}`
    id?: boolean | `@${string}`
    likes?: boolean | `@${string}`
    qualityScore?: boolean | `@${string}`
    views?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentAsset']: AliasType<{
    type?: boolean | `@${string}`
    url?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentGroup']: AliasType<{
    channel?: boolean | `@${string}`
    content?: ValueTypes['Content']
    contentType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    groupId?: boolean | `@${string}`
    isFavorite?: boolean | `@${string}`
    isHidden?: boolean | `@${string}`
    platformContentLink?: boolean | `@${string}`
    submittedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentRequirement']: AliasType<{
    bonus?: boolean | `@${string}`
    contentType?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreateCampaignOutput']: AliasType<{
    id?: boolean | `@${string}`
    invoiceLink?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreateProposalSlideResult']: AliasType<{
    link?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatedByUser']: AliasType<{
    firstName?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Creator']: AliasType<{
    email?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    id?: boolean | `@${string}`
    instagramProfile?: ValueTypes['SocialProfile']
    lastName?: boolean | `@${string}`
    tiktokProfile?: ValueTypes['SocialProfile']
    youtubeProfile?: ValueTypes['SocialProfile']
    __typename?: boolean | `@${string}`
  }>
  ['CreatorApprovalState']: AliasType<{
    feedback?: boolean | `@${string}`
    reviewedByUserEmail?: boolean | `@${string}`
    reviewedByUserId?: boolean | `@${string}`
    reviewedByUserName?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorContent']: AliasType<{
    content?: ValueTypes['ContentGroup']
    creator?: ValueTypes['Creator']
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorDraftContent']: AliasType<{
    creator?: ValueTypes['Creator']
    draftContent?: ValueTypes['DraftContent']
    id?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorPricing']: AliasType<{
    price?: boolean | `@${string}`
    type?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['DraftContent']: AliasType<{
    channel?: boolean | `@${string}`
    contentType?: boolean | `@${string}`
    draftMedia?: boolean | `@${string}`
    draftStatus?: boolean | `@${string}`
    draftSubmittedAt?: boolean | `@${string}`
    draftText?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isHidden?: boolean | `@${string}`
    reviewFeedback?: ValueTypes['ReviewFeedback']
    reviewedAt?: boolean | `@${string}`
    reviewerFirstName?: boolean | `@${string}`
    reviewerLastName?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['EndCampaignOutput']: AliasType<{
    endDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['EtailerProductMetrics']: AliasType<{
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    pageViews?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    totalSalesAmount?: boolean | `@${string}`
    unitsSold?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['LambdaResponse']: AliasType<{
    code?: boolean | `@${string}`
    message?: boolean | `@${string}`
    success?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ListingBadgeRecord']: AliasType<{
    badge?: boolean | `@${string}`
    date?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsAgency']: AliasType<{
    items?: ValueTypes['Agency']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsBrand']: AliasType<{
    items?: ValueTypes['Brand']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsBrandApi']: AliasType<{
    items?: ValueTypes['BrandApi']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsCampaignClick']: AliasType<{
    items?: ValueTypes['CampaignClick']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsCompany']: AliasType<{
    items?: ValueTypes['Company']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsEtailerProductMetrics']: AliasType<{
    items?: ValueTypes['EtailerProductMetrics']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsPricing']: AliasType<{
    items?: ValueTypes['Pricing']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProduct']: AliasType<{
    items?: ValueTypes['Product']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProductKeywordRecord']: AliasType<{
    items?: ValueTypes['ProductKeywordRecord']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProductKeywordSummary']: AliasType<{
    items?: ValueTypes['ProductKeywordSummary']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProductRatingSnapshot']: AliasType<{
    items?: ValueTypes['ProductRatingSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProductRecommendation']: AliasType<{
    items?: ValueTypes['ProductRecommendation']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProposal']: AliasType<{
    items?: ValueTypes['Proposal']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsScrapeRequestJob']: AliasType<{
    items?: ValueTypes['ScrapeRequestJob']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsSubscriptionCreditAction']: AliasType<{
    items?: ValueTypes['SubscriptionCreditAction']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUser']: AliasType<{
    items?: ValueTypes['User']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUserAgency']: AliasType<{
    items?: ValueTypes['UserAgency']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUserBrand']: AliasType<{
    items?: ValueTypes['UserBrand']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUserCompany']: AliasType<{
    items?: ValueTypes['UserCompany']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUserInvitation']: AliasType<{
    items?: ValueTypes['UserInvitation']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSearchItemssearchBrandContent']: AliasType<{
    items?: ValueTypes['CreatorContent']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSearchItemssearchBrandCounts']: AliasType<{
    items?: ValueTypes['SearchBrandCountsOutput']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSearchItemssearchBrandProducts']: AliasType<{
    items?: ValueTypes['Product']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSearchItemssearchRecommendations']: AliasType<{
    items?: ValueTypes['Product']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Mutation']: AliasType<{
    adjustCampaignStartDate?: [
      { input: ValueTypes['AdjustCampaignStartDateInput'] },
      ValueTypes['AdjustCampaignStartDateOutput']
    ]
    adjustProductRecommendationVisibility?: [
      { input: ValueTypes['AdjustProductRecommendationVisibilityInput'] },
      ValueTypes['Product']
    ]
    adjustSubscriptionCredits?: [{ input: ValueTypes['AdjustCreditInput'] }, ValueTypes['SubscriptionCreditAction']]
    approveContent?: [{ input: ValueTypes['ApproveContentInput'] }, ValueTypes['ApproveContentResonse']]
    createAgency?: [{ input: ValueTypes['ModelInputCreateAgency'] }, ValueTypes['Agency']]
    createBrand?: [{ input: ValueTypes['CreateBrandInput'] }, ValueTypes['Brand']]
    createCampaign?: [{ proposalId: string }, ValueTypes['CreateCampaignOutput']]
    createCampaignBenchmark?: [
      { input: ValueTypes['ModelInputCreateCampaignBenchmark'] },
      ValueTypes['CampaignBenchmark']
    ]
    createCampaignPerformance?: [
      { input: ValueTypes['ModelInputCreateCampaignPerformance'] },
      ValueTypes['CampaignPerformance']
    ]
    createCampaignPerformanceProduct?: [
      { input: ValueTypes['ModelInputCreateCampaignPerformanceProduct'] },
      ValueTypes['CampaignPerformanceProduct']
    ]
    createCompany?: [{ input: ValueTypes['ModelInputCreateCompany'] }, ValueTypes['Company']]
    createManualProduct?: [{ input: ValueTypes['ProductInput'] }, ValueTypes['Product']]
    createPWProductSeoSummary?: [
      { input: ValueTypes['ModelInputCreatePWProductSeoSummary'] },
      ValueTypes['PWProductSeoSummary']
    ]
    createPricing?: [{ input: ValueTypes['ModelInputCreatePricing'] }, ValueTypes['Pricing']]
    createProductFromUrl?: [{ input: ValueTypes['CreateProductFromUrlInput'] }, ValueTypes['Product']]
    createProposal?: [{ input: ValueTypes['ModelInputCreateProposal'] }, ValueTypes['Proposal']]
    createProposalSlide?: [{ input: ValueTypes['CreateProposalSlideInput'] }, ValueTypes['CreateProposalSlideResult']]
    createScrapeRequestJob?: [{ input: ValueTypes['ModelInputCreateScrapeRequestJob'] }, ValueTypes['ScrapeRequestJob']]
    deactivateUser?: [{ id: string }, ValueTypes['User']]
    deleteCampaignPerformance?: [
      { input: ValueTypes['ModelInputDeleteCampaignPerformance'] },
      ValueTypes['CampaignPerformance']
    ]
    deleteCampaignPerformanceProduct?: [
      { input: ValueTypes['ModelInputDeleteCampaignPerformanceProduct'] },
      ValueTypes['CampaignPerformanceProduct']
    ]
    deletePricing?: [{ input: ValueTypes['ModelInputDeletePricing'] }, ValueTypes['Pricing']]
    deleteProposal?: [{ input: ValueTypes['ModelInputDeleteProposal'] }, ValueTypes['Proposal']]
    deleteUserInvitation?: [{ input: ValueTypes['ModelInputDeleteUserInvitation'] }, ValueTypes['UserInvitation']]
    endCampaign?: [{ campaignId: string }, ValueTypes['EndCampaignOutput']]
    generateFeedbackReport?: [{ campaignId: string }, ValueTypes['ProductFeedbackSummary']]
    inviteUserToMomentum?: [{ input: ValueTypes['UserInvitationInput'] }, ValueTypes['UserInvitation']]
    pingActivity?: [{ userId?: string | undefined | null }, boolean | `@${string}`]
    reactivateUser?: [{ id: string }, ValueTypes['User']]
    refreshRecommendations?: [
      { brandId: string; stores: Array<ValueTypes['Store']> },
      ValueTypes['RefreshRecommendationsOutput']
    ]
    requestContentEdits?: [{ input: ValueTypes['RequestContentEditsInput'] }, ValueTypes['ReviewFeedback']]
    requestUploadFileLink?: [
      {
        additionalParameters?: string | undefined | null
        extension: string
        uploadRequestType: ValueTypes['UploadRequestType']
      },
      ValueTypes['RequestUploadFileLinkResult']
    ]
    resetPassword?: [{ code: string; password: string; username: string }, ValueTypes['LambdaResponse']]
    signupUser?: [
      { attributes?: string | undefined | null; password: string; username: string },
      ValueTypes['LambdaResponse']
    ]
    submitCampaignCreatorApproval?: [
      { input: ValueTypes['SubmitCampaignCreatorApprovalInput'] },
      ValueTypes['CampaignCreatorForApproval']
    ]
    toggleContentVisibility?: [
      { input: ValueTypes['ToggleContentVisibilityInput'] },
      ValueTypes['ToggleContentVisibilityResonse']
    ]
    updateAgency?: [{ input: ValueTypes['ModelInputUpdateAgency'] }, ValueTypes['Agency']]
    updateBrand?: [{ input: ValueTypes['UpdateBrandInput'] }, ValueTypes['Brand']]
    updateCampaignBenchmark?: [
      { input: ValueTypes['ModelInputUpdateCampaignBenchmark'] },
      ValueTypes['CampaignBenchmark']
    ]
    updateCampaignPerformance?: [
      { input: ValueTypes['ModelInputUpdateCampaignPerformance'] },
      ValueTypes['CampaignPerformance']
    ]
    updateCampaignPerformanceProduct?: [
      { input: ValueTypes['ModelInputUpdateCampaignPerformanceProduct'] },
      ValueTypes['CampaignPerformanceProduct']
    ]
    updateCompany?: [{ input: ValueTypes['ModelInputUpdateCompany'] }, ValueTypes['Company']]
    updateManualProduct?: [{ input: ValueTypes['UpdateManualProductInput'] }, ValueTypes['Product']]
    updatePWProductSeoSummary?: [
      { input: ValueTypes['ModelInputUpdatePWProductSeoSummary'] },
      ValueTypes['PWProductSeoSummary']
    ]
    updatePricing?: [{ input: ValueTypes['ModelInputUpdatePricing'] }, ValueTypes['Pricing']]
    updateProductKeywordSummary?: [
      { input: ValueTypes['ModelInputUpdateProductKeywordSummary'] },
      ValueTypes['ProductKeywordSummary']
    ]
    updateProductSeoSummary?: [
      { input: ValueTypes['ModelInputUpdateProductSeoSummary'] },
      ValueTypes['ProductSeoSummary']
    ]
    updateProposal?: [{ input: ValueTypes['ModelInputUpdateProposal'] }, ValueTypes['Proposal']]
    updateScrapeRequestJob?: [{ input: ValueTypes['ModelInputUpdateScrapeRequestJob'] }, ValueTypes['ScrapeRequestJob']]
    updateUser?: [{ input: ValueTypes['ModelInputUpdateUser'] }, ValueTypes['User']]
    updateUserAgency?: [{ input: ValueTypes['ModelInputUpdateUserAgency'] }, ValueTypes['UserAgency']]
    updateUserCampaignSocialContent?: [
      { input: ValueTypes['ModelInputUpdateUserCampaignSocialContent'] },
      ValueTypes['UserCampaignSocialContent']
    ]
    updateUserInvitation?: [{ input: ValueTypes['ModelInputUpdateUserInvitation'] }, ValueTypes['UserInvitation']]
    uploadCreatorContent?: [{ input: ValueTypes['UploadCreatorContentInput'] }, ValueTypes['ContentGroup']]
    uploadProductSeoData?: [{ input: ValueTypes['UploadProductSeoDataInput'] }, ValueTypes['ProductSeoSummary']]
    __typename?: boolean | `@${string}`
  }>
  ['PWProductSEOSummaryKeyword']: AliasType<{
    badges?: ValueTypes['ListingBadgeRecord']
    highestRank?: boolean | `@${string}`
    impressions?: boolean | `@${string}`
    keyword?: boolean | `@${string}`
    pageOneWin?: boolean | `@${string}`
    pageOneWinDate?: boolean | `@${string}`
    sponsoredHighestRank?: boolean | `@${string}`
    sponsoredStartRank?: boolean | `@${string}`
    startRank?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PWProductSEOSummaryRecord']: AliasType<{
    categoryRanks?: ValueTypes['CategoryRank']
    impressions?: boolean | `@${string}`
    isOutOfStock?: boolean | `@${string}`
    topPageWins?: boolean | `@${string}`
    weekIndex?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PWProductSeoSummary']: AliasType<{
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    keywords?: ValueTypes['PWProductSEOSummaryKeyword']
    productSEOSummaryRecords?: ValueTypes['PWProductSEOSummaryRecord']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Pricing']: AliasType<{
    createdAt?: boolean | `@${string}`
    creatorPricing?: ValueTypes['CreatorPricing']
    id?: boolean | `@${string}`
    store?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Product']: AliasType<{
    active?: boolean | `@${string}`
    availabilityDate?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    categories?: boolean | `@${string}`
    childSkus?: ValueTypes['ModelItemsProduct']
    createdAt?: boolean | `@${string}`
    createdByUserId?: boolean | `@${string}`
    creationSource?: boolean | `@${string}`
    creationSourceUrl?: boolean | `@${string}`
    estimatedAvailabilityDate?: boolean | `@${string}`
    id?: boolean | `@${string}`
    image?: boolean | `@${string}`
    initialEtailerProductMetric?: ValueTypes['EtailerProductMetrics']
    isRecommendationsHidden?: boolean | `@${string}`
    itemClassification?: boolean | `@${string}`
    lastScrapedAt?: boolean | `@${string}`
    listingFulfillmentType?: boolean | `@${string}`
    listingLink?: boolean | `@${string}`
    name?: boolean | `@${string}`
    parentSku?: ValueTypes['Product']
    parentSkuId?: boolean | `@${string}`
    price?: boolean | `@${string}`
    priceCents?: boolean | `@${string}`
    productImageKey?: boolean | `@${string}`
    ratingSnapshots?: ValueTypes['ModelItemsProductRatingSnapshot']
    ratingSummary?: ValueTypes['ProductRatingSnapshot']
    recommendations?: ValueTypes['ProductRecommendation']
    skuId?: boolean | `@${string}`
    store?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductFeedbackSummary']: AliasType<{
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    failureReason?: boolean | `@${string}`
    jsonDownloadUrl?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductKeywordRecord']: AliasType<{
    campaignId?: boolean | `@${string}`
    campaignId_keyword?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    keyword?: boolean | `@${string}`
    keywordSales?: boolean | `@${string}`
    organicRank?: boolean | `@${string}`
    searchVolume?: boolean | `@${string}`
    sponsoredRank?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductKeywordSummary']: AliasType<{
    campaignId?: boolean | `@${string}`
    campaignId_keyword?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    hasAmazonChoiceBadge?: boolean | `@${string}`
    keyword?: boolean | `@${string}`
    organicHighestRank?: boolean | `@${string}`
    organicPageOneWin?: boolean | `@${string}`
    organicPageOneWinDate?: boolean | `@${string}`
    organicStartRank?: boolean | `@${string}`
    records?: ValueTypes['ProductKeywordRecord']
    searchVolume?: boolean | `@${string}`
    sponsoredHighestRank?: boolean | `@${string}`
    sponsoredPageOneWin?: boolean | `@${string}`
    sponsoredPageOneWinDate?: boolean | `@${string}`
    sponsoredStartRank?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductPerformanceRecord']: AliasType<{
    date?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    reviewCount?: boolean | `@${string}`
    salesRank?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductRatingSnapshot']: AliasType<{
    numRatings?: boolean | `@${string}`
    numReviews?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    snapshotAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductRecommendation']: AliasType<{
    createdAt?: boolean | `@${string}`
    recommendationType?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductSeoSummary']: AliasType<{
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    productSeoSummaryRecords?: ValueTypes['ProductSeoSummaryRecord']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductSeoSummaryRecord']: AliasType<{
    fromDate?: boolean | `@${string}`
    isOutOfStock?: boolean | `@${string}`
    organicPageOneWins?: boolean | `@${string}`
    searchVolume?: boolean | `@${string}`
    sponsoredPageOneWins?: boolean | `@${string}`
    toDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Proposal']: AliasType<{
    benchmarkProducts?: boolean | `@${string}`
    billingContacts?: ValueTypes['BillingContact']
    brand?: ValueTypes['Brand']
    brandId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    createdBy?: ValueTypes['User']
    createdByUserId?: boolean | `@${string}`
    creatorPricing?: ValueTypes['ProposalCreatorPricing']
    creditActionId?: boolean | `@${string}`
    durationWeeks?: boolean | `@${string}`
    estimatedAverageRating?: boolean | `@${string}`
    estimatedUnitsSoldPerMonth?: boolean | `@${string}`
    estimatedUnitsSoldPerWeek?: boolean | `@${string}`
    exchangeRate?: boolean | `@${string}`
    goal?: boolean | `@${string}`
    hiddenEndRating?: boolean | `@${string}`
    hiddenNumReviews?: boolean | `@${string}`
    hiddenPdpTraffic?: boolean | `@${string}`
    hiddenRetailEfficiency?: boolean | `@${string}`
    hiddenSeo?: boolean | `@${string}`
    hiddenSocialContent?: boolean | `@${string}`
    hiddenUgcContent?: boolean | `@${string}`
    hubspotDealId?: boolean | `@${string}`
    id?: boolean | `@${string}`
    invoiceDueDate?: boolean | `@${string}`
    invoiceId?: boolean | `@${string}`
    invoiceLink?: boolean | `@${string}`
    invoiceNumber?: boolean | `@${string}`
    invoicePONumber?: boolean | `@${string}`
    invoicePaidDate?: boolean | `@${string}`
    invoiceStatus?: boolean | `@${string}`
    isDailyScheduling?: boolean | `@${string}`
    launchDate?: boolean | `@${string}`
    liftCloseoutBonus?: boolean | `@${string}`
    liftNumCreators?: boolean | `@${string}`
    operatingCost?: boolean | `@${string}`
    paymentType?: boolean | `@${string}`
    product?: ValueTypes['Product']
    productId?: boolean | `@${string}`
    productPrice?: boolean | `@${string}`
    productPriceOverride?: boolean | `@${string}`
    productRating?: boolean | `@${string}`
    productRatingCount?: boolean | `@${string}`
    productVariationSkus?: boolean | `@${string}`
    ratingGoal?: boolean | `@${string}`
    referralCost?: boolean | `@${string}`
    searchTerms?: boolean | `@${string}`
    status?: boolean | `@${string}`
    statusChangedAt?: boolean | `@${string}`
    submittedAt?: boolean | `@${string}`
    submittedBy?: ValueTypes['User']
    submittedByUserId?: boolean | `@${string}`
    title?: boolean | `@${string}`
    totalCredits?: boolean | `@${string}`
    totalCreditsPaid?: boolean | `@${string}`
    totalProductCostCredits?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    updatedBy?: ValueTypes['User']
    updatedByUserId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProposalCreatorPricing']: AliasType<{
    closeoutBonus?: boolean | `@${string}`
    contentRequirements?: ValueTypes['ContentRequirement']
    hidden?: boolean | `@${string}`
    numCreators?: boolean | `@${string}`
    price?: boolean | `@${string}`
    schedule?: boolean | `@${string}`
    type?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PurchaseDetails']: AliasType<{
    amountCents?: boolean | `@${string}`
    purchaseDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Query']: AliasType<{
    companiesByAgency?: [
      {
        agencyId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsCompany']
    ]
    generateCompanyRollupReport?: [{ companyId: string }, boolean | `@${string}`]
    getAgency?: [{ id: string }, ValueTypes['Agency']]
    getAmazonAuthorizationUri?: [
      { brandApiType: ValueTypes['BrandApiType']; brandId: string; redirectUri: string },
      boolean | `@${string}`
    ]
    getBenchmarkMetrics?: [
      { benchmarkGoal: number; benchmarkType: ValueTypes['BenchmarkType']; skuId: string },
      ValueTypes['EtailerProductMetrics']
    ]
    getBrand?: [{ id: string }, ValueTypes['Brand']]
    getBrandApi?: [{ api: ValueTypes['BrandApiType']; brandId: string }, ValueTypes['BrandApi']]
    getCampaign?: [{ campaignId: string }, ValueTypes['CampaignDetails']]
    getCampaignBenchmark?: [{ campaignId: string; skuId: string }, ValueTypes['CampaignBenchmark']]
    getCampaignPerformance?: [{ campaignId: string; skuId: string }, ValueTypes['CampaignPerformance']]
    getCampaignPerformanceProduct?: [{ id: string }, ValueTypes['CampaignPerformanceProduct']]
    getCampaignReviewMetrics?: [
      { campaignId: string; endDate?: string | undefined | null; startDate: string },
      ValueTypes['CampaignProductReviewRatingMetric']
    ]
    getCompany?: [{ id: string }, ValueTypes['Company']]
    getPWProductSeoSummary?: [{ campaignId: string }, ValueTypes['PWProductSeoSummary']]
    getPricing?: [{ id: string; store: ValueTypes['Store'] }, ValueTypes['Pricing']]
    getProduct?: [{ id: string }, ValueTypes['Product']]
    getProductFeedbackSummary?: [{ campaignId: string }, ValueTypes['ProductFeedbackSummary']]
    getProductRecommendation?: [
      { recommendationType: ValueTypes['RecommendationType']; skuId: string },
      ValueTypes['ProductRecommendation']
    ]
    getProductSeoSummary?: [{ campaignId: string }, ValueTypes['ProductSeoSummary']]
    getProposal?: [{ id: string }, ValueTypes['Proposal']]
    getUser?: [{ id: string }, ValueTypes['User']]
    getUserAgency?: [{ agencyId: string; userId: string }, ValueTypes['UserAgency']]
    getUserBrand?: [{ brandId: string; userId: string }, ValueTypes['UserBrand']]
    getUserCompany?: [{ companyId: string; userId: string }, ValueTypes['UserCompany']]
    getUserInvitation?: [{ id: string }, ValueTypes['UserInvitation']]
    listAgencies?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsAgency']
    ]
    listBrandApis?: [
      {
        api?: ValueTypes['ModelBrandApiSkKeyConditionInput'] | undefined | null
        brandId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsBrandApi']
    ]
    listBrandCampaigns?: [{ brandId?: string | undefined | null }, ValueTypes['Campaign']]
    listBrandsByActive?: [
      {
        active?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsBrand']
    ]
    listBrandsByActiveBySubscriptionEndsAt?: [
      {
        active?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        subscriptionEndsAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsBrand']
    ]
    listBrandsByCompanyId?: [
      {
        companyId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsBrand']
    ]
    listCampaignClicks?: [
      {
        campaignId?: string | undefined | null
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsCampaignClick']
    ]
    listCampaignCreatorsForApproval?: [{ campaignId: string }, ValueTypes['CampaignCreatorForApproval']]
    listCompanies?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsCompany']
    ]
    listContentPendingApproval?: [{ campaignId: string }, ValueTypes['CreatorDraftContent']]
    listEtailerProductMetrics?: [
      {
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsEtailerProductMetrics']
    ]
    listInvitationsByAgency?: [
      {
        agencyId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        status?: ValueTypes['ModelMomentumInvitationSkKeyConditionInput'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsUserInvitation']
    ]
    listInvitationsByCompany?: [
      {
        companyId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        status?: ValueTypes['ModelMomentumInvitationSkKeyConditionInput'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsUserInvitation']
    ]
    listInvitationsByStatus?: [
      {
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        status?: ValueTypes['UserInvitationStatus'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsUserInvitation']
    ]
    listPricings?: [
      {
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        store?: ValueTypes['ModelCreatorPricingSkKeyConditionInput'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsPricing']
    ]
    listProductKeywordRecords?: [
      {
        campaignId_keyword?: string | undefined | null
        date?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsProductKeywordRecord']
    ]
    listProductKeywordSummaries?: [
      {
        campaignId?: string | undefined | null
        keyword?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsProductKeywordSummary']
    ]
    listProductRecommendations?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        recommendationType?: ValueTypes['ModelProductRecommendationsSkKeyConditionInput'] | undefined | null
        segment?: number | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsProductRecommendation']
    ]
    listProducts?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsProduct']
    ]
    listProductsByBrandId?: [
      {
        brandId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsProduct']
    ]
    listProposals?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsProposal']
    ]
    listProposalsByBrandId?: [
      {
        brandId?: string | undefined | null
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsProposal']
    ]
    listSubscriptionCreditActions?: [
      {
        actionId?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsSubscriptionCreditAction']
    ]
    listUserAgencies?: [
      {
        agencyId?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelItemsUserAgency']
    ]
    listUserAgenciesByAgencyId?: [
      {
        agencyId?: string | undefined | null
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsUserAgency']
    ]
    listUserBrands?: [
      {
        brandId?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelItemsUserBrand']
    ]
    listUserCompanies?: [
      {
        companyId?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
        userId?: string | undefined | null
      },
      ValueTypes['ModelItemsUserCompany']
    ]
    listUserCompaniesByCompanyId?: [
      {
        companyId?: string | undefined | null
        createdAt?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsUserCompany']
    ]
    listUsers?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsUser']
    ]
    requestExchangeRate?: [{ input: ValueTypes['RequestExchangeRateInput'] }, ValueTypes['RequestExchangeRateOutput']]
    scrapeRequestByBrandIdBySku?: [
      {
        brandId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sku?: ValueTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ValueTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ValueTypes['ModelItemsScrapeRequestJob']
    ]
    searchBrandContent?: [
      {
        campaignIds: Array<string>
        excludedContentTypes: Array<ValueTypes['ContentType'] | undefined | null>
        from?: number | undefined | null
        isFavorite: boolean
        isFavoriteMinShouldMatch: number
        minShouldMatch: number
        search?: string | undefined | null
        size?: number | undefined | null
        sortBy: string
      },
      ValueTypes['ModelSearchItemssearchBrandContent']
    ]
    searchBrandCounts?: [
      { args?: string | undefined | null; from?: number | undefined | null; size?: number | undefined | null },
      ValueTypes['ModelSearchItemssearchBrandCounts']
    ]
    searchBrandProducts?: [
      { brandId: string; from?: number | undefined | null; size?: number | undefined | null },
      ValueTypes['ModelSearchItemssearchBrandProducts']
    ]
    searchRecommendations?: [
      {
        archivedMinShouldMatch: number
        brandIds?: Array<string> | undefined | null
        from?: number | undefined | null
        isArchived?: boolean | undefined | null
        minShouldMatch: number
        recommendationTypes: Array<ValueTypes['RecommendationType']>
        search?: string | undefined | null
        size?: number | undefined | null
        sortBy: string
        stores?: Array<ValueTypes['Store']> | undefined | null
      },
      ValueTypes['ModelSearchItemssearchRecommendations']
    ]
    __typename?: boolean | `@${string}`
  }>
  ['RefreshRecommendationsOutput']: AliasType<{
    isSuccess?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ReleaseSchedule']: AliasType<{
    brandAdvocatesWeeklySchedule?: boolean | `@${string}`
    premiumUgcWeeklySchedule?: boolean | `@${string}`
    socialWeeklySchedule?: boolean | `@${string}`
    ugcWeeklySchedule?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['RequestExchangeRateOutput']: AliasType<{
    rate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['RequestUploadFileLinkResult']: AliasType<{
    key?: boolean | `@${string}`
    link?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Review']: AliasType<{
    attributedUserId?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isVine?: boolean | `@${string}`
    name?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    reviewedAt?: boolean | `@${string}`
    text?: boolean | `@${string}`
    title?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ReviewFeedback']: AliasType<{
    atTime?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    createdByFirstName?: boolean | `@${string}`
    createdByLastName?: boolean | `@${string}`
    forAssetAtIndex?: boolean | `@${string}`
    message?: boolean | `@${string}`
    visibleToUser?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ScrapeRequestJob']: AliasType<{
    brandId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    expirationTime?: boolean | `@${string}`
    id?: boolean | `@${string}`
    message?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SearchBrandCountsOutput']: AliasType<{
    brandId?: boolean | `@${string}`
    totalProducts?: boolean | `@${string}`
    totalRecommendations?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialProfile']: AliasType<{
    engagementRate?: boolean | `@${string}`
    followers?: boolean | `@${string}`
    handle?: boolean | `@${string}`
    isPrimary?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SubscriptionCreditAction']: AliasType<{
    action?: boolean | `@${string}`
    actionCreatedByFirstName?: boolean | `@${string}`
    actionCreatedByIP?: boolean | `@${string}`
    actionCreatedById?: boolean | `@${string}`
    actionCreatedByLastName?: boolean | `@${string}`
    actionId?: boolean | `@${string}`
    associatedProposalId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    label?: boolean | `@${string}`
    numCredits?: boolean | `@${string}`
    proposal?: ValueTypes['Proposal']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ToggleContentVisibilityResonse']: AliasType<{
    isHidden?: boolean | `@${string}`
    userCampaignSocialContentId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['User']: AliasType<{
    accessLevel?: boolean | `@${string}`
    companies?: ValueTypes['UserCompany']
    createdAt?: boolean | `@${string}`
    email?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    id?: boolean | `@${string}`
    invitationId?: boolean | `@${string}`
    lastActiveAt?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    phoneNumber?: boolean | `@${string}`
    primaryBrandId?: boolean | `@${string}`
    profilePicture?: boolean | `@${string}`
    role?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserAgency']: AliasType<{
    agency?: ValueTypes['Agency']
    agencyId?: boolean | `@${string}`
    companies?: ValueTypes['Company']
    createdAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserBrand']: AliasType<{
    brand?: ValueTypes['Brand']
    brandId?: boolean | `@${string}`
    companyId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContent']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isFavorite?: boolean | `@${string}`
    isHidden?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCompany']: AliasType<{
    company?: ValueTypes['Company']
    companyId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    isAllBrandAccess?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ValueTypes['User']
    userBrands?: ValueTypes['UserBrand']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserInvitation']: AliasType<{
    accessLevel?: boolean | `@${string}`
    agencyId?: boolean | `@${string}`
    brandIds?: boolean | `@${string}`
    company?: ValueTypes['Company']
    companyId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    email?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isAllBrandAccess?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    phoneNumber?: boolean | `@${string}`
    primaryBrandId?: boolean | `@${string}`
    role?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AccessLevel']: AccessLevel
  ['BenchmarkType']: BenchmarkType
  ['BrandApiType']: BrandApiType
  ['BrandApiVersion']: BrandApiVersion
  ['CampaignCreatorApprovalAction']: CampaignCreatorApprovalAction
  ['CampaignCreatorStatus']: CampaignCreatorStatus
  ['Channel']: Channel
  ['ContentType']: ContentType
  ['ContentUploadType']: ContentUploadType
  ['CreatorApprovalStatus']: CreatorApprovalStatus
  ['CreatorType']: CreatorType
  ['CreditAction']: CreditAction
  ['Currency']: Currency
  ['DraftStatus']: DraftStatus
  ['InvoicePaymentType']: InvoicePaymentType
  ['InvoiceStatus']: InvoiceStatus
  ['ItemClassification']: ItemClassification
  ['ListingBadge']: ListingBadge
  ['ListingFulfillmentType']: ListingFulfillmentType
  ['ModelSearchStringFieldInput']: ModelSearchStringFieldInput
  ['ModelSortDirection']: ModelSortDirection
  ['PaymentTermsType']: PaymentTermsType
  ['PerformanceComparisonMetric']: PerformanceComparisonMetric
  ['PerformanceSummaryTitleType']: PerformanceSummaryTitleType
  ['PricingCreatorType']: PricingCreatorType
  ['ProductCreationSource']: ProductCreationSource
  ['ProductFeedbackStatus']: ProductFeedbackStatus
  ['ProposalGoal']: ProposalGoal
  ['ProposalStatus']: ProposalStatus
  ['RecommendationType']: RecommendationType
  ['Region']: Region
  ['Role']: Role
  ['ScrapeRequestStatus']: ScrapeRequestStatus
  ['Store']: Store
  ['UploadRequestType']: UploadRequestType
  ['UserInvitationStatus']: UserInvitationStatus
  ['UserStatus']: UserStatus
  ['AdjustCampaignStartDateInput']: {
    campaignId?: string | undefined | null
    newCampaignStartDate?: string | undefined | null
  }
  ['AdjustCreditInput']: {
    action: ValueTypes['CreditAction']
    associatedProposalId?: string | undefined | null
    id: string
    label?: string | undefined | null
    numCredits: number
  }
  ['AdjustProductRecommendationVisibilityInput']: {
    id: string
    isVisible: boolean
  }
  ['ApproveContentInput']: {
    userCampaignSocialContentId: string
  }
  ['CreateBrandInput']: {
    amazonBrandStoreUrl?: string | undefined | null
    brandApis?: Array<ValueTypes['BrandApiType']> | undefined | null
    companyId: string
    id?: string | undefined | null
    isBrandStoreScrapeEnabled?: boolean | undefined | null
    isWalmartScrapeEnabled?: boolean | undefined | null
    logo?: string | undefined | null
    name: string
    nameAudioKey?: string | undefined | null
    region: ValueTypes['Region']
    walmartScrapeUrl?: string | undefined | null
  }
  ['CreateProductFromUrlInput']: {
    brandId: string
    productUrl: string
  }
  ['CreateProposalSlideInput']: {
    creatorPricing: Array<ValueTypes['CreatorPricingInput']>
    exchangeRate: number
    goal: ValueTypes['ProposalGoal']
    productId: string
    ratingGoal?: number | undefined | null
  }
  ['CreatorPricingInput']: {
    numCreators: number
    price: number
    type: ValueTypes['PricingCreatorType']
  }
  ['ModelBrandApiSkKeyConditionInput']: {
    beginsWith?: ValueTypes['BrandApiType'] | undefined | null
    between?: Array<ValueTypes['BrandApiType'] | undefined | null> | undefined | null
    eq?: ValueTypes['BrandApiType'] | undefined | null
    ge?: ValueTypes['BrandApiType'] | undefined | null
    gt?: ValueTypes['BrandApiType'] | undefined | null
    le?: ValueTypes['BrandApiType'] | undefined | null
    lt?: ValueTypes['BrandApiType'] | undefined | null
  }
  ['ModelCreatorPricingSkKeyConditionInput']: {
    beginsWith?: ValueTypes['Store'] | undefined | null
    between?: Array<ValueTypes['Store'] | undefined | null> | undefined | null
    eq?: ValueTypes['Store'] | undefined | null
    ge?: ValueTypes['Store'] | undefined | null
    gt?: ValueTypes['Store'] | undefined | null
    le?: ValueTypes['Store'] | undefined | null
    lt?: ValueTypes['Store'] | undefined | null
  }
  ['ModelInputCreateAgency']: {
    clientPricingId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    logo: string
    name: string
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ValueTypes['PaymentTermsType'] | undefined | null
    updatedAt?: string | undefined | null
    websiteUrl: string
  }
  ['ModelInputCreateCampaignBenchmark']: {
    benchmarkGoal: number
    benchmarkType: ValueTypes['BenchmarkType']
    benchmarkedProductIds: Array<string>
    campaignId: string
    createdAt?: string | undefined | null
    skuId: string
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreateCampaignPerformance']: {
    campaignId: string
    comparisonMetric?: ValueTypes['PerformanceComparisonMetric'] | undefined | null
    comparisonMetricValue?: number | undefined | null
    createdAt?: string | undefined | null
    isBsrDataUnavailable?: boolean | undefined | null
    isBsrVisible?: boolean | undefined | null
    isTtrDataUnavailable?: boolean | undefined | null
    isTtrVisible?: boolean | undefined | null
    isVisible?: boolean | undefined | null
    promotedProductId: string
    skuId: string
    summaryTitle?: ValueTypes['PerformanceSummaryTitleType'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreateCampaignPerformanceProduct']: {
    bestSellerRankDataUploaded?: boolean | undefined | null
    campaignId: string
    comparisonMetric?: ValueTypes['PerformanceComparisonMetric'] | undefined | null
    createdAt?: string | undefined | null
    id: string
    isCategoryAverage?: boolean | undefined | null
    isVisible?: boolean | undefined | null
    productImage?: string | undefined | null
    productName: string
    productPerformanceRecords?:
      | Array<ValueTypes['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']>
      | undefined
      | null
    productUrl?: string | undefined | null
    reviewDataUploaded?: boolean | undefined | null
    skuId: string
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']: {
    date: string
    rating?: number | undefined | null
    reviewCount?: number | undefined | null
    salesRank?: number | undefined | null
  }
  ['ModelInputCreateCompany']: {
    agencyId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    logo?: string | undefined | null
    name: string
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ValueTypes['PaymentTermsType'] | undefined | null
    updatedAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['ModelInputCreatePWProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined | null
    keywords?: Array<ValueTypes['ModelInputCreatePWProductSeoSummaryKeywordsInput']> | undefined | null
    productSEOSummaryRecords?:
      | Array<ValueTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']>
      | undefined
      | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreatePWProductSeoSummaryKeywordsInput']: {
    badges?: Array<ValueTypes['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']> | undefined | null
    highestRank?: number | undefined | null
    impressions: number
    keyword: string
    pageOneWin?: boolean | undefined | null
    pageOneWinDate?: string | undefined | null
    sponsoredHighestRank?: number | undefined | null
    sponsoredStartRank?: number | undefined | null
    startRank?: number | undefined | null
  }
  ['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']: {
    badge: ValueTypes['ListingBadge']
    date?: string | undefined | null
  }
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']: {
    categoryRanks?:
      | Array<ValueTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']>
      | undefined
      | null
    impressions?: number | undefined | null
    isOutOfStock?: boolean | undefined | null
    topPageWins?: number | undefined | null
    weekIndex: number
  }
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: {
    category: string
    rank: number
  }
  ['ModelInputCreatePricing']: {
    createdAt?: string | undefined | null
    creatorPricing?: Array<ValueTypes['ModelInputCreatePricingCreatorPricingInput']> | undefined | null
    id: string
    store: ValueTypes['Store']
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreatePricingCreatorPricingInput']: {
    price: number
    type: ValueTypes['PricingCreatorType']
  }
  ['ModelInputCreateProposal']: {
    benchmarkProducts?: Array<string> | undefined | null
    billingContacts?: Array<ValueTypes['ModelInputCreateProposalBillingContactsInput']> | undefined | null
    brandId: string
    createdAt?: string | undefined | null
    createdByUserId?: string | undefined | null
    creatorPricing?: Array<ValueTypes['ModelInputCreateProposalCreatorPricingInput']> | undefined | null
    creditActionId?: string | undefined | null
    durationWeeks?: number | undefined | null
    estimatedAverageRating?: number | undefined | null
    estimatedUnitsSoldPerMonth?: number | undefined | null
    estimatedUnitsSoldPerWeek?: number | undefined | null
    exchangeRate?: number | undefined | null
    goal: ValueTypes['ProposalGoal']
    hiddenEndRating?: boolean | undefined | null
    hiddenNumReviews?: boolean | undefined | null
    hiddenPdpTraffic?: boolean | undefined | null
    hiddenRetailEfficiency?: boolean | undefined | null
    hiddenSeo?: boolean | undefined | null
    hiddenSocialContent?: boolean | undefined | null
    hiddenUgcContent?: boolean | undefined | null
    hubspotDealId?: string | undefined | null
    id: string
    invoiceDueDate?: string | undefined | null
    invoiceId?: string | undefined | null
    invoiceLink?: string | undefined | null
    invoiceNumber?: string | undefined | null
    invoicePONumber?: string | undefined | null
    invoicePaidDate?: string | undefined | null
    invoiceStatus?: ValueTypes['InvoiceStatus'] | undefined | null
    isDailyScheduling?: boolean | undefined | null
    launchDate: string
    liftCloseoutBonus?: number | undefined | null
    liftNumCreators?: number | undefined | null
    operatingCost?: number | undefined | null
    paymentType?: ValueTypes['InvoicePaymentType'] | undefined | null
    productId: string
    productPrice?: number | undefined | null
    productPriceOverride?: number | undefined | null
    productRating?: number | undefined | null
    productRatingCount?: number | undefined | null
    productVariationSkus?: Array<string> | undefined | null
    ratingGoal?: number | undefined | null
    referralCost?: number | undefined | null
    searchTerms?: Array<string> | undefined | null
    status: ValueTypes['ProposalStatus']
    statusChangedAt: string
    submittedAt?: string | undefined | null
    submittedByUserId?: string | undefined | null
    title?: string | undefined | null
    totalCredits?: number | undefined | null
    totalCreditsPaid?: number | undefined | null
    totalProductCostCredits?: number | undefined | null
    updatedAt?: string | undefined | null
    updatedByUserId?: string | undefined | null
  }
  ['ModelInputCreateProposalBillingContactsInput']: {
    email: string
    name: string
  }
  ['ModelInputCreateProposalCreatorPricingInput']: {
    closeoutBonus?: number | undefined | null
    contentRequirements?:
      | Array<ValueTypes['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']>
      | undefined
      | null
    hidden?: boolean | undefined | null
    numCreators: number
    price: number
    schedule: Array<number>
    type: ValueTypes['CreatorType']
  }
  ['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']: {
    bonus: number
    contentType: ValueTypes['ContentType']
  }
  ['ModelInputCreateScrapeRequestJob']: {
    brandId?: string | undefined | null
    createdAt?: string | undefined | null
    expirationTime: string
    id: string
    message?: string | undefined | null
    sku?: string | undefined | null
    skuId?: string | undefined | null
    status: ValueTypes['ScrapeRequestStatus']
    updatedAt?: string | undefined | null
  }
  ['ModelInputDeleteCampaignPerformance']: {
    campaignId: string
    skuId: string
  }
  ['ModelInputDeleteCampaignPerformanceProduct']: {
    id: string
  }
  ['ModelInputDeletePricing']: {
    id: string
    store: ValueTypes['Store']
  }
  ['ModelInputDeleteProposal']: {
    id: string
  }
  ['ModelInputDeleteUserInvitation']: {
    id: string
  }
  ['ModelInputUpdateAgency']: {
    clientPricingId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    logo?: string | undefined | null
    name?: string | undefined | null
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ValueTypes['PaymentTermsType'] | undefined | null
    updatedAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['ModelInputUpdateCampaignBenchmark']: {
    benchmarkGoal?: number | undefined | null
    benchmarkType?: ValueTypes['BenchmarkType'] | undefined | null
    benchmarkedProductIds?: Array<string | undefined | null> | undefined | null
    campaignId: string
    createdAt?: string | undefined | null
    skuId: string
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateCampaignPerformance']: {
    campaignId: string
    comparisonMetric?: ValueTypes['PerformanceComparisonMetric'] | undefined | null
    comparisonMetricValue?: number | undefined | null
    createdAt?: string | undefined | null
    isBsrDataUnavailable?: boolean | undefined | null
    isBsrVisible?: boolean | undefined | null
    isTtrDataUnavailable?: boolean | undefined | null
    isTtrVisible?: boolean | undefined | null
    isVisible?: boolean | undefined | null
    promotedProductId?: string | undefined | null
    skuId: string
    summaryTitle?: ValueTypes['PerformanceSummaryTitleType'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateCampaignPerformanceProduct']: {
    bestSellerRankDataUploaded?: boolean | undefined | null
    campaignId?: string | undefined | null
    comparisonMetric?: ValueTypes['PerformanceComparisonMetric'] | undefined | null
    createdAt?: string | undefined | null
    id: string
    isCategoryAverage?: boolean | undefined | null
    isVisible?: boolean | undefined | null
    productImage?: string | undefined | null
    productName?: string | undefined | null
    productPerformanceRecords?:
      | Array<ValueTypes['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput'] | undefined | null>
      | undefined
      | null
    productUrl?: string | undefined | null
    reviewDataUploaded?: boolean | undefined | null
    skuId?: string | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput']: {
    date?: string | undefined | null
    rating?: number | undefined | null
    reviewCount?: number | undefined | null
    salesRank?: number | undefined | null
  }
  ['ModelInputUpdateCompany']: {
    agencyId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    logo?: string | undefined | null
    name?: string | undefined | null
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ValueTypes['PaymentTermsType'] | undefined | null
    updatedAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined | null
    keywords?:
      | Array<ValueTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInput'] | undefined | null>
      | undefined
      | null
    productSEOSummaryRecords?:
      | Array<ValueTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput'] | undefined | null>
      | undefined
      | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInput']: {
    badges?:
      | Array<ValueTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput'] | undefined | null>
      | undefined
      | null
    highestRank?: number | undefined | null
    impressions?: number | undefined | null
    keyword?: string | undefined | null
    pageOneWin?: boolean | undefined | null
    pageOneWinDate?: string | undefined | null
    sponsoredHighestRank?: number | undefined | null
    sponsoredStartRank?: number | undefined | null
    startRank?: number | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput']: {
    badge: ValueTypes['ListingBadge']
    date?: string | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput']: {
    categoryRanks?:
      | Array<
          | ValueTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']
          | undefined
          | null
        >
      | undefined
      | null
    impressions?: number | undefined | null
    isOutOfStock?: boolean | undefined | null
    topPageWins?: number | undefined | null
    weekIndex?: number | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: {
    category: string
    rank: number
  }
  ['ModelInputUpdatePricing']: {
    createdAt?: string | undefined | null
    creatorPricing?:
      | Array<ValueTypes['ModelInputUpdatePricingCreatorPricingInput'] | undefined | null>
      | undefined
      | null
    id: string
    store: ValueTypes['Store']
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdatePricingCreatorPricingInput']: {
    price?: number | undefined | null
    type?: ValueTypes['PricingCreatorType'] | undefined | null
  }
  ['ModelInputUpdateProductKeywordSummary']: {
    campaignId: string
    campaignId_keyword?: string | undefined | null
    createdAt?: string | undefined | null
    hasAmazonChoiceBadge?: boolean | undefined | null
    keyword: string
    organicHighestRank?: number | undefined | null
    organicPageOneWin?: boolean | undefined | null
    organicPageOneWinDate?: string | undefined | null
    organicStartRank?: number | undefined | null
    searchVolume?: number | undefined | null
    sponsoredHighestRank?: number | undefined | null
    sponsoredPageOneWin?: boolean | undefined | null
    sponsoredPageOneWinDate?: string | undefined | null
    sponsoredStartRank?: number | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined | null
    productSeoSummaryRecords?:
      | Array<ValueTypes['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput'] | undefined | null>
      | undefined
      | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput']: {
    fromDate?: string | undefined | null
    isOutOfStock?: boolean | undefined | null
    organicPageOneWins?: number | undefined | null
    searchVolume?: number | undefined | null
    sponsoredPageOneWins?: number | undefined | null
    toDate?: string | undefined | null
  }
  ['ModelInputUpdateProposal']: {
    benchmarkProducts?: Array<string | undefined | null> | undefined | null
    billingContacts?:
      | Array<ValueTypes['ModelInputUpdateProposalBillingContactsInput'] | undefined | null>
      | undefined
      | null
    brandId?: string | undefined | null
    createdAt?: string | undefined | null
    createdByUserId?: string | undefined | null
    creatorPricing?:
      | Array<ValueTypes['ModelInputUpdateProposalCreatorPricingInput'] | undefined | null>
      | undefined
      | null
    creditActionId?: string | undefined | null
    durationWeeks?: number | undefined | null
    estimatedAverageRating?: number | undefined | null
    estimatedUnitsSoldPerMonth?: number | undefined | null
    estimatedUnitsSoldPerWeek?: number | undefined | null
    exchangeRate?: number | undefined | null
    goal?: ValueTypes['ProposalGoal'] | undefined | null
    hiddenEndRating?: boolean | undefined | null
    hiddenNumReviews?: boolean | undefined | null
    hiddenPdpTraffic?: boolean | undefined | null
    hiddenRetailEfficiency?: boolean | undefined | null
    hiddenSeo?: boolean | undefined | null
    hiddenSocialContent?: boolean | undefined | null
    hiddenUgcContent?: boolean | undefined | null
    hubspotDealId?: string | undefined | null
    id: string
    invoiceDueDate?: string | undefined | null
    invoiceId?: string | undefined | null
    invoiceLink?: string | undefined | null
    invoiceNumber?: string | undefined | null
    invoicePONumber?: string | undefined | null
    invoicePaidDate?: string | undefined | null
    invoiceStatus?: ValueTypes['InvoiceStatus'] | undefined | null
    isDailyScheduling?: boolean | undefined | null
    launchDate?: string | undefined | null
    liftCloseoutBonus?: number | undefined | null
    liftNumCreators?: number | undefined | null
    operatingCost?: number | undefined | null
    paymentType?: ValueTypes['InvoicePaymentType'] | undefined | null
    productId?: string | undefined | null
    productPrice?: number | undefined | null
    productPriceOverride?: number | undefined | null
    productRating?: number | undefined | null
    productRatingCount?: number | undefined | null
    productVariationSkus?: Array<string | undefined | null> | undefined | null
    ratingGoal?: number | undefined | null
    referralCost?: number | undefined | null
    searchTerms?: Array<string | undefined | null> | undefined | null
    status?: ValueTypes['ProposalStatus'] | undefined | null
    statusChangedAt?: string | undefined | null
    submittedAt?: string | undefined | null
    submittedByUserId?: string | undefined | null
    title?: string | undefined | null
    totalCredits?: number | undefined | null
    totalCreditsPaid?: number | undefined | null
    totalProductCostCredits?: number | undefined | null
    updatedAt?: string | undefined | null
    updatedByUserId?: string | undefined | null
  }
  ['ModelInputUpdateProposalBillingContactsInput']: {
    email?: string | undefined | null
    name?: string | undefined | null
  }
  ['ModelInputUpdateProposalCreatorPricingInput']: {
    closeoutBonus?: number | undefined | null
    contentRequirements?:
      | Array<ValueTypes['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput'] | undefined | null>
      | undefined
      | null
    hidden?: boolean | undefined | null
    numCreators?: number | undefined | null
    price?: number | undefined | null
    schedule?: Array<number | undefined | null> | undefined | null
    type?: ValueTypes['CreatorType'] | undefined | null
  }
  ['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput']: {
    bonus: number
    contentType: ValueTypes['ContentType']
  }
  ['ModelInputUpdateScrapeRequestJob']: {
    brandId?: string | undefined | null
    createdAt?: string | undefined | null
    expirationTime?: string | undefined | null
    id: string
    message?: string | undefined | null
    sku?: string | undefined | null
    skuId?: string | undefined | null
    status?: ValueTypes['ScrapeRequestStatus'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateUser']: {
    accessLevel?: ValueTypes['AccessLevel'] | undefined | null
    createdAt?: string | undefined | null
    email?: string | undefined | null
    firstName?: string | undefined | null
    id: string
    invitationId?: string | undefined | null
    lastActiveAt?: string | undefined | null
    lastName?: string | undefined | null
    phoneNumber?: string | undefined | null
    primaryBrandId?: string | undefined | null
    profilePicture?: string | undefined | null
    role?: ValueTypes['Role'] | undefined | null
    status?: ValueTypes['UserStatus'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateUserAgency']: {
    agencyId: string
    createdAt?: string | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['ModelInputUpdateUserCampaignSocialContent']: {
    createdAt?: string | undefined | null
    id: string
    isFavorite?: boolean | undefined | null
    isHidden?: boolean | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateUserInvitation']: {
    accessLevel?: ValueTypes['AccessLevel'] | undefined | null
    agencyId?: string | undefined | null
    brandIds?: Array<string | undefined | null> | undefined | null
    companyId?: string | undefined | null
    createdAt?: string | undefined | null
    email?: string | undefined | null
    firstName?: string | undefined | null
    id: string
    isAllBrandAccess?: boolean | undefined | null
    lastName?: string | undefined | null
    phoneNumber?: string | undefined | null
    primaryBrandId?: string | undefined | null
    role?: ValueTypes['Role'] | undefined | null
    status?: ValueTypes['UserInvitationStatus'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelIntKeyConditionInput']: {
    beginsWith?: number | undefined | null
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
  }
  ['ModelMomentumInvitationSkKeyConditionInput']: {
    beginsWith?: ValueTypes['UserInvitationStatus'] | undefined | null
    between?: Array<ValueTypes['UserInvitationStatus'] | undefined | null> | undefined | null
    eq?: ValueTypes['UserInvitationStatus'] | undefined | null
    ge?: ValueTypes['UserInvitationStatus'] | undefined | null
    gt?: ValueTypes['UserInvitationStatus'] | undefined | null
    le?: ValueTypes['UserInvitationStatus'] | undefined | null
    lt?: ValueTypes['UserInvitationStatus'] | undefined | null
  }
  ['ModelProductRecommendationsSkKeyConditionInput']: {
    beginsWith?: ValueTypes['RecommendationType'] | undefined | null
    between?: Array<ValueTypes['RecommendationType'] | undefined | null> | undefined | null
    eq?: ValueTypes['RecommendationType'] | undefined | null
    ge?: ValueTypes['RecommendationType'] | undefined | null
    gt?: ValueTypes['RecommendationType'] | undefined | null
    le?: ValueTypes['RecommendationType'] | undefined | null
    lt?: ValueTypes['RecommendationType'] | undefined | null
  }
  ['ModelSearchStringInput']: {
    field?: ValueTypes['ModelSearchStringFieldInput'] | undefined | null
    match?: string | undefined | null
    match_phrase_prefix?: string | undefined | null
    not?: boolean | undefined | null
    prefix?: string | undefined | null
    regexp?: string | undefined | null
    wildcard?: string | undefined | null
  }
  ['ModelStringKeyConditionInput']: {
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
  }
  ['ProductInput']: {
    brandId: string
    estimatedAvailabilityDate?: string | undefined | null
    id?: string | undefined | null
    image?: string | undefined | null
    name: string
    price: string
    store: ValueTypes['Store']
  }
  ['ProductSeoKeywordUploadRecord']: {
    date: string
    keyword: string
    keywordSales?: number | undefined | null
    organicRank?: number | undefined | null
    searchVolume?: number | undefined | null
    sponsoredRank?: number | undefined | null
  }
  ['RequestContentEditsInput']: {
    atTime?: number | undefined | null
    forAssetAtIndex?: number | undefined | null
    message?: string | undefined | null
    shouldSendToCreator: boolean
    userCampaignSocialContentId: string
    visibleToUser?: boolean | undefined | null
  }
  ['RequestExchangeRateInput']: {
    from: ValueTypes['Currency']
    to?: ValueTypes['Currency'] | undefined | null
  }
  ['SubmitCampaignCreatorApprovalInput']: {
    action: ValueTypes['CampaignCreatorApprovalAction']
    feedback?: string | undefined | null
    id: string
  }
  ['ToggleContentVisibilityInput']: {
    isHidden: boolean
    userCampaignSocialContentId: string
  }
  ['UpdateBrandInput']: {
    amazonBrandStoreUrl?: string | undefined | null
    companyId?: string | undefined | null
    enabledBrandApiTypes?: Array<ValueTypes['BrandApiType'] | undefined | null> | undefined | null
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined | null
    isWalmartScrapeEnabled?: boolean | undefined | null
    logo?: string | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    region?: ValueTypes['Region'] | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    walmartScrapeUrl?: string | undefined | null
  }
  ['UpdateManualProductInput']: {
    id: string
    image?: string | undefined | null
    name?: string | undefined | null
    price?: string | undefined | null
    store?: ValueTypes['Store'] | undefined | null
  }
  ['UploadCreatorContentInput']: {
    assetKeys?: Array<string> | undefined | null
    channel?: ValueTypes['Channel'] | undefined | null
    contentLink?: string | undefined | null
    contentType?: ValueTypes['ContentType'] | undefined | null
    socialContentId?: string | undefined | null
    uploadType: ValueTypes['ContentUploadType']
    userCampaignId: string
  }
  ['UploadProductSeoDataInput']: {
    campaignId: string
    campaignStartDate: string
    records: Array<ValueTypes['ProductSeoKeywordUploadRecord']>
    shouldUpdateSummary?: boolean | undefined | null
  }
  ['UserInvitationInput']: {
    accessLevel?: ValueTypes['AccessLevel'] | undefined | null
    agencyId?: string | undefined | null
    brandIds?: Array<string> | undefined | null
    companyId?: string | undefined | null
    email: string
    firstName?: string | undefined | null
    isAllBrandAccess?: boolean | undefined | null
    lastName?: string | undefined | null
    phoneNumber?: string | undefined | null
    primaryBrandId?: string | undefined | null
    role?: ValueTypes['Role'] | undefined | null
  }
}

export type ResolverInputTypes = {
  ['AdjustCampaignStartDateOutput']: AliasType<{
    startDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Agency']: AliasType<{
    clientPricing?: ResolverInputTypes['Pricing']
    clientPricingId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    logo?: boolean | `@${string}`
    name?: boolean | `@${string}`
    paymentTermsCustomNetDays?: boolean | `@${string}`
    paymentTermsType?: boolean | `@${string}`
    pricing?: ResolverInputTypes['Pricing']
    updatedAt?: boolean | `@${string}`
    websiteUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ApproveContentResonse']: AliasType<{
    userCampaignSocialContentId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BillingContact']: AliasType<{
    email?: boolean | `@${string}`
    name?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Brand']: AliasType<{
    amazonBrandName?: boolean | `@${string}`
    amazonBrandStoreUrl?: boolean | `@${string}`
    brandApis?: ResolverInputTypes['BrandApi']
    companyId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isBrandStoreScrapeEnabled?: boolean | `@${string}`
    isWalmartScrapeEnabled?: boolean | `@${string}`
    lastScrapedAt?: boolean | `@${string}`
    logo?: boolean | `@${string}`
    name?: boolean | `@${string}`
    nameAudioKey?: boolean | `@${string}`
    pricing?: ResolverInputTypes['Pricing']
    products?: ResolverInputTypes['ModelItemsProduct']
    proposals?: ResolverInputTypes['Proposal']
    region?: boolean | `@${string}`
    subscriptionCredits?: ResolverInputTypes['SubscriptionCreditAction']
    subscriptionEndsAt?: boolean | `@${string}`
    subscriptionStartsAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    walmartBrandName?: boolean | `@${string}`
    walmartLastScrapedAt?: boolean | `@${string}`
    walmartScrapeUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['BrandApi']: AliasType<{
    accessToken?: boolean | `@${string}`
    api?: boolean | `@${string}`
    apiVersion?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    enabled?: boolean | `@${string}`
    isIntegrated?: boolean | `@${string}`
    refreshToken?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Campaign']: AliasType<{
    brandId?: boolean | `@${string}`
    cost?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    endDate?: boolean | `@${string}`
    goal?: boolean | `@${string}`
    id?: boolean | `@${string}`
    numCreators?: boolean | `@${string}`
    numCreatorsJoined?: boolean | `@${string}`
    preLaunchDate?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    proposal?: ResolverInputTypes['Proposal']
    skuId?: boolean | `@${string}`
    startDate?: boolean | `@${string}`
    title?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignBenchmark']: AliasType<{
    benchmarkGoal?: boolean | `@${string}`
    benchmarkType?: boolean | `@${string}`
    benchmarkedProductIds?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignClick']: AliasType<{
    clicks?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignCreator']: AliasType<{
    content?: ResolverInputTypes['ContentGroup']
    creator?: ResolverInputTypes['CampaignCreatorCreator']
    creatorType?: boolean | `@${string}`
    leftProductFeedback?: boolean | `@${string}`
    purchaseDetails?: ResolverInputTypes['PurchaseDetails']
    status?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignCreatorCreator']: AliasType<{
    email?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    id?: boolean | `@${string}`
    instagramProfile?: ResolverInputTypes['SocialProfile']
    lastName?: boolean | `@${string}`
    sponsoredPosts?: boolean | `@${string}`
    tiktokProfile?: ResolverInputTypes['SocialProfile']
    youtubeProfile?: ResolverInputTypes['SocialProfile']
    __typename?: boolean | `@${string}`
  }>
  ['CampaignCreatorForApproval']: AliasType<{
    approvalState?: ResolverInputTypes['CreatorApprovalState']
    creator?: ResolverInputTypes['CampaignCreatorCreator']
    creatorType?: boolean | `@${string}`
    dateOfBirth?: boolean | `@${string}`
    locale?: boolean | `@${string}`
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignDetails']: AliasType<{
    anticipatedStartDate?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    cost?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    creators?: ResolverInputTypes['CampaignCreator']
    endDate?: boolean | `@${string}`
    expectedClicksCounts?: boolean | `@${string}`
    expectedPlacementWins?: boolean | `@${string}`
    expectedPremiumUgcPostCount?: boolean | `@${string}`
    expectedReviewsCount?: boolean | `@${string}`
    expectedSocialPostCount?: boolean | `@${string}`
    expectedUgcPostCount?: boolean | `@${string}`
    goal?: boolean | `@${string}`
    id?: boolean | `@${string}`
    liftCampaignId?: boolean | `@${string}`
    numAdditionalBrandAdvocateCreators?: boolean | `@${string}`
    numAdditionalBrandAdvocateCreatorsJoined?: boolean | `@${string}`
    numBrandAdvocateCreators?: boolean | `@${string}`
    numCreators?: boolean | `@${string}`
    numCreatorsJoined?: boolean | `@${string}`
    numPremiumUgcCreators?: boolean | `@${string}`
    numSocialCreators?: boolean | `@${string}`
    numUgcCreators?: boolean | `@${string}`
    preLaunchDate?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    proposal?: ResolverInputTypes['Proposal']
    releaseSchedule?: ResolverInputTypes['ReleaseSchedule']
    skuId?: boolean | `@${string}`
    startDate?: boolean | `@${string}`
    title?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignPerformance']: AliasType<{
    campaignId?: boolean | `@${string}`
    comparisonMetric?: boolean | `@${string}`
    comparisonMetricValue?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    isBsrDataUnavailable?: boolean | `@${string}`
    isBsrVisible?: boolean | `@${string}`
    isTtrDataUnavailable?: boolean | `@${string}`
    isTtrVisible?: boolean | `@${string}`
    isVisible?: boolean | `@${string}`
    products?: ResolverInputTypes['CampaignPerformanceProduct']
    promotedProductId?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    summaryTitle?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignPerformanceProduct']: AliasType<{
    bestSellerRankDataUploaded?: boolean | `@${string}`
    campaignId?: boolean | `@${string}`
    comparisonMetric?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isCategoryAverage?: boolean | `@${string}`
    isVisible?: boolean | `@${string}`
    productImage?: boolean | `@${string}`
    productName?: boolean | `@${string}`
    productPerformanceRecords?: ResolverInputTypes['ProductPerformanceRecord']
    productUrl?: boolean | `@${string}`
    reviewDataUploaded?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CampaignProductReviewRatingMetric']: AliasType<{
    fromDate?: boolean | `@${string}`
    momentumRating?: boolean | `@${string}`
    momentumRatingCount?: boolean | `@${string}`
    momentumReviewCount?: boolean | `@${string}`
    momentumReviewRating?: boolean | `@${string}`
    numRatings?: boolean | `@${string}`
    numReviews?: boolean | `@${string}`
    organicRating?: boolean | `@${string}`
    organicRatingCount?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    ratingsInPeriod?: boolean | `@${string}`
    reviews?: ResolverInputTypes['Review']
    reviewsInPeriod?: boolean | `@${string}`
    toDate?: boolean | `@${string}`
    vineRating?: boolean | `@${string}`
    vineReviewCount?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CategoryRank']: AliasType<{
    category?: boolean | `@${string}`
    rank?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Company']: AliasType<{
    agency?: ResolverInputTypes['Agency']
    agencyId?: boolean | `@${string}`
    brands?: ResolverInputTypes['Brand']
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    logo?: boolean | `@${string}`
    name?: boolean | `@${string}`
    paymentTermsCustomNetDays?: boolean | `@${string}`
    paymentTermsType?: boolean | `@${string}`
    pricing?: ResolverInputTypes['Pricing']
    updatedAt?: boolean | `@${string}`
    websiteUrl?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Content']: AliasType<{
    assets?: ResolverInputTypes['ContentAsset']
    caption?: boolean | `@${string}`
    comments?: boolean | `@${string}`
    engagements?: boolean | `@${string}`
    id?: boolean | `@${string}`
    likes?: boolean | `@${string}`
    qualityScore?: boolean | `@${string}`
    views?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentAsset']: AliasType<{
    type?: boolean | `@${string}`
    url?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentGroup']: AliasType<{
    channel?: boolean | `@${string}`
    content?: ResolverInputTypes['Content']
    contentType?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    groupId?: boolean | `@${string}`
    isFavorite?: boolean | `@${string}`
    isHidden?: boolean | `@${string}`
    platformContentLink?: boolean | `@${string}`
    submittedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ContentRequirement']: AliasType<{
    bonus?: boolean | `@${string}`
    contentType?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreateCampaignOutput']: AliasType<{
    id?: boolean | `@${string}`
    invoiceLink?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreateProposalSlideResult']: AliasType<{
    link?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatedByUser']: AliasType<{
    firstName?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Creator']: AliasType<{
    email?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    id?: boolean | `@${string}`
    instagramProfile?: ResolverInputTypes['SocialProfile']
    lastName?: boolean | `@${string}`
    tiktokProfile?: ResolverInputTypes['SocialProfile']
    youtubeProfile?: ResolverInputTypes['SocialProfile']
    __typename?: boolean | `@${string}`
  }>
  ['CreatorApprovalState']: AliasType<{
    feedback?: boolean | `@${string}`
    reviewedByUserEmail?: boolean | `@${string}`
    reviewedByUserId?: boolean | `@${string}`
    reviewedByUserName?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorContent']: AliasType<{
    content?: ResolverInputTypes['ContentGroup']
    creator?: ResolverInputTypes['Creator']
    userCampaignId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorDraftContent']: AliasType<{
    creator?: ResolverInputTypes['Creator']
    draftContent?: ResolverInputTypes['DraftContent']
    id?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['CreatorPricing']: AliasType<{
    price?: boolean | `@${string}`
    type?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['DraftContent']: AliasType<{
    channel?: boolean | `@${string}`
    contentType?: boolean | `@${string}`
    draftMedia?: boolean | `@${string}`
    draftStatus?: boolean | `@${string}`
    draftSubmittedAt?: boolean | `@${string}`
    draftText?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isHidden?: boolean | `@${string}`
    reviewFeedback?: ResolverInputTypes['ReviewFeedback']
    reviewedAt?: boolean | `@${string}`
    reviewerFirstName?: boolean | `@${string}`
    reviewerLastName?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['EndCampaignOutput']: AliasType<{
    endDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['EtailerProductMetrics']: AliasType<{
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    pageViews?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    totalSalesAmount?: boolean | `@${string}`
    unitsSold?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['LambdaResponse']: AliasType<{
    code?: boolean | `@${string}`
    message?: boolean | `@${string}`
    success?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ListingBadgeRecord']: AliasType<{
    badge?: boolean | `@${string}`
    date?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsAgency']: AliasType<{
    items?: ResolverInputTypes['Agency']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsBrand']: AliasType<{
    items?: ResolverInputTypes['Brand']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsBrandApi']: AliasType<{
    items?: ResolverInputTypes['BrandApi']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsCampaignClick']: AliasType<{
    items?: ResolverInputTypes['CampaignClick']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsCompany']: AliasType<{
    items?: ResolverInputTypes['Company']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsEtailerProductMetrics']: AliasType<{
    items?: ResolverInputTypes['EtailerProductMetrics']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsPricing']: AliasType<{
    items?: ResolverInputTypes['Pricing']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProduct']: AliasType<{
    items?: ResolverInputTypes['Product']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProductKeywordRecord']: AliasType<{
    items?: ResolverInputTypes['ProductKeywordRecord']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProductKeywordSummary']: AliasType<{
    items?: ResolverInputTypes['ProductKeywordSummary']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProductRatingSnapshot']: AliasType<{
    items?: ResolverInputTypes['ProductRatingSnapshot']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProductRecommendation']: AliasType<{
    items?: ResolverInputTypes['ProductRecommendation']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsProposal']: AliasType<{
    items?: ResolverInputTypes['Proposal']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsScrapeRequestJob']: AliasType<{
    items?: ResolverInputTypes['ScrapeRequestJob']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsSubscriptionCreditAction']: AliasType<{
    items?: ResolverInputTypes['SubscriptionCreditAction']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUser']: AliasType<{
    items?: ResolverInputTypes['User']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUserAgency']: AliasType<{
    items?: ResolverInputTypes['UserAgency']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUserBrand']: AliasType<{
    items?: ResolverInputTypes['UserBrand']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUserCompany']: AliasType<{
    items?: ResolverInputTypes['UserCompany']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelItemsUserInvitation']: AliasType<{
    items?: ResolverInputTypes['UserInvitation']
    nextToken?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSearchItemssearchBrandContent']: AliasType<{
    items?: ResolverInputTypes['CreatorContent']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSearchItemssearchBrandCounts']: AliasType<{
    items?: ResolverInputTypes['SearchBrandCountsOutput']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSearchItemssearchBrandProducts']: AliasType<{
    items?: ResolverInputTypes['Product']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ModelSearchItemssearchRecommendations']: AliasType<{
    items?: ResolverInputTypes['Product']
    total?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Mutation']: AliasType<{
    adjustCampaignStartDate?: [
      { input: ResolverInputTypes['AdjustCampaignStartDateInput'] },
      ResolverInputTypes['AdjustCampaignStartDateOutput']
    ]
    adjustProductRecommendationVisibility?: [
      { input: ResolverInputTypes['AdjustProductRecommendationVisibilityInput'] },
      ResolverInputTypes['Product']
    ]
    adjustSubscriptionCredits?: [
      { input: ResolverInputTypes['AdjustCreditInput'] },
      ResolverInputTypes['SubscriptionCreditAction']
    ]
    approveContent?: [{ input: ResolverInputTypes['ApproveContentInput'] }, ResolverInputTypes['ApproveContentResonse']]
    createAgency?: [{ input: ResolverInputTypes['ModelInputCreateAgency'] }, ResolverInputTypes['Agency']]
    createBrand?: [{ input: ResolverInputTypes['CreateBrandInput'] }, ResolverInputTypes['Brand']]
    createCampaign?: [{ proposalId: string }, ResolverInputTypes['CreateCampaignOutput']]
    createCampaignBenchmark?: [
      { input: ResolverInputTypes['ModelInputCreateCampaignBenchmark'] },
      ResolverInputTypes['CampaignBenchmark']
    ]
    createCampaignPerformance?: [
      { input: ResolverInputTypes['ModelInputCreateCampaignPerformance'] },
      ResolverInputTypes['CampaignPerformance']
    ]
    createCampaignPerformanceProduct?: [
      { input: ResolverInputTypes['ModelInputCreateCampaignPerformanceProduct'] },
      ResolverInputTypes['CampaignPerformanceProduct']
    ]
    createCompany?: [{ input: ResolverInputTypes['ModelInputCreateCompany'] }, ResolverInputTypes['Company']]
    createManualProduct?: [{ input: ResolverInputTypes['ProductInput'] }, ResolverInputTypes['Product']]
    createPWProductSeoSummary?: [
      { input: ResolverInputTypes['ModelInputCreatePWProductSeoSummary'] },
      ResolverInputTypes['PWProductSeoSummary']
    ]
    createPricing?: [{ input: ResolverInputTypes['ModelInputCreatePricing'] }, ResolverInputTypes['Pricing']]
    createProductFromUrl?: [{ input: ResolverInputTypes['CreateProductFromUrlInput'] }, ResolverInputTypes['Product']]
    createProposal?: [{ input: ResolverInputTypes['ModelInputCreateProposal'] }, ResolverInputTypes['Proposal']]
    createProposalSlide?: [
      { input: ResolverInputTypes['CreateProposalSlideInput'] },
      ResolverInputTypes['CreateProposalSlideResult']
    ]
    createScrapeRequestJob?: [
      { input: ResolverInputTypes['ModelInputCreateScrapeRequestJob'] },
      ResolverInputTypes['ScrapeRequestJob']
    ]
    deactivateUser?: [{ id: string }, ResolverInputTypes['User']]
    deleteCampaignPerformance?: [
      { input: ResolverInputTypes['ModelInputDeleteCampaignPerformance'] },
      ResolverInputTypes['CampaignPerformance']
    ]
    deleteCampaignPerformanceProduct?: [
      { input: ResolverInputTypes['ModelInputDeleteCampaignPerformanceProduct'] },
      ResolverInputTypes['CampaignPerformanceProduct']
    ]
    deletePricing?: [{ input: ResolverInputTypes['ModelInputDeletePricing'] }, ResolverInputTypes['Pricing']]
    deleteProposal?: [{ input: ResolverInputTypes['ModelInputDeleteProposal'] }, ResolverInputTypes['Proposal']]
    deleteUserInvitation?: [
      { input: ResolverInputTypes['ModelInputDeleteUserInvitation'] },
      ResolverInputTypes['UserInvitation']
    ]
    endCampaign?: [{ campaignId: string }, ResolverInputTypes['EndCampaignOutput']]
    generateFeedbackReport?: [{ campaignId: string }, ResolverInputTypes['ProductFeedbackSummary']]
    inviteUserToMomentum?: [{ input: ResolverInputTypes['UserInvitationInput'] }, ResolverInputTypes['UserInvitation']]
    pingActivity?: [{ userId?: string | undefined | null }, boolean | `@${string}`]
    reactivateUser?: [{ id: string }, ResolverInputTypes['User']]
    refreshRecommendations?: [
      { brandId: string; stores: Array<ResolverInputTypes['Store']> },
      ResolverInputTypes['RefreshRecommendationsOutput']
    ]
    requestContentEdits?: [
      { input: ResolverInputTypes['RequestContentEditsInput'] },
      ResolverInputTypes['ReviewFeedback']
    ]
    requestUploadFileLink?: [
      {
        additionalParameters?: string | undefined | null
        extension: string
        uploadRequestType: ResolverInputTypes['UploadRequestType']
      },
      ResolverInputTypes['RequestUploadFileLinkResult']
    ]
    resetPassword?: [{ code: string; password: string; username: string }, ResolverInputTypes['LambdaResponse']]
    signupUser?: [
      { attributes?: string | undefined | null; password: string; username: string },
      ResolverInputTypes['LambdaResponse']
    ]
    submitCampaignCreatorApproval?: [
      { input: ResolverInputTypes['SubmitCampaignCreatorApprovalInput'] },
      ResolverInputTypes['CampaignCreatorForApproval']
    ]
    toggleContentVisibility?: [
      { input: ResolverInputTypes['ToggleContentVisibilityInput'] },
      ResolverInputTypes['ToggleContentVisibilityResonse']
    ]
    updateAgency?: [{ input: ResolverInputTypes['ModelInputUpdateAgency'] }, ResolverInputTypes['Agency']]
    updateBrand?: [{ input: ResolverInputTypes['UpdateBrandInput'] }, ResolverInputTypes['Brand']]
    updateCampaignBenchmark?: [
      { input: ResolverInputTypes['ModelInputUpdateCampaignBenchmark'] },
      ResolverInputTypes['CampaignBenchmark']
    ]
    updateCampaignPerformance?: [
      { input: ResolverInputTypes['ModelInputUpdateCampaignPerformance'] },
      ResolverInputTypes['CampaignPerformance']
    ]
    updateCampaignPerformanceProduct?: [
      { input: ResolverInputTypes['ModelInputUpdateCampaignPerformanceProduct'] },
      ResolverInputTypes['CampaignPerformanceProduct']
    ]
    updateCompany?: [{ input: ResolverInputTypes['ModelInputUpdateCompany'] }, ResolverInputTypes['Company']]
    updateManualProduct?: [{ input: ResolverInputTypes['UpdateManualProductInput'] }, ResolverInputTypes['Product']]
    updatePWProductSeoSummary?: [
      { input: ResolverInputTypes['ModelInputUpdatePWProductSeoSummary'] },
      ResolverInputTypes['PWProductSeoSummary']
    ]
    updatePricing?: [{ input: ResolverInputTypes['ModelInputUpdatePricing'] }, ResolverInputTypes['Pricing']]
    updateProductKeywordSummary?: [
      { input: ResolverInputTypes['ModelInputUpdateProductKeywordSummary'] },
      ResolverInputTypes['ProductKeywordSummary']
    ]
    updateProductSeoSummary?: [
      { input: ResolverInputTypes['ModelInputUpdateProductSeoSummary'] },
      ResolverInputTypes['ProductSeoSummary']
    ]
    updateProposal?: [{ input: ResolverInputTypes['ModelInputUpdateProposal'] }, ResolverInputTypes['Proposal']]
    updateScrapeRequestJob?: [
      { input: ResolverInputTypes['ModelInputUpdateScrapeRequestJob'] },
      ResolverInputTypes['ScrapeRequestJob']
    ]
    updateUser?: [{ input: ResolverInputTypes['ModelInputUpdateUser'] }, ResolverInputTypes['User']]
    updateUserAgency?: [{ input: ResolverInputTypes['ModelInputUpdateUserAgency'] }, ResolverInputTypes['UserAgency']]
    updateUserCampaignSocialContent?: [
      { input: ResolverInputTypes['ModelInputUpdateUserCampaignSocialContent'] },
      ResolverInputTypes['UserCampaignSocialContent']
    ]
    updateUserInvitation?: [
      { input: ResolverInputTypes['ModelInputUpdateUserInvitation'] },
      ResolverInputTypes['UserInvitation']
    ]
    uploadCreatorContent?: [
      { input: ResolverInputTypes['UploadCreatorContentInput'] },
      ResolverInputTypes['ContentGroup']
    ]
    uploadProductSeoData?: [
      { input: ResolverInputTypes['UploadProductSeoDataInput'] },
      ResolverInputTypes['ProductSeoSummary']
    ]
    __typename?: boolean | `@${string}`
  }>
  ['PWProductSEOSummaryKeyword']: AliasType<{
    badges?: ResolverInputTypes['ListingBadgeRecord']
    highestRank?: boolean | `@${string}`
    impressions?: boolean | `@${string}`
    keyword?: boolean | `@${string}`
    pageOneWin?: boolean | `@${string}`
    pageOneWinDate?: boolean | `@${string}`
    sponsoredHighestRank?: boolean | `@${string}`
    sponsoredStartRank?: boolean | `@${string}`
    startRank?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PWProductSEOSummaryRecord']: AliasType<{
    categoryRanks?: ResolverInputTypes['CategoryRank']
    impressions?: boolean | `@${string}`
    isOutOfStock?: boolean | `@${string}`
    topPageWins?: boolean | `@${string}`
    weekIndex?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PWProductSeoSummary']: AliasType<{
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    keywords?: ResolverInputTypes['PWProductSEOSummaryKeyword']
    productSEOSummaryRecords?: ResolverInputTypes['PWProductSEOSummaryRecord']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Pricing']: AliasType<{
    createdAt?: boolean | `@${string}`
    creatorPricing?: ResolverInputTypes['CreatorPricing']
    id?: boolean | `@${string}`
    store?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Product']: AliasType<{
    active?: boolean | `@${string}`
    availabilityDate?: boolean | `@${string}`
    brandId?: boolean | `@${string}`
    categories?: boolean | `@${string}`
    childSkus?: ResolverInputTypes['ModelItemsProduct']
    createdAt?: boolean | `@${string}`
    createdByUserId?: boolean | `@${string}`
    creationSource?: boolean | `@${string}`
    creationSourceUrl?: boolean | `@${string}`
    estimatedAvailabilityDate?: boolean | `@${string}`
    id?: boolean | `@${string}`
    image?: boolean | `@${string}`
    initialEtailerProductMetric?: ResolverInputTypes['EtailerProductMetrics']
    isRecommendationsHidden?: boolean | `@${string}`
    itemClassification?: boolean | `@${string}`
    lastScrapedAt?: boolean | `@${string}`
    listingFulfillmentType?: boolean | `@${string}`
    listingLink?: boolean | `@${string}`
    name?: boolean | `@${string}`
    parentSku?: ResolverInputTypes['Product']
    parentSkuId?: boolean | `@${string}`
    price?: boolean | `@${string}`
    priceCents?: boolean | `@${string}`
    productImageKey?: boolean | `@${string}`
    ratingSnapshots?: ResolverInputTypes['ModelItemsProductRatingSnapshot']
    ratingSummary?: ResolverInputTypes['ProductRatingSnapshot']
    recommendations?: ResolverInputTypes['ProductRecommendation']
    skuId?: boolean | `@${string}`
    store?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductFeedbackSummary']: AliasType<{
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    failureReason?: boolean | `@${string}`
    jsonDownloadUrl?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductKeywordRecord']: AliasType<{
    campaignId?: boolean | `@${string}`
    campaignId_keyword?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    date?: boolean | `@${string}`
    keyword?: boolean | `@${string}`
    keywordSales?: boolean | `@${string}`
    organicRank?: boolean | `@${string}`
    searchVolume?: boolean | `@${string}`
    sponsoredRank?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductKeywordSummary']: AliasType<{
    campaignId?: boolean | `@${string}`
    campaignId_keyword?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    hasAmazonChoiceBadge?: boolean | `@${string}`
    keyword?: boolean | `@${string}`
    organicHighestRank?: boolean | `@${string}`
    organicPageOneWin?: boolean | `@${string}`
    organicPageOneWinDate?: boolean | `@${string}`
    organicStartRank?: boolean | `@${string}`
    records?: ResolverInputTypes['ProductKeywordRecord']
    searchVolume?: boolean | `@${string}`
    sponsoredHighestRank?: boolean | `@${string}`
    sponsoredPageOneWin?: boolean | `@${string}`
    sponsoredPageOneWinDate?: boolean | `@${string}`
    sponsoredStartRank?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductPerformanceRecord']: AliasType<{
    date?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    reviewCount?: boolean | `@${string}`
    salesRank?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductRatingSnapshot']: AliasType<{
    numRatings?: boolean | `@${string}`
    numReviews?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    snapshotAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductRecommendation']: AliasType<{
    createdAt?: boolean | `@${string}`
    recommendationType?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductSeoSummary']: AliasType<{
    campaignId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    productSeoSummaryRecords?: ResolverInputTypes['ProductSeoSummaryRecord']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProductSeoSummaryRecord']: AliasType<{
    fromDate?: boolean | `@${string}`
    isOutOfStock?: boolean | `@${string}`
    organicPageOneWins?: boolean | `@${string}`
    searchVolume?: boolean | `@${string}`
    sponsoredPageOneWins?: boolean | `@${string}`
    toDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Proposal']: AliasType<{
    benchmarkProducts?: boolean | `@${string}`
    billingContacts?: ResolverInputTypes['BillingContact']
    brand?: ResolverInputTypes['Brand']
    brandId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    createdBy?: ResolverInputTypes['User']
    createdByUserId?: boolean | `@${string}`
    creatorPricing?: ResolverInputTypes['ProposalCreatorPricing']
    creditActionId?: boolean | `@${string}`
    durationWeeks?: boolean | `@${string}`
    estimatedAverageRating?: boolean | `@${string}`
    estimatedUnitsSoldPerMonth?: boolean | `@${string}`
    estimatedUnitsSoldPerWeek?: boolean | `@${string}`
    exchangeRate?: boolean | `@${string}`
    goal?: boolean | `@${string}`
    hiddenEndRating?: boolean | `@${string}`
    hiddenNumReviews?: boolean | `@${string}`
    hiddenPdpTraffic?: boolean | `@${string}`
    hiddenRetailEfficiency?: boolean | `@${string}`
    hiddenSeo?: boolean | `@${string}`
    hiddenSocialContent?: boolean | `@${string}`
    hiddenUgcContent?: boolean | `@${string}`
    hubspotDealId?: boolean | `@${string}`
    id?: boolean | `@${string}`
    invoiceDueDate?: boolean | `@${string}`
    invoiceId?: boolean | `@${string}`
    invoiceLink?: boolean | `@${string}`
    invoiceNumber?: boolean | `@${string}`
    invoicePONumber?: boolean | `@${string}`
    invoicePaidDate?: boolean | `@${string}`
    invoiceStatus?: boolean | `@${string}`
    isDailyScheduling?: boolean | `@${string}`
    launchDate?: boolean | `@${string}`
    liftCloseoutBonus?: boolean | `@${string}`
    liftNumCreators?: boolean | `@${string}`
    operatingCost?: boolean | `@${string}`
    paymentType?: boolean | `@${string}`
    product?: ResolverInputTypes['Product']
    productId?: boolean | `@${string}`
    productPrice?: boolean | `@${string}`
    productPriceOverride?: boolean | `@${string}`
    productRating?: boolean | `@${string}`
    productRatingCount?: boolean | `@${string}`
    productVariationSkus?: boolean | `@${string}`
    ratingGoal?: boolean | `@${string}`
    referralCost?: boolean | `@${string}`
    searchTerms?: boolean | `@${string}`
    status?: boolean | `@${string}`
    statusChangedAt?: boolean | `@${string}`
    submittedAt?: boolean | `@${string}`
    submittedBy?: ResolverInputTypes['User']
    submittedByUserId?: boolean | `@${string}`
    title?: boolean | `@${string}`
    totalCredits?: boolean | `@${string}`
    totalCreditsPaid?: boolean | `@${string}`
    totalProductCostCredits?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    updatedBy?: ResolverInputTypes['User']
    updatedByUserId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ProposalCreatorPricing']: AliasType<{
    closeoutBonus?: boolean | `@${string}`
    contentRequirements?: ResolverInputTypes['ContentRequirement']
    hidden?: boolean | `@${string}`
    numCreators?: boolean | `@${string}`
    price?: boolean | `@${string}`
    schedule?: boolean | `@${string}`
    type?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['PurchaseDetails']: AliasType<{
    amountCents?: boolean | `@${string}`
    purchaseDate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Query']: AliasType<{
    companiesByAgency?: [
      {
        agencyId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsCompany']
    ]
    generateCompanyRollupReport?: [{ companyId: string }, boolean | `@${string}`]
    getAgency?: [{ id: string }, ResolverInputTypes['Agency']]
    getAmazonAuthorizationUri?: [
      { brandApiType: ResolverInputTypes['BrandApiType']; brandId: string; redirectUri: string },
      boolean | `@${string}`
    ]
    getBenchmarkMetrics?: [
      { benchmarkGoal: number; benchmarkType: ResolverInputTypes['BenchmarkType']; skuId: string },
      ResolverInputTypes['EtailerProductMetrics']
    ]
    getBrand?: [{ id: string }, ResolverInputTypes['Brand']]
    getBrandApi?: [{ api: ResolverInputTypes['BrandApiType']; brandId: string }, ResolverInputTypes['BrandApi']]
    getCampaign?: [{ campaignId: string }, ResolverInputTypes['CampaignDetails']]
    getCampaignBenchmark?: [{ campaignId: string; skuId: string }, ResolverInputTypes['CampaignBenchmark']]
    getCampaignPerformance?: [{ campaignId: string; skuId: string }, ResolverInputTypes['CampaignPerformance']]
    getCampaignPerformanceProduct?: [{ id: string }, ResolverInputTypes['CampaignPerformanceProduct']]
    getCampaignReviewMetrics?: [
      { campaignId: string; endDate?: string | undefined | null; startDate: string },
      ResolverInputTypes['CampaignProductReviewRatingMetric']
    ]
    getCompany?: [{ id: string }, ResolverInputTypes['Company']]
    getPWProductSeoSummary?: [{ campaignId: string }, ResolverInputTypes['PWProductSeoSummary']]
    getPricing?: [{ id: string; store: ResolverInputTypes['Store'] }, ResolverInputTypes['Pricing']]
    getProduct?: [{ id: string }, ResolverInputTypes['Product']]
    getProductFeedbackSummary?: [{ campaignId: string }, ResolverInputTypes['ProductFeedbackSummary']]
    getProductRecommendation?: [
      { recommendationType: ResolverInputTypes['RecommendationType']; skuId: string },
      ResolverInputTypes['ProductRecommendation']
    ]
    getProductSeoSummary?: [{ campaignId: string }, ResolverInputTypes['ProductSeoSummary']]
    getProposal?: [{ id: string }, ResolverInputTypes['Proposal']]
    getUser?: [{ id: string }, ResolverInputTypes['User']]
    getUserAgency?: [{ agencyId: string; userId: string }, ResolverInputTypes['UserAgency']]
    getUserBrand?: [{ brandId: string; userId: string }, ResolverInputTypes['UserBrand']]
    getUserCompany?: [{ companyId: string; userId: string }, ResolverInputTypes['UserCompany']]
    getUserInvitation?: [{ id: string }, ResolverInputTypes['UserInvitation']]
    listAgencies?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsAgency']
    ]
    listBrandApis?: [
      {
        api?: ResolverInputTypes['ModelBrandApiSkKeyConditionInput'] | undefined | null
        brandId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsBrandApi']
    ]
    listBrandCampaigns?: [{ brandId?: string | undefined | null }, ResolverInputTypes['Campaign']]
    listBrandsByActive?: [
      {
        active?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsBrand']
    ]
    listBrandsByActiveBySubscriptionEndsAt?: [
      {
        active?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        subscriptionEndsAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsBrand']
    ]
    listBrandsByCompanyId?: [
      {
        companyId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsBrand']
    ]
    listCampaignClicks?: [
      {
        campaignId?: string | undefined | null
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsCampaignClick']
    ]
    listCampaignCreatorsForApproval?: [{ campaignId: string }, ResolverInputTypes['CampaignCreatorForApproval']]
    listCompanies?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsCompany']
    ]
    listContentPendingApproval?: [{ campaignId: string }, ResolverInputTypes['CreatorDraftContent']]
    listEtailerProductMetrics?: [
      {
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsEtailerProductMetrics']
    ]
    listInvitationsByAgency?: [
      {
        agencyId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        status?: ResolverInputTypes['ModelMomentumInvitationSkKeyConditionInput'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsUserInvitation']
    ]
    listInvitationsByCompany?: [
      {
        companyId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        status?: ResolverInputTypes['ModelMomentumInvitationSkKeyConditionInput'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsUserInvitation']
    ]
    listInvitationsByStatus?: [
      {
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        status?: ResolverInputTypes['UserInvitationStatus'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsUserInvitation']
    ]
    listPricings?: [
      {
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        store?: ResolverInputTypes['ModelCreatorPricingSkKeyConditionInput'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsPricing']
    ]
    listProductKeywordRecords?: [
      {
        campaignId_keyword?: string | undefined | null
        date?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsProductKeywordRecord']
    ]
    listProductKeywordSummaries?: [
      {
        campaignId?: string | undefined | null
        keyword?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsProductKeywordSummary']
    ]
    listProductRecommendations?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        recommendationType?: ResolverInputTypes['ModelProductRecommendationsSkKeyConditionInput'] | undefined | null
        segment?: number | undefined | null
        skuId?: string | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsProductRecommendation']
    ]
    listProducts?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsProduct']
    ]
    listProductsByBrandId?: [
      {
        brandId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsProduct']
    ]
    listProposals?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsProposal']
    ]
    listProposalsByBrandId?: [
      {
        brandId?: string | undefined | null
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsProposal']
    ]
    listSubscriptionCreditActions?: [
      {
        actionId?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        id?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsSubscriptionCreditAction']
    ]
    listUserAgencies?: [
      {
        agencyId?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelItemsUserAgency']
    ]
    listUserAgenciesByAgencyId?: [
      {
        agencyId?: string | undefined | null
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsUserAgency']
    ]
    listUserBrands?: [
      {
        brandId?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelItemsUserBrand']
    ]
    listUserCompanies?: [
      {
        companyId?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
        userId?: string | undefined | null
      },
      ResolverInputTypes['ModelItemsUserCompany']
    ]
    listUserCompaniesByCompanyId?: [
      {
        companyId?: string | undefined | null
        createdAt?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsUserCompany']
    ]
    listUsers?: [
      {
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsUser']
    ]
    requestExchangeRate?: [
      { input: ResolverInputTypes['RequestExchangeRateInput'] },
      ResolverInputTypes['RequestExchangeRateOutput']
    ]
    scrapeRequestByBrandIdBySku?: [
      {
        brandId?: string | undefined | null
        limit?: number | undefined | null
        nextToken?: string | undefined | null
        segment?: number | undefined | null
        sku?: ResolverInputTypes['ModelStringKeyConditionInput'] | undefined | null
        sortDirection?: ResolverInputTypes['ModelSortDirection'] | undefined | null
        totalSegments?: number | undefined | null
      },
      ResolverInputTypes['ModelItemsScrapeRequestJob']
    ]
    searchBrandContent?: [
      {
        campaignIds: Array<string>
        excludedContentTypes: Array<ResolverInputTypes['ContentType'] | undefined | null>
        from?: number | undefined | null
        isFavorite: boolean
        isFavoriteMinShouldMatch: number
        minShouldMatch: number
        search?: string | undefined | null
        size?: number | undefined | null
        sortBy: string
      },
      ResolverInputTypes['ModelSearchItemssearchBrandContent']
    ]
    searchBrandCounts?: [
      { args?: string | undefined | null; from?: number | undefined | null; size?: number | undefined | null },
      ResolverInputTypes['ModelSearchItemssearchBrandCounts']
    ]
    searchBrandProducts?: [
      { brandId: string; from?: number | undefined | null; size?: number | undefined | null },
      ResolverInputTypes['ModelSearchItemssearchBrandProducts']
    ]
    searchRecommendations?: [
      {
        archivedMinShouldMatch: number
        brandIds?: Array<string> | undefined | null
        from?: number | undefined | null
        isArchived?: boolean | undefined | null
        minShouldMatch: number
        recommendationTypes: Array<ResolverInputTypes['RecommendationType']>
        search?: string | undefined | null
        size?: number | undefined | null
        sortBy: string
        stores?: Array<ResolverInputTypes['Store']> | undefined | null
      },
      ResolverInputTypes['ModelSearchItemssearchRecommendations']
    ]
    __typename?: boolean | `@${string}`
  }>
  ['RefreshRecommendationsOutput']: AliasType<{
    isSuccess?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ReleaseSchedule']: AliasType<{
    brandAdvocatesWeeklySchedule?: boolean | `@${string}`
    premiumUgcWeeklySchedule?: boolean | `@${string}`
    socialWeeklySchedule?: boolean | `@${string}`
    ugcWeeklySchedule?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['RequestExchangeRateOutput']: AliasType<{
    rate?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['RequestUploadFileLinkResult']: AliasType<{
    key?: boolean | `@${string}`
    link?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['Review']: AliasType<{
    attributedUserId?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isVine?: boolean | `@${string}`
    name?: boolean | `@${string}`
    rating?: boolean | `@${string}`
    reviewedAt?: boolean | `@${string}`
    text?: boolean | `@${string}`
    title?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ReviewFeedback']: AliasType<{
    atTime?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    createdByFirstName?: boolean | `@${string}`
    createdByLastName?: boolean | `@${string}`
    forAssetAtIndex?: boolean | `@${string}`
    message?: boolean | `@${string}`
    visibleToUser?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ScrapeRequestJob']: AliasType<{
    brandId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    expirationTime?: boolean | `@${string}`
    id?: boolean | `@${string}`
    message?: boolean | `@${string}`
    sku?: boolean | `@${string}`
    skuId?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SearchBrandCountsOutput']: AliasType<{
    brandId?: boolean | `@${string}`
    totalProducts?: boolean | `@${string}`
    totalRecommendations?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SocialProfile']: AliasType<{
    engagementRate?: boolean | `@${string}`
    followers?: boolean | `@${string}`
    handle?: boolean | `@${string}`
    isPrimary?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['SubscriptionCreditAction']: AliasType<{
    action?: boolean | `@${string}`
    actionCreatedByFirstName?: boolean | `@${string}`
    actionCreatedByIP?: boolean | `@${string}`
    actionCreatedById?: boolean | `@${string}`
    actionCreatedByLastName?: boolean | `@${string}`
    actionId?: boolean | `@${string}`
    associatedProposalId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    label?: boolean | `@${string}`
    numCredits?: boolean | `@${string}`
    proposal?: ResolverInputTypes['Proposal']
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['ToggleContentVisibilityResonse']: AliasType<{
    isHidden?: boolean | `@${string}`
    userCampaignSocialContentId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['User']: AliasType<{
    accessLevel?: boolean | `@${string}`
    companies?: ResolverInputTypes['UserCompany']
    createdAt?: boolean | `@${string}`
    email?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    id?: boolean | `@${string}`
    invitationId?: boolean | `@${string}`
    lastActiveAt?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    phoneNumber?: boolean | `@${string}`
    primaryBrandId?: boolean | `@${string}`
    profilePicture?: boolean | `@${string}`
    role?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserAgency']: AliasType<{
    agency?: ResolverInputTypes['Agency']
    agencyId?: boolean | `@${string}`
    companies?: ResolverInputTypes['Company']
    createdAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserBrand']: AliasType<{
    brand?: ResolverInputTypes['Brand']
    brandId?: boolean | `@${string}`
    companyId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCampaignSocialContent']: AliasType<{
    createdAt?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isFavorite?: boolean | `@${string}`
    isHidden?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserCompany']: AliasType<{
    company?: ResolverInputTypes['Company']
    companyId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    isAllBrandAccess?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    user?: ResolverInputTypes['User']
    userBrands?: ResolverInputTypes['UserBrand']
    userId?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['UserInvitation']: AliasType<{
    accessLevel?: boolean | `@${string}`
    agencyId?: boolean | `@${string}`
    brandIds?: boolean | `@${string}`
    company?: ResolverInputTypes['Company']
    companyId?: boolean | `@${string}`
    createdAt?: boolean | `@${string}`
    email?: boolean | `@${string}`
    firstName?: boolean | `@${string}`
    id?: boolean | `@${string}`
    isAllBrandAccess?: boolean | `@${string}`
    lastName?: boolean | `@${string}`
    phoneNumber?: boolean | `@${string}`
    primaryBrandId?: boolean | `@${string}`
    role?: boolean | `@${string}`
    status?: boolean | `@${string}`
    updatedAt?: boolean | `@${string}`
    __typename?: boolean | `@${string}`
  }>
  ['AccessLevel']: AccessLevel
  ['BenchmarkType']: BenchmarkType
  ['BrandApiType']: BrandApiType
  ['BrandApiVersion']: BrandApiVersion
  ['CampaignCreatorApprovalAction']: CampaignCreatorApprovalAction
  ['CampaignCreatorStatus']: CampaignCreatorStatus
  ['Channel']: Channel
  ['ContentType']: ContentType
  ['ContentUploadType']: ContentUploadType
  ['CreatorApprovalStatus']: CreatorApprovalStatus
  ['CreatorType']: CreatorType
  ['CreditAction']: CreditAction
  ['Currency']: Currency
  ['DraftStatus']: DraftStatus
  ['InvoicePaymentType']: InvoicePaymentType
  ['InvoiceStatus']: InvoiceStatus
  ['ItemClassification']: ItemClassification
  ['ListingBadge']: ListingBadge
  ['ListingFulfillmentType']: ListingFulfillmentType
  ['ModelSearchStringFieldInput']: ModelSearchStringFieldInput
  ['ModelSortDirection']: ModelSortDirection
  ['PaymentTermsType']: PaymentTermsType
  ['PerformanceComparisonMetric']: PerformanceComparisonMetric
  ['PerformanceSummaryTitleType']: PerformanceSummaryTitleType
  ['PricingCreatorType']: PricingCreatorType
  ['ProductCreationSource']: ProductCreationSource
  ['ProductFeedbackStatus']: ProductFeedbackStatus
  ['ProposalGoal']: ProposalGoal
  ['ProposalStatus']: ProposalStatus
  ['RecommendationType']: RecommendationType
  ['Region']: Region
  ['Role']: Role
  ['ScrapeRequestStatus']: ScrapeRequestStatus
  ['Store']: Store
  ['UploadRequestType']: UploadRequestType
  ['UserInvitationStatus']: UserInvitationStatus
  ['UserStatus']: UserStatus
  ['AdjustCampaignStartDateInput']: {
    campaignId?: string | undefined | null
    newCampaignStartDate?: string | undefined | null
  }
  ['AdjustCreditInput']: {
    action: ResolverInputTypes['CreditAction']
    associatedProposalId?: string | undefined | null
    id: string
    label?: string | undefined | null
    numCredits: number
  }
  ['AdjustProductRecommendationVisibilityInput']: {
    id: string
    isVisible: boolean
  }
  ['ApproveContentInput']: {
    userCampaignSocialContentId: string
  }
  ['CreateBrandInput']: {
    amazonBrandStoreUrl?: string | undefined | null
    brandApis?: Array<ResolverInputTypes['BrandApiType']> | undefined | null
    companyId: string
    id?: string | undefined | null
    isBrandStoreScrapeEnabled?: boolean | undefined | null
    isWalmartScrapeEnabled?: boolean | undefined | null
    logo?: string | undefined | null
    name: string
    nameAudioKey?: string | undefined | null
    region: ResolverInputTypes['Region']
    walmartScrapeUrl?: string | undefined | null
  }
  ['CreateProductFromUrlInput']: {
    brandId: string
    productUrl: string
  }
  ['CreateProposalSlideInput']: {
    creatorPricing: Array<ResolverInputTypes['CreatorPricingInput']>
    exchangeRate: number
    goal: ResolverInputTypes['ProposalGoal']
    productId: string
    ratingGoal?: number | undefined | null
  }
  ['CreatorPricingInput']: {
    numCreators: number
    price: number
    type: ResolverInputTypes['PricingCreatorType']
  }
  ['ModelBrandApiSkKeyConditionInput']: {
    beginsWith?: ResolverInputTypes['BrandApiType'] | undefined | null
    between?: Array<ResolverInputTypes['BrandApiType'] | undefined | null> | undefined | null
    eq?: ResolverInputTypes['BrandApiType'] | undefined | null
    ge?: ResolverInputTypes['BrandApiType'] | undefined | null
    gt?: ResolverInputTypes['BrandApiType'] | undefined | null
    le?: ResolverInputTypes['BrandApiType'] | undefined | null
    lt?: ResolverInputTypes['BrandApiType'] | undefined | null
  }
  ['ModelCreatorPricingSkKeyConditionInput']: {
    beginsWith?: ResolverInputTypes['Store'] | undefined | null
    between?: Array<ResolverInputTypes['Store'] | undefined | null> | undefined | null
    eq?: ResolverInputTypes['Store'] | undefined | null
    ge?: ResolverInputTypes['Store'] | undefined | null
    gt?: ResolverInputTypes['Store'] | undefined | null
    le?: ResolverInputTypes['Store'] | undefined | null
    lt?: ResolverInputTypes['Store'] | undefined | null
  }
  ['ModelInputCreateAgency']: {
    clientPricingId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    logo: string
    name: string
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ResolverInputTypes['PaymentTermsType'] | undefined | null
    updatedAt?: string | undefined | null
    websiteUrl: string
  }
  ['ModelInputCreateCampaignBenchmark']: {
    benchmarkGoal: number
    benchmarkType: ResolverInputTypes['BenchmarkType']
    benchmarkedProductIds: Array<string>
    campaignId: string
    createdAt?: string | undefined | null
    skuId: string
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreateCampaignPerformance']: {
    campaignId: string
    comparisonMetric?: ResolverInputTypes['PerformanceComparisonMetric'] | undefined | null
    comparisonMetricValue?: number | undefined | null
    createdAt?: string | undefined | null
    isBsrDataUnavailable?: boolean | undefined | null
    isBsrVisible?: boolean | undefined | null
    isTtrDataUnavailable?: boolean | undefined | null
    isTtrVisible?: boolean | undefined | null
    isVisible?: boolean | undefined | null
    promotedProductId: string
    skuId: string
    summaryTitle?: ResolverInputTypes['PerformanceSummaryTitleType'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreateCampaignPerformanceProduct']: {
    bestSellerRankDataUploaded?: boolean | undefined | null
    campaignId: string
    comparisonMetric?: ResolverInputTypes['PerformanceComparisonMetric'] | undefined | null
    createdAt?: string | undefined | null
    id: string
    isCategoryAverage?: boolean | undefined | null
    isVisible?: boolean | undefined | null
    productImage?: string | undefined | null
    productName: string
    productPerformanceRecords?:
      | Array<ResolverInputTypes['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']>
      | undefined
      | null
    productUrl?: string | undefined | null
    reviewDataUploaded?: boolean | undefined | null
    skuId: string
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']: {
    date: string
    rating?: number | undefined | null
    reviewCount?: number | undefined | null
    salesRank?: number | undefined | null
  }
  ['ModelInputCreateCompany']: {
    agencyId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    logo?: string | undefined | null
    name: string
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ResolverInputTypes['PaymentTermsType'] | undefined | null
    updatedAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['ModelInputCreatePWProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined | null
    keywords?: Array<ResolverInputTypes['ModelInputCreatePWProductSeoSummaryKeywordsInput']> | undefined | null
    productSEOSummaryRecords?:
      | Array<ResolverInputTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']>
      | undefined
      | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreatePWProductSeoSummaryKeywordsInput']: {
    badges?: Array<ResolverInputTypes['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']> | undefined | null
    highestRank?: number | undefined | null
    impressions: number
    keyword: string
    pageOneWin?: boolean | undefined | null
    pageOneWinDate?: string | undefined | null
    sponsoredHighestRank?: number | undefined | null
    sponsoredStartRank?: number | undefined | null
    startRank?: number | undefined | null
  }
  ['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']: {
    badge: ResolverInputTypes['ListingBadge']
    date?: string | undefined | null
  }
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']: {
    categoryRanks?:
      | Array<ResolverInputTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']>
      | undefined
      | null
    impressions?: number | undefined | null
    isOutOfStock?: boolean | undefined | null
    topPageWins?: number | undefined | null
    weekIndex: number
  }
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: {
    category: string
    rank: number
  }
  ['ModelInputCreatePricing']: {
    createdAt?: string | undefined | null
    creatorPricing?: Array<ResolverInputTypes['ModelInputCreatePricingCreatorPricingInput']> | undefined | null
    id: string
    store: ResolverInputTypes['Store']
    updatedAt?: string | undefined | null
  }
  ['ModelInputCreatePricingCreatorPricingInput']: {
    price: number
    type: ResolverInputTypes['PricingCreatorType']
  }
  ['ModelInputCreateProposal']: {
    benchmarkProducts?: Array<string> | undefined | null
    billingContacts?: Array<ResolverInputTypes['ModelInputCreateProposalBillingContactsInput']> | undefined | null
    brandId: string
    createdAt?: string | undefined | null
    createdByUserId?: string | undefined | null
    creatorPricing?: Array<ResolverInputTypes['ModelInputCreateProposalCreatorPricingInput']> | undefined | null
    creditActionId?: string | undefined | null
    durationWeeks?: number | undefined | null
    estimatedAverageRating?: number | undefined | null
    estimatedUnitsSoldPerMonth?: number | undefined | null
    estimatedUnitsSoldPerWeek?: number | undefined | null
    exchangeRate?: number | undefined | null
    goal: ResolverInputTypes['ProposalGoal']
    hiddenEndRating?: boolean | undefined | null
    hiddenNumReviews?: boolean | undefined | null
    hiddenPdpTraffic?: boolean | undefined | null
    hiddenRetailEfficiency?: boolean | undefined | null
    hiddenSeo?: boolean | undefined | null
    hiddenSocialContent?: boolean | undefined | null
    hiddenUgcContent?: boolean | undefined | null
    hubspotDealId?: string | undefined | null
    id: string
    invoiceDueDate?: string | undefined | null
    invoiceId?: string | undefined | null
    invoiceLink?: string | undefined | null
    invoiceNumber?: string | undefined | null
    invoicePONumber?: string | undefined | null
    invoicePaidDate?: string | undefined | null
    invoiceStatus?: ResolverInputTypes['InvoiceStatus'] | undefined | null
    isDailyScheduling?: boolean | undefined | null
    launchDate: string
    liftCloseoutBonus?: number | undefined | null
    liftNumCreators?: number | undefined | null
    operatingCost?: number | undefined | null
    paymentType?: ResolverInputTypes['InvoicePaymentType'] | undefined | null
    productId: string
    productPrice?: number | undefined | null
    productPriceOverride?: number | undefined | null
    productRating?: number | undefined | null
    productRatingCount?: number | undefined | null
    productVariationSkus?: Array<string> | undefined | null
    ratingGoal?: number | undefined | null
    referralCost?: number | undefined | null
    searchTerms?: Array<string> | undefined | null
    status: ResolverInputTypes['ProposalStatus']
    statusChangedAt: string
    submittedAt?: string | undefined | null
    submittedByUserId?: string | undefined | null
    title?: string | undefined | null
    totalCredits?: number | undefined | null
    totalCreditsPaid?: number | undefined | null
    totalProductCostCredits?: number | undefined | null
    updatedAt?: string | undefined | null
    updatedByUserId?: string | undefined | null
  }
  ['ModelInputCreateProposalBillingContactsInput']: {
    email: string
    name: string
  }
  ['ModelInputCreateProposalCreatorPricingInput']: {
    closeoutBonus?: number | undefined | null
    contentRequirements?:
      | Array<ResolverInputTypes['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']>
      | undefined
      | null
    hidden?: boolean | undefined | null
    numCreators: number
    price: number
    schedule: Array<number>
    type: ResolverInputTypes['CreatorType']
  }
  ['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']: {
    bonus: number
    contentType: ResolverInputTypes['ContentType']
  }
  ['ModelInputCreateScrapeRequestJob']: {
    brandId?: string | undefined | null
    createdAt?: string | undefined | null
    expirationTime: string
    id: string
    message?: string | undefined | null
    sku?: string | undefined | null
    skuId?: string | undefined | null
    status: ResolverInputTypes['ScrapeRequestStatus']
    updatedAt?: string | undefined | null
  }
  ['ModelInputDeleteCampaignPerformance']: {
    campaignId: string
    skuId: string
  }
  ['ModelInputDeleteCampaignPerformanceProduct']: {
    id: string
  }
  ['ModelInputDeletePricing']: {
    id: string
    store: ResolverInputTypes['Store']
  }
  ['ModelInputDeleteProposal']: {
    id: string
  }
  ['ModelInputDeleteUserInvitation']: {
    id: string
  }
  ['ModelInputUpdateAgency']: {
    clientPricingId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    logo?: string | undefined | null
    name?: string | undefined | null
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ResolverInputTypes['PaymentTermsType'] | undefined | null
    updatedAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['ModelInputUpdateCampaignBenchmark']: {
    benchmarkGoal?: number | undefined | null
    benchmarkType?: ResolverInputTypes['BenchmarkType'] | undefined | null
    benchmarkedProductIds?: Array<string | undefined | null> | undefined | null
    campaignId: string
    createdAt?: string | undefined | null
    skuId: string
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateCampaignPerformance']: {
    campaignId: string
    comparisonMetric?: ResolverInputTypes['PerformanceComparisonMetric'] | undefined | null
    comparisonMetricValue?: number | undefined | null
    createdAt?: string | undefined | null
    isBsrDataUnavailable?: boolean | undefined | null
    isBsrVisible?: boolean | undefined | null
    isTtrDataUnavailable?: boolean | undefined | null
    isTtrVisible?: boolean | undefined | null
    isVisible?: boolean | undefined | null
    promotedProductId?: string | undefined | null
    skuId: string
    summaryTitle?: ResolverInputTypes['PerformanceSummaryTitleType'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateCampaignPerformanceProduct']: {
    bestSellerRankDataUploaded?: boolean | undefined | null
    campaignId?: string | undefined | null
    comparisonMetric?: ResolverInputTypes['PerformanceComparisonMetric'] | undefined | null
    createdAt?: string | undefined | null
    id: string
    isCategoryAverage?: boolean | undefined | null
    isVisible?: boolean | undefined | null
    productImage?: string | undefined | null
    productName?: string | undefined | null
    productPerformanceRecords?:
      | Array<
          | ResolverInputTypes['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput']
          | undefined
          | null
        >
      | undefined
      | null
    productUrl?: string | undefined | null
    reviewDataUploaded?: boolean | undefined | null
    skuId?: string | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput']: {
    date?: string | undefined | null
    rating?: number | undefined | null
    reviewCount?: number | undefined | null
    salesRank?: number | undefined | null
  }
  ['ModelInputUpdateCompany']: {
    agencyId?: string | undefined | null
    createdAt?: string | undefined | null
    id: string
    logo?: string | undefined | null
    name?: string | undefined | null
    paymentTermsCustomNetDays?: number | undefined | null
    paymentTermsType?: ResolverInputTypes['PaymentTermsType'] | undefined | null
    updatedAt?: string | undefined | null
    websiteUrl?: string | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined | null
    keywords?:
      | Array<ResolverInputTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInput'] | undefined | null>
      | undefined
      | null
    productSEOSummaryRecords?:
      | Array<ResolverInputTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput'] | undefined | null>
      | undefined
      | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInput']: {
    badges?:
      | Array<ResolverInputTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput'] | undefined | null>
      | undefined
      | null
    highestRank?: number | undefined | null
    impressions?: number | undefined | null
    keyword?: string | undefined | null
    pageOneWin?: boolean | undefined | null
    pageOneWinDate?: string | undefined | null
    sponsoredHighestRank?: number | undefined | null
    sponsoredStartRank?: number | undefined | null
    startRank?: number | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput']: {
    badge: ResolverInputTypes['ListingBadge']
    date?: string | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput']: {
    categoryRanks?:
      | Array<
          | ResolverInputTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']
          | undefined
          | null
        >
      | undefined
      | null
    impressions?: number | undefined | null
    isOutOfStock?: boolean | undefined | null
    topPageWins?: number | undefined | null
    weekIndex?: number | undefined | null
  }
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: {
    category: string
    rank: number
  }
  ['ModelInputUpdatePricing']: {
    createdAt?: string | undefined | null
    creatorPricing?:
      | Array<ResolverInputTypes['ModelInputUpdatePricingCreatorPricingInput'] | undefined | null>
      | undefined
      | null
    id: string
    store: ResolverInputTypes['Store']
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdatePricingCreatorPricingInput']: {
    price?: number | undefined | null
    type?: ResolverInputTypes['PricingCreatorType'] | undefined | null
  }
  ['ModelInputUpdateProductKeywordSummary']: {
    campaignId: string
    campaignId_keyword?: string | undefined | null
    createdAt?: string | undefined | null
    hasAmazonChoiceBadge?: boolean | undefined | null
    keyword: string
    organicHighestRank?: number | undefined | null
    organicPageOneWin?: boolean | undefined | null
    organicPageOneWinDate?: string | undefined | null
    organicStartRank?: number | undefined | null
    searchVolume?: number | undefined | null
    sponsoredHighestRank?: number | undefined | null
    sponsoredPageOneWin?: boolean | undefined | null
    sponsoredPageOneWinDate?: string | undefined | null
    sponsoredStartRank?: number | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined | null
    productSeoSummaryRecords?:
      | Array<ResolverInputTypes['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput'] | undefined | null>
      | undefined
      | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput']: {
    fromDate?: string | undefined | null
    isOutOfStock?: boolean | undefined | null
    organicPageOneWins?: number | undefined | null
    searchVolume?: number | undefined | null
    sponsoredPageOneWins?: number | undefined | null
    toDate?: string | undefined | null
  }
  ['ModelInputUpdateProposal']: {
    benchmarkProducts?: Array<string | undefined | null> | undefined | null
    billingContacts?:
      | Array<ResolverInputTypes['ModelInputUpdateProposalBillingContactsInput'] | undefined | null>
      | undefined
      | null
    brandId?: string | undefined | null
    createdAt?: string | undefined | null
    createdByUserId?: string | undefined | null
    creatorPricing?:
      | Array<ResolverInputTypes['ModelInputUpdateProposalCreatorPricingInput'] | undefined | null>
      | undefined
      | null
    creditActionId?: string | undefined | null
    durationWeeks?: number | undefined | null
    estimatedAverageRating?: number | undefined | null
    estimatedUnitsSoldPerMonth?: number | undefined | null
    estimatedUnitsSoldPerWeek?: number | undefined | null
    exchangeRate?: number | undefined | null
    goal?: ResolverInputTypes['ProposalGoal'] | undefined | null
    hiddenEndRating?: boolean | undefined | null
    hiddenNumReviews?: boolean | undefined | null
    hiddenPdpTraffic?: boolean | undefined | null
    hiddenRetailEfficiency?: boolean | undefined | null
    hiddenSeo?: boolean | undefined | null
    hiddenSocialContent?: boolean | undefined | null
    hiddenUgcContent?: boolean | undefined | null
    hubspotDealId?: string | undefined | null
    id: string
    invoiceDueDate?: string | undefined | null
    invoiceId?: string | undefined | null
    invoiceLink?: string | undefined | null
    invoiceNumber?: string | undefined | null
    invoicePONumber?: string | undefined | null
    invoicePaidDate?: string | undefined | null
    invoiceStatus?: ResolverInputTypes['InvoiceStatus'] | undefined | null
    isDailyScheduling?: boolean | undefined | null
    launchDate?: string | undefined | null
    liftCloseoutBonus?: number | undefined | null
    liftNumCreators?: number | undefined | null
    operatingCost?: number | undefined | null
    paymentType?: ResolverInputTypes['InvoicePaymentType'] | undefined | null
    productId?: string | undefined | null
    productPrice?: number | undefined | null
    productPriceOverride?: number | undefined | null
    productRating?: number | undefined | null
    productRatingCount?: number | undefined | null
    productVariationSkus?: Array<string | undefined | null> | undefined | null
    ratingGoal?: number | undefined | null
    referralCost?: number | undefined | null
    searchTerms?: Array<string | undefined | null> | undefined | null
    status?: ResolverInputTypes['ProposalStatus'] | undefined | null
    statusChangedAt?: string | undefined | null
    submittedAt?: string | undefined | null
    submittedByUserId?: string | undefined | null
    title?: string | undefined | null
    totalCredits?: number | undefined | null
    totalCreditsPaid?: number | undefined | null
    totalProductCostCredits?: number | undefined | null
    updatedAt?: string | undefined | null
    updatedByUserId?: string | undefined | null
  }
  ['ModelInputUpdateProposalBillingContactsInput']: {
    email?: string | undefined | null
    name?: string | undefined | null
  }
  ['ModelInputUpdateProposalCreatorPricingInput']: {
    closeoutBonus?: number | undefined | null
    contentRequirements?:
      | Array<
          ResolverInputTypes['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput'] | undefined | null
        >
      | undefined
      | null
    hidden?: boolean | undefined | null
    numCreators?: number | undefined | null
    price?: number | undefined | null
    schedule?: Array<number | undefined | null> | undefined | null
    type?: ResolverInputTypes['CreatorType'] | undefined | null
  }
  ['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput']: {
    bonus: number
    contentType: ResolverInputTypes['ContentType']
  }
  ['ModelInputUpdateScrapeRequestJob']: {
    brandId?: string | undefined | null
    createdAt?: string | undefined | null
    expirationTime?: string | undefined | null
    id: string
    message?: string | undefined | null
    sku?: string | undefined | null
    skuId?: string | undefined | null
    status?: ResolverInputTypes['ScrapeRequestStatus'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateUser']: {
    accessLevel?: ResolverInputTypes['AccessLevel'] | undefined | null
    createdAt?: string | undefined | null
    email?: string | undefined | null
    firstName?: string | undefined | null
    id: string
    invitationId?: string | undefined | null
    lastActiveAt?: string | undefined | null
    lastName?: string | undefined | null
    phoneNumber?: string | undefined | null
    primaryBrandId?: string | undefined | null
    profilePicture?: string | undefined | null
    role?: ResolverInputTypes['Role'] | undefined | null
    status?: ResolverInputTypes['UserStatus'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateUserAgency']: {
    agencyId: string
    createdAt?: string | undefined | null
    updatedAt?: string | undefined | null
    userId: string
  }
  ['ModelInputUpdateUserCampaignSocialContent']: {
    createdAt?: string | undefined | null
    id: string
    isFavorite?: boolean | undefined | null
    isHidden?: boolean | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelInputUpdateUserInvitation']: {
    accessLevel?: ResolverInputTypes['AccessLevel'] | undefined | null
    agencyId?: string | undefined | null
    brandIds?: Array<string | undefined | null> | undefined | null
    companyId?: string | undefined | null
    createdAt?: string | undefined | null
    email?: string | undefined | null
    firstName?: string | undefined | null
    id: string
    isAllBrandAccess?: boolean | undefined | null
    lastName?: string | undefined | null
    phoneNumber?: string | undefined | null
    primaryBrandId?: string | undefined | null
    role?: ResolverInputTypes['Role'] | undefined | null
    status?: ResolverInputTypes['UserInvitationStatus'] | undefined | null
    updatedAt?: string | undefined | null
  }
  ['ModelIntKeyConditionInput']: {
    beginsWith?: number | undefined | null
    between?: Array<number | undefined | null> | undefined | null
    eq?: number | undefined | null
    ge?: number | undefined | null
    gt?: number | undefined | null
    le?: number | undefined | null
    lt?: number | undefined | null
  }
  ['ModelMomentumInvitationSkKeyConditionInput']: {
    beginsWith?: ResolverInputTypes['UserInvitationStatus'] | undefined | null
    between?: Array<ResolverInputTypes['UserInvitationStatus'] | undefined | null> | undefined | null
    eq?: ResolverInputTypes['UserInvitationStatus'] | undefined | null
    ge?: ResolverInputTypes['UserInvitationStatus'] | undefined | null
    gt?: ResolverInputTypes['UserInvitationStatus'] | undefined | null
    le?: ResolverInputTypes['UserInvitationStatus'] | undefined | null
    lt?: ResolverInputTypes['UserInvitationStatus'] | undefined | null
  }
  ['ModelProductRecommendationsSkKeyConditionInput']: {
    beginsWith?: ResolverInputTypes['RecommendationType'] | undefined | null
    between?: Array<ResolverInputTypes['RecommendationType'] | undefined | null> | undefined | null
    eq?: ResolverInputTypes['RecommendationType'] | undefined | null
    ge?: ResolverInputTypes['RecommendationType'] | undefined | null
    gt?: ResolverInputTypes['RecommendationType'] | undefined | null
    le?: ResolverInputTypes['RecommendationType'] | undefined | null
    lt?: ResolverInputTypes['RecommendationType'] | undefined | null
  }
  ['ModelSearchStringInput']: {
    field?: ResolverInputTypes['ModelSearchStringFieldInput'] | undefined | null
    match?: string | undefined | null
    match_phrase_prefix?: string | undefined | null
    not?: boolean | undefined | null
    prefix?: string | undefined | null
    regexp?: string | undefined | null
    wildcard?: string | undefined | null
  }
  ['ModelStringKeyConditionInput']: {
    beginsWith?: string | undefined | null
    between?: Array<string | undefined | null> | undefined | null
    eq?: string | undefined | null
    ge?: string | undefined | null
    gt?: string | undefined | null
    le?: string | undefined | null
    lt?: string | undefined | null
  }
  ['ProductInput']: {
    brandId: string
    estimatedAvailabilityDate?: string | undefined | null
    id?: string | undefined | null
    image?: string | undefined | null
    name: string
    price: string
    store: ResolverInputTypes['Store']
  }
  ['ProductSeoKeywordUploadRecord']: {
    date: string
    keyword: string
    keywordSales?: number | undefined | null
    organicRank?: number | undefined | null
    searchVolume?: number | undefined | null
    sponsoredRank?: number | undefined | null
  }
  ['RequestContentEditsInput']: {
    atTime?: number | undefined | null
    forAssetAtIndex?: number | undefined | null
    message?: string | undefined | null
    shouldSendToCreator: boolean
    userCampaignSocialContentId: string
    visibleToUser?: boolean | undefined | null
  }
  ['RequestExchangeRateInput']: {
    from: ResolverInputTypes['Currency']
    to?: ResolverInputTypes['Currency'] | undefined | null
  }
  ['SubmitCampaignCreatorApprovalInput']: {
    action: ResolverInputTypes['CampaignCreatorApprovalAction']
    feedback?: string | undefined | null
    id: string
  }
  ['ToggleContentVisibilityInput']: {
    isHidden: boolean
    userCampaignSocialContentId: string
  }
  ['UpdateBrandInput']: {
    amazonBrandStoreUrl?: string | undefined | null
    companyId?: string | undefined | null
    enabledBrandApiTypes?: Array<ResolverInputTypes['BrandApiType'] | undefined | null> | undefined | null
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined | null
    isWalmartScrapeEnabled?: boolean | undefined | null
    logo?: string | undefined | null
    name?: string | undefined | null
    nameAudioKey?: string | undefined | null
    region?: ResolverInputTypes['Region'] | undefined | null
    subscriptionEndsAt?: string | undefined | null
    subscriptionStartsAt?: string | undefined | null
    walmartScrapeUrl?: string | undefined | null
  }
  ['UpdateManualProductInput']: {
    id: string
    image?: string | undefined | null
    name?: string | undefined | null
    price?: string | undefined | null
    store?: ResolverInputTypes['Store'] | undefined | null
  }
  ['UploadCreatorContentInput']: {
    assetKeys?: Array<string> | undefined | null
    channel?: ResolverInputTypes['Channel'] | undefined | null
    contentLink?: string | undefined | null
    contentType?: ResolverInputTypes['ContentType'] | undefined | null
    socialContentId?: string | undefined | null
    uploadType: ResolverInputTypes['ContentUploadType']
    userCampaignId: string
  }
  ['UploadProductSeoDataInput']: {
    campaignId: string
    campaignStartDate: string
    records: Array<ResolverInputTypes['ProductSeoKeywordUploadRecord']>
    shouldUpdateSummary?: boolean | undefined | null
  }
  ['UserInvitationInput']: {
    accessLevel?: ResolverInputTypes['AccessLevel'] | undefined | null
    agencyId?: string | undefined | null
    brandIds?: Array<string> | undefined | null
    companyId?: string | undefined | null
    email: string
    firstName?: string | undefined | null
    isAllBrandAccess?: boolean | undefined | null
    lastName?: string | undefined | null
    phoneNumber?: string | undefined | null
    primaryBrandId?: string | undefined | null
    role?: ResolverInputTypes['Role'] | undefined | null
  }
}

export type ModelTypes = {
  ['AdjustCampaignStartDateOutput']: {
    startDate: string
  }
  ['Agency']: {
    clientPricing: Array<ModelTypes['Pricing']>
    clientPricingId?: string | undefined
    createdAt: string
    id: string
    logo: string
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: ModelTypes['PaymentTermsType'] | undefined
    pricing: Array<ModelTypes['Pricing']>
    updatedAt: string
    websiteUrl: string
  }
  ['ApproveContentResonse']: {
    userCampaignSocialContentId: string
  }
  ['BillingContact']: {
    email: string
    name: string
  }
  ['Brand']: {
    amazonBrandName?: string | undefined
    amazonBrandStoreUrl?: string | undefined
    brandApis: Array<ModelTypes['BrandApi']>
    companyId?: string | undefined
    createdAt: string
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    lastScrapedAt?: string | undefined
    logo?: string | undefined
    name: string
    nameAudioKey?: string | undefined
    pricing: Array<ModelTypes['Pricing']>
    products: ModelTypes['ModelItemsProduct']
    proposals: Array<ModelTypes['Proposal']>
    region: ModelTypes['Region']
    subscriptionCredits: Array<ModelTypes['SubscriptionCreditAction']>
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    updatedAt: string
    walmartBrandName?: string | undefined
    walmartLastScrapedAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['BrandApi']: {
    accessToken?: string | undefined
    api: ModelTypes['BrandApiType']
    apiVersion?: ModelTypes['BrandApiVersion'] | undefined
    brandId: string
    createdAt: string
    enabled?: boolean | undefined
    isIntegrated?: boolean | undefined
    refreshToken?: string | undefined
    updatedAt: string
  }
  ['Campaign']: {
    brandId: string
    cost: number
    createdAt: string
    endDate?: string | undefined
    goal?: ModelTypes['ProposalGoal'] | undefined
    id: string
    numCreators: number
    numCreatorsJoined: number
    preLaunchDate?: string | undefined
    product?: ModelTypes['Product'] | undefined
    proposal?: ModelTypes['Proposal'] | undefined
    skuId: string
    startDate: string
    title: string
  }
  ['CampaignBenchmark']: {
    benchmarkGoal: number
    benchmarkType: ModelTypes['BenchmarkType']
    benchmarkedProductIds: Array<string>
    campaignId: string
    createdAt: string
    skuId: string
    updatedAt: string
  }
  ['CampaignClick']: {
    clicks?: number | undefined
    createdAt: string
    date: string
    updatedAt: string
  }
  ['CampaignCreator']: {
    content: Array<ModelTypes['ContentGroup']>
    creator: ModelTypes['CampaignCreatorCreator']
    creatorType: ModelTypes['CreatorType']
    leftProductFeedback?: boolean | undefined
    purchaseDetails?: ModelTypes['PurchaseDetails'] | undefined
    status: ModelTypes['CampaignCreatorStatus']
    userCampaignId: string
  }
  ['CampaignCreatorCreator']: {
    email: string
    firstName: string
    id: string
    instagramProfile?: ModelTypes['SocialProfile'] | undefined
    lastName: string
    sponsoredPosts: Array<string>
    tiktokProfile?: ModelTypes['SocialProfile'] | undefined
    youtubeProfile?: ModelTypes['SocialProfile'] | undefined
  }
  ['CampaignCreatorForApproval']: {
    approvalState: ModelTypes['CreatorApprovalState']
    creator: ModelTypes['CampaignCreatorCreator']
    creatorType: ModelTypes['CreatorType']
    dateOfBirth?: string | undefined
    locale?: ModelTypes['Region'] | undefined
    userCampaignId: string
  }
  ['CampaignDetails']: {
    anticipatedStartDate: string
    brandId: string
    cost: number
    createdAt: string
    creators: Array<ModelTypes['CampaignCreator']>
    endDate?: string | undefined
    expectedClicksCounts?: number | undefined
    expectedPlacementWins?: number | undefined
    expectedPremiumUgcPostCount?: number | undefined
    expectedReviewsCount?: number | undefined
    expectedSocialPostCount?: number | undefined
    expectedUgcPostCount?: number | undefined
    goal?: ModelTypes['ProposalGoal'] | undefined
    id: string
    liftCampaignId?: string | undefined
    numAdditionalBrandAdvocateCreators?: number | undefined
    numAdditionalBrandAdvocateCreatorsJoined?: number | undefined
    numBrandAdvocateCreators?: number | undefined
    numCreators: number
    numCreatorsJoined: number
    numPremiumUgcCreators?: number | undefined
    numSocialCreators?: number | undefined
    numUgcCreators?: number | undefined
    preLaunchDate?: string | undefined
    product: ModelTypes['Product']
    proposal?: ModelTypes['Proposal'] | undefined
    releaseSchedule?: ModelTypes['ReleaseSchedule'] | undefined
    skuId: string
    startDate: string
    title: string
  }
  ['CampaignPerformance']: {
    campaignId: string
    comparisonMetric?: ModelTypes['PerformanceComparisonMetric'] | undefined
    comparisonMetricValue?: number | undefined
    createdAt: string
    isBsrDataUnavailable?: boolean | undefined
    isBsrVisible?: boolean | undefined
    isTtrDataUnavailable?: boolean | undefined
    isTtrVisible?: boolean | undefined
    isVisible?: boolean | undefined
    products: Array<ModelTypes['CampaignPerformanceProduct']>
    promotedProductId: string
    skuId: string
    summaryTitle?: ModelTypes['PerformanceSummaryTitleType'] | undefined
    updatedAt: string
  }
  ['CampaignPerformanceProduct']: {
    bestSellerRankDataUploaded?: boolean | undefined
    campaignId: string
    comparisonMetric?: ModelTypes['PerformanceComparisonMetric'] | undefined
    createdAt: string
    id: string
    isCategoryAverage?: boolean | undefined
    isVisible?: boolean | undefined
    productImage?: string | undefined
    productName: string
    productPerformanceRecords: Array<ModelTypes['ProductPerformanceRecord']>
    productUrl?: string | undefined
    reviewDataUploaded?: boolean | undefined
    skuId: string
    updatedAt: string
  }
  ['CampaignProductReviewRatingMetric']: {
    fromDate: string
    momentumRating: number
    momentumRatingCount: number
    momentumReviewCount: number
    momentumReviewRating: number
    numRatings: number
    numReviews: number
    organicRating: number
    organicRatingCount: number
    rating: number
    ratingsInPeriod: number
    reviews: Array<ModelTypes['Review']>
    reviewsInPeriod: number
    toDate: string
    vineRating: number
    vineReviewCount: number
  }
  ['CategoryRank']: {
    category: string
    rank: number
  }
  ['Company']: {
    agency?: ModelTypes['Agency'] | undefined
    agencyId?: string | undefined
    brands: Array<ModelTypes['Brand']>
    createdAt: string
    id: string
    logo?: string | undefined
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: ModelTypes['PaymentTermsType'] | undefined
    pricing: Array<ModelTypes['Pricing']>
    updatedAt: string
    websiteUrl?: string | undefined
  }
  ['Content']: {
    assets: Array<ModelTypes['ContentAsset']>
    caption?: string | undefined
    comments?: number | undefined
    engagements?: number | undefined
    id: string
    likes?: number | undefined
    qualityScore?: number | undefined
    views?: number | undefined
  }
  ['ContentAsset']: {
    type: string
    url: string
  }
  ['ContentGroup']: {
    channel: ModelTypes['Channel']
    content: Array<ModelTypes['Content']>
    contentType: ModelTypes['ContentType']
    createdAt: string
    groupId: string
    isFavorite: boolean
    isHidden: boolean
    platformContentLink?: string | undefined
    submittedAt: string
  }
  ['ContentRequirement']: {
    bonus: number
    contentType: ModelTypes['ContentType']
  }
  ['CreateCampaignOutput']: {
    id: string
    invoiceLink?: string | undefined
  }
  ['CreateProposalSlideResult']: {
    link: string
  }
  ['CreatedByUser']: {
    firstName: string
    lastName: string
  }
  ['Creator']: {
    email: string
    firstName: string
    id: string
    instagramProfile?: ModelTypes['SocialProfile'] | undefined
    lastName: string
    tiktokProfile?: ModelTypes['SocialProfile'] | undefined
    youtubeProfile?: ModelTypes['SocialProfile'] | undefined
  }
  ['CreatorApprovalState']: {
    feedback?: string | undefined
    reviewedByUserEmail?: string | undefined
    reviewedByUserId?: string | undefined
    reviewedByUserName?: string | undefined
    status: ModelTypes['CreatorApprovalStatus']
    updatedAt: string
  }
  ['CreatorContent']: {
    content: Array<ModelTypes['ContentGroup']>
    creator: ModelTypes['Creator']
    userCampaignId: string
  }
  ['CreatorDraftContent']: {
    creator: ModelTypes['Creator']
    draftContent?: Array<ModelTypes['DraftContent']> | undefined
    id: string
  }
  ['CreatorPricing']: {
    price: number
    type: ModelTypes['PricingCreatorType']
  }
  ['DraftContent']: {
    channel: ModelTypes['Channel']
    contentType: ModelTypes['ContentType']
    draftMedia?: Array<string> | undefined
    draftStatus: ModelTypes['DraftStatus']
    draftSubmittedAt: string
    draftText?: string | undefined
    id: string
    isHidden?: boolean | undefined
    reviewFeedback?: Array<ModelTypes['ReviewFeedback']> | undefined
    reviewedAt?: string | undefined
    reviewerFirstName?: string | undefined
    reviewerLastName?: string | undefined
  }
  ['EndCampaignOutput']: {
    endDate: string
  }
  ['EtailerProductMetrics']: {
    createdAt: string
    date: string
    pageViews?: number | undefined
    skuId: string
    totalSalesAmount?: number | undefined
    unitsSold?: number | undefined
    updatedAt: string
  }
  ['LambdaResponse']: {
    code?: string | undefined
    message?: string | undefined
    success: boolean
  }
  ['ListingBadgeRecord']: {
    badge: ModelTypes['ListingBadge']
    date?: string | undefined
  }
  ['ModelItemsAgency']: {
    items: Array<ModelTypes['Agency']>
    nextToken?: string | undefined
  }
  ['ModelItemsBrand']: {
    items: Array<ModelTypes['Brand']>
    nextToken?: string | undefined
  }
  ['ModelItemsBrandApi']: {
    items: Array<ModelTypes['BrandApi']>
    nextToken?: string | undefined
  }
  ['ModelItemsCampaignClick']: {
    items: Array<ModelTypes['CampaignClick']>
    nextToken?: string | undefined
  }
  ['ModelItemsCompany']: {
    items: Array<ModelTypes['Company']>
    nextToken?: string | undefined
  }
  ['ModelItemsEtailerProductMetrics']: {
    items: Array<ModelTypes['EtailerProductMetrics']>
    nextToken?: string | undefined
  }
  ['ModelItemsPricing']: {
    items: Array<ModelTypes['Pricing']>
    nextToken?: string | undefined
  }
  ['ModelItemsProduct']: {
    items: Array<ModelTypes['Product']>
    nextToken?: string | undefined
  }
  ['ModelItemsProductKeywordRecord']: {
    items: Array<ModelTypes['ProductKeywordRecord']>
    nextToken?: string | undefined
  }
  ['ModelItemsProductKeywordSummary']: {
    items: Array<ModelTypes['ProductKeywordSummary']>
    nextToken?: string | undefined
  }
  ['ModelItemsProductRatingSnapshot']: {
    items: Array<ModelTypes['ProductRatingSnapshot']>
    nextToken?: string | undefined
  }
  ['ModelItemsProductRecommendation']: {
    items: Array<ModelTypes['ProductRecommendation']>
    nextToken?: string | undefined
  }
  ['ModelItemsProposal']: {
    items: Array<ModelTypes['Proposal']>
    nextToken?: string | undefined
  }
  ['ModelItemsScrapeRequestJob']: {
    items: Array<ModelTypes['ScrapeRequestJob']>
    nextToken?: string | undefined
  }
  ['ModelItemsSubscriptionCreditAction']: {
    items: Array<ModelTypes['SubscriptionCreditAction']>
    nextToken?: string | undefined
  }
  ['ModelItemsUser']: {
    items: Array<ModelTypes['User']>
    nextToken?: string | undefined
  }
  ['ModelItemsUserAgency']: {
    items: Array<ModelTypes['UserAgency']>
    nextToken?: string | undefined
  }
  ['ModelItemsUserBrand']: {
    items: Array<ModelTypes['UserBrand']>
    nextToken?: string | undefined
  }
  ['ModelItemsUserCompany']: {
    items: Array<ModelTypes['UserCompany']>
    nextToken?: string | undefined
  }
  ['ModelItemsUserInvitation']: {
    items: Array<ModelTypes['UserInvitation']>
    nextToken?: string | undefined
  }
  ['ModelSearchItemssearchBrandContent']: {
    items: Array<ModelTypes['CreatorContent']>
    total?: number | undefined
  }
  ['ModelSearchItemssearchBrandCounts']: {
    items: Array<ModelTypes['SearchBrandCountsOutput']>
    total?: number | undefined
  }
  ['ModelSearchItemssearchBrandProducts']: {
    items: Array<ModelTypes['Product']>
    total?: number | undefined
  }
  ['ModelSearchItemssearchRecommendations']: {
    items?: Array<ModelTypes['Product'] | undefined> | undefined
    total?: number | undefined
  }
  ['Mutation']: {
    adjustCampaignStartDate: ModelTypes['AdjustCampaignStartDateOutput']
    adjustProductRecommendationVisibility: ModelTypes['Product']
    adjustSubscriptionCredits: ModelTypes['SubscriptionCreditAction']
    approveContent: ModelTypes['ApproveContentResonse']
    createAgency: ModelTypes['Agency']
    createBrand: ModelTypes['Brand']
    createCampaign: ModelTypes['CreateCampaignOutput']
    createCampaignBenchmark: ModelTypes['CampaignBenchmark']
    createCampaignPerformance: ModelTypes['CampaignPerformance']
    createCampaignPerformanceProduct: ModelTypes['CampaignPerformanceProduct']
    createCompany: ModelTypes['Company']
    createManualProduct: ModelTypes['Product']
    createPWProductSeoSummary: ModelTypes['PWProductSeoSummary']
    createPricing: ModelTypes['Pricing']
    createProductFromUrl?: ModelTypes['Product'] | undefined
    createProposal: ModelTypes['Proposal']
    createProposalSlide: ModelTypes['CreateProposalSlideResult']
    createScrapeRequestJob: ModelTypes['ScrapeRequestJob']
    deactivateUser: ModelTypes['User']
    deleteCampaignPerformance: ModelTypes['CampaignPerformance']
    deleteCampaignPerformanceProduct: ModelTypes['CampaignPerformanceProduct']
    deletePricing: ModelTypes['Pricing']
    deleteProposal: ModelTypes['Proposal']
    deleteUserInvitation: ModelTypes['UserInvitation']
    endCampaign: ModelTypes['EndCampaignOutput']
    generateFeedbackReport: ModelTypes['ProductFeedbackSummary']
    inviteUserToMomentum: ModelTypes['UserInvitation']
    pingActivity: boolean
    reactivateUser: ModelTypes['User']
    refreshRecommendations: ModelTypes['RefreshRecommendationsOutput']
    requestContentEdits?: Array<ModelTypes['ReviewFeedback']> | undefined
    requestUploadFileLink: ModelTypes['RequestUploadFileLinkResult']
    resetPassword: ModelTypes['LambdaResponse']
    signupUser: ModelTypes['LambdaResponse']
    submitCampaignCreatorApproval: ModelTypes['CampaignCreatorForApproval']
    toggleContentVisibility: ModelTypes['ToggleContentVisibilityResonse']
    updateAgency: ModelTypes['Agency']
    updateBrand: ModelTypes['Brand']
    updateCampaignBenchmark: ModelTypes['CampaignBenchmark']
    updateCampaignPerformance: ModelTypes['CampaignPerformance']
    updateCampaignPerformanceProduct: ModelTypes['CampaignPerformanceProduct']
    updateCompany: ModelTypes['Company']
    updateManualProduct: ModelTypes['Product']
    updatePWProductSeoSummary: ModelTypes['PWProductSeoSummary']
    updatePricing: ModelTypes['Pricing']
    updateProductKeywordSummary: ModelTypes['ProductKeywordSummary']
    updateProductSeoSummary: ModelTypes['ProductSeoSummary']
    updateProposal: ModelTypes['Proposal']
    updateScrapeRequestJob: ModelTypes['ScrapeRequestJob']
    updateUser: ModelTypes['User']
    updateUserAgency: ModelTypes['UserAgency']
    updateUserCampaignSocialContent: ModelTypes['UserCampaignSocialContent']
    updateUserInvitation: ModelTypes['UserInvitation']
    uploadCreatorContent: ModelTypes['ContentGroup']
    uploadProductSeoData?: ModelTypes['ProductSeoSummary'] | undefined
  }
  ['PWProductSEOSummaryKeyword']: {
    badges?: Array<ModelTypes['ListingBadgeRecord']> | undefined
    highestRank?: number | undefined
    impressions: number
    keyword: string
    pageOneWin?: boolean | undefined
    pageOneWinDate?: string | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredStartRank?: number | undefined
    startRank?: number | undefined
  }
  ['PWProductSEOSummaryRecord']: {
    categoryRanks?: Array<ModelTypes['CategoryRank']> | undefined
    impressions?: number | undefined
    isOutOfStock?: boolean | undefined
    topPageWins?: number | undefined
    weekIndex: number
  }
  ['PWProductSeoSummary']: {
    campaignId: string
    createdAt: string
    keywords: Array<ModelTypes['PWProductSEOSummaryKeyword']>
    productSEOSummaryRecords: Array<ModelTypes['PWProductSEOSummaryRecord']>
    updatedAt: string
  }
  ['Pricing']: {
    createdAt: string
    creatorPricing: Array<ModelTypes['CreatorPricing']>
    id: string
    store: ModelTypes['Store']
    updatedAt: string
  }
  ['Product']: {
    active?: string | undefined
    availabilityDate?: string | undefined
    brandId: string
    categories?: Array<string> | undefined
    childSkus: ModelTypes['ModelItemsProduct']
    createdAt: string
    createdByUserId?: string | undefined
    creationSource?: ModelTypes['ProductCreationSource'] | undefined
    creationSourceUrl?: string | undefined
    estimatedAvailabilityDate?: string | undefined
    id: string
    image?: string | undefined
    initialEtailerProductMetric?: ModelTypes['EtailerProductMetrics'] | undefined
    isRecommendationsHidden?: boolean | undefined
    itemClassification?: ModelTypes['ItemClassification'] | undefined
    lastScrapedAt?: string | undefined
    listingFulfillmentType?: ModelTypes['ListingFulfillmentType'] | undefined
    listingLink?: string | undefined
    name: string
    parentSku?: ModelTypes['Product'] | undefined
    parentSkuId?: string | undefined
    price?: string | undefined
    priceCents?: number | undefined
    productImageKey?: string | undefined
    ratingSnapshots: ModelTypes['ModelItemsProductRatingSnapshot']
    ratingSummary?: ModelTypes['ProductRatingSnapshot'] | undefined
    recommendations: Array<ModelTypes['ProductRecommendation']>
    skuId?: string | undefined
    store: ModelTypes['Store']
    updatedAt: string
  }
  ['ProductFeedbackSummary']: {
    campaignId: string
    createdAt: string
    failureReason?: string | undefined
    jsonDownloadUrl?: string | undefined
    status: ModelTypes['ProductFeedbackStatus']
    updatedAt: string
  }
  ['ProductKeywordRecord']: {
    campaignId: string
    campaignId_keyword: string
    createdAt: string
    date: string
    keyword: string
    keywordSales?: number | undefined
    organicRank?: number | undefined
    searchVolume?: number | undefined
    sponsoredRank?: number | undefined
    updatedAt: string
  }
  ['ProductKeywordSummary']: {
    campaignId: string
    campaignId_keyword: string
    createdAt: string
    hasAmazonChoiceBadge?: boolean | undefined
    keyword: string
    organicHighestRank?: number | undefined
    organicPageOneWin?: boolean | undefined
    organicPageOneWinDate?: string | undefined
    organicStartRank?: number | undefined
    records: Array<ModelTypes['ProductKeywordRecord']>
    searchVolume?: number | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredPageOneWin?: boolean | undefined
    sponsoredPageOneWinDate?: string | undefined
    sponsoredStartRank?: number | undefined
    updatedAt: string
  }
  ['ProductPerformanceRecord']: {
    date: string
    rating?: number | undefined
    reviewCount?: number | undefined
    salesRank?: number | undefined
  }
  ['ProductRatingSnapshot']: {
    numRatings?: number | undefined
    numReviews?: number | undefined
    rating?: number | undefined
    sku: string
    skuId: string
    snapshotAt: string
  }
  ['ProductRecommendation']: {
    createdAt: string
    recommendationType: ModelTypes['RecommendationType']
    skuId: string
    updatedAt: string
  }
  ['ProductSeoSummary']: {
    campaignId: string
    createdAt: string
    productSeoSummaryRecords: Array<ModelTypes['ProductSeoSummaryRecord']>
    updatedAt: string
  }
  ['ProductSeoSummaryRecord']: {
    fromDate: string
    isOutOfStock?: boolean | undefined
    organicPageOneWins?: number | undefined
    searchVolume?: number | undefined
    sponsoredPageOneWins?: number | undefined
    toDate: string
  }
  ['Proposal']: {
    benchmarkProducts?: Array<string> | undefined
    billingContacts?: Array<ModelTypes['BillingContact']> | undefined
    brand: ModelTypes['Brand']
    brandId: string
    createdAt: string
    createdBy?: ModelTypes['User'] | undefined
    createdByUserId?: string | undefined
    creatorPricing: Array<ModelTypes['ProposalCreatorPricing']>
    creditActionId?: string | undefined
    durationWeeks?: number | undefined
    estimatedAverageRating?: number | undefined
    estimatedUnitsSoldPerMonth?: number | undefined
    estimatedUnitsSoldPerWeek?: number | undefined
    exchangeRate?: number | undefined
    goal: ModelTypes['ProposalGoal']
    hiddenEndRating?: boolean | undefined
    hiddenNumReviews?: boolean | undefined
    hiddenPdpTraffic?: boolean | undefined
    hiddenRetailEfficiency?: boolean | undefined
    hiddenSeo?: boolean | undefined
    hiddenSocialContent?: boolean | undefined
    hiddenUgcContent?: boolean | undefined
    hubspotDealId?: string | undefined
    id: string
    invoiceDueDate?: string | undefined
    invoiceId?: string | undefined
    invoiceLink?: string | undefined
    invoiceNumber?: string | undefined
    invoicePONumber?: string | undefined
    invoicePaidDate?: string | undefined
    invoiceStatus?: ModelTypes['InvoiceStatus'] | undefined
    isDailyScheduling?: boolean | undefined
    launchDate: string
    liftCloseoutBonus?: number | undefined
    liftNumCreators?: number | undefined
    operatingCost?: number | undefined
    paymentType?: ModelTypes['InvoicePaymentType'] | undefined
    product?: ModelTypes['Product'] | undefined
    productId: string
    productPrice?: number | undefined
    productPriceOverride?: number | undefined
    productRating?: number | undefined
    productRatingCount?: number | undefined
    productVariationSkus?: Array<string> | undefined
    ratingGoal?: number | undefined
    referralCost?: number | undefined
    searchTerms?: Array<string> | undefined
    status: ModelTypes['ProposalStatus']
    statusChangedAt: string
    submittedAt?: string | undefined
    submittedBy?: ModelTypes['User'] | undefined
    submittedByUserId?: string | undefined
    title?: string | undefined
    totalCredits?: number | undefined
    totalCreditsPaid?: number | undefined
    totalProductCostCredits?: number | undefined
    updatedAt: string
    updatedBy?: ModelTypes['User'] | undefined
    updatedByUserId?: string | undefined
  }
  ['ProposalCreatorPricing']: {
    closeoutBonus?: number | undefined
    contentRequirements?: Array<ModelTypes['ContentRequirement']> | undefined
    hidden?: boolean | undefined
    numCreators: number
    price: number
    schedule: Array<number>
    type: ModelTypes['CreatorType']
  }
  ['PurchaseDetails']: {
    amountCents: number
    purchaseDate: string
  }
  ['Query']: {
    companiesByAgency: ModelTypes['ModelItemsCompany']
    generateCompanyRollupReport: string
    getAgency?: ModelTypes['Agency'] | undefined
    getAmazonAuthorizationUri: string
    getBenchmarkMetrics?: Array<ModelTypes['EtailerProductMetrics']> | undefined
    getBrand?: ModelTypes['Brand'] | undefined
    getBrandApi?: ModelTypes['BrandApi'] | undefined
    getCampaign?: ModelTypes['CampaignDetails'] | undefined
    getCampaignBenchmark?: ModelTypes['CampaignBenchmark'] | undefined
    getCampaignPerformance?: ModelTypes['CampaignPerformance'] | undefined
    getCampaignPerformanceProduct?: ModelTypes['CampaignPerformanceProduct'] | undefined
    getCampaignReviewMetrics: Array<ModelTypes['CampaignProductReviewRatingMetric']>
    getCompany?: ModelTypes['Company'] | undefined
    getPWProductSeoSummary?: ModelTypes['PWProductSeoSummary'] | undefined
    getPricing?: ModelTypes['Pricing'] | undefined
    getProduct?: ModelTypes['Product'] | undefined
    getProductFeedbackSummary?: ModelTypes['ProductFeedbackSummary'] | undefined
    getProductRecommendation?: ModelTypes['ProductRecommendation'] | undefined
    getProductSeoSummary?: ModelTypes['ProductSeoSummary'] | undefined
    getProposal?: ModelTypes['Proposal'] | undefined
    getUser?: ModelTypes['User'] | undefined
    getUserAgency?: ModelTypes['UserAgency'] | undefined
    getUserBrand?: ModelTypes['UserBrand'] | undefined
    getUserCompany?: ModelTypes['UserCompany'] | undefined
    getUserInvitation?: ModelTypes['UserInvitation'] | undefined
    listAgencies: ModelTypes['ModelItemsAgency']
    listBrandApis: ModelTypes['ModelItemsBrandApi']
    listBrandCampaigns: Array<ModelTypes['Campaign']>
    listBrandsByActive: ModelTypes['ModelItemsBrand']
    listBrandsByActiveBySubscriptionEndsAt: ModelTypes['ModelItemsBrand']
    listBrandsByCompanyId: ModelTypes['ModelItemsBrand']
    listCampaignClicks: ModelTypes['ModelItemsCampaignClick']
    listCampaignCreatorsForApproval: Array<ModelTypes['CampaignCreatorForApproval']>
    listCompanies: ModelTypes['ModelItemsCompany']
    listContentPendingApproval: Array<ModelTypes['CreatorDraftContent']>
    listEtailerProductMetrics: ModelTypes['ModelItemsEtailerProductMetrics']
    listInvitationsByAgency: ModelTypes['ModelItemsUserInvitation']
    listInvitationsByCompany: ModelTypes['ModelItemsUserInvitation']
    listInvitationsByStatus: ModelTypes['ModelItemsUserInvitation']
    listPricings: ModelTypes['ModelItemsPricing']
    listProductKeywordRecords: ModelTypes['ModelItemsProductKeywordRecord']
    listProductKeywordSummaries: ModelTypes['ModelItemsProductKeywordSummary']
    listProductRecommendations: ModelTypes['ModelItemsProductRecommendation']
    listProducts: ModelTypes['ModelItemsProduct']
    listProductsByBrandId: ModelTypes['ModelItemsProduct']
    listProposals: ModelTypes['ModelItemsProposal']
    listProposalsByBrandId: ModelTypes['ModelItemsProposal']
    listSubscriptionCreditActions: ModelTypes['ModelItemsSubscriptionCreditAction']
    listUserAgencies: ModelTypes['ModelItemsUserAgency']
    listUserAgenciesByAgencyId: ModelTypes['ModelItemsUserAgency']
    listUserBrands: ModelTypes['ModelItemsUserBrand']
    listUserCompanies: ModelTypes['ModelItemsUserCompany']
    listUserCompaniesByCompanyId: ModelTypes['ModelItemsUserCompany']
    listUsers: ModelTypes['ModelItemsUser']
    requestExchangeRate: ModelTypes['RequestExchangeRateOutput']
    scrapeRequestByBrandIdBySku: ModelTypes['ModelItemsScrapeRequestJob']
    searchBrandContent: ModelTypes['ModelSearchItemssearchBrandContent']
    searchBrandCounts: ModelTypes['ModelSearchItemssearchBrandCounts']
    searchBrandProducts: ModelTypes['ModelSearchItemssearchBrandProducts']
    searchRecommendations: ModelTypes['ModelSearchItemssearchRecommendations']
  }
  ['RefreshRecommendationsOutput']: {
    isSuccess: boolean
  }
  ['ReleaseSchedule']: {
    brandAdvocatesWeeklySchedule: Array<number>
    premiumUgcWeeklySchedule: Array<number>
    socialWeeklySchedule: Array<number>
    ugcWeeklySchedule: Array<number>
  }
  ['RequestExchangeRateOutput']: {
    rate: number
  }
  ['RequestUploadFileLinkResult']: {
    key: string
    link: string
  }
  ['Review']: {
    attributedUserId?: string | undefined
    id: string
    isVine?: boolean | undefined
    name?: string | undefined
    rating?: number | undefined
    reviewedAt: string
    text?: string | undefined
    title?: string | undefined
  }
  ['ReviewFeedback']: {
    atTime?: number | undefined
    createdAt?: string | undefined
    createdByFirstName?: string | undefined
    createdByLastName?: string | undefined
    forAssetAtIndex?: number | undefined
    message?: string | undefined
    visibleToUser?: boolean | undefined
  }
  ['ScrapeRequestJob']: {
    brandId?: string | undefined
    createdAt: string
    expirationTime: string
    id: string
    message?: string | undefined
    sku?: string | undefined
    skuId?: string | undefined
    status: ModelTypes['ScrapeRequestStatus']
    updatedAt: string
  }
  ['SearchBrandCountsOutput']: {
    brandId: string
    totalProducts: number
    totalRecommendations: number
  }
  ['SocialProfile']: {
    engagementRate?: number | undefined
    followers?: number | undefined
    handle: string
    isPrimary?: boolean | undefined
  }
  ['SubscriptionCreditAction']: {
    action: ModelTypes['CreditAction']
    actionCreatedByFirstName?: string | undefined
    actionCreatedByIP?: string | undefined
    actionCreatedById: string
    actionCreatedByLastName?: string | undefined
    actionId: string
    associatedProposalId?: string | undefined
    createdAt: string
    id: string
    label?: string | undefined
    numCredits: number
    proposal?: ModelTypes['Proposal'] | undefined
    updatedAt: string
  }
  ['ToggleContentVisibilityResonse']: {
    isHidden: boolean
    userCampaignSocialContentId: string
  }
  ['User']: {
    accessLevel?: ModelTypes['AccessLevel'] | undefined
    companies: Array<ModelTypes['UserCompany']>
    createdAt: string
    email: string
    firstName: string
    id: string
    invitationId?: string | undefined
    lastActiveAt?: string | undefined
    lastName: string
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    profilePicture?: string | undefined
    role?: ModelTypes['Role'] | undefined
    status: ModelTypes['UserStatus']
    updatedAt: string
  }
  ['UserAgency']: {
    agency: ModelTypes['Agency']
    agencyId: string
    companies: Array<ModelTypes['Company']>
    createdAt: string
    updatedAt: string
    user: ModelTypes['User']
    userId: string
  }
  ['UserBrand']: {
    brand: ModelTypes['Brand']
    brandId: string
    companyId: string
    createdAt: string
    updatedAt: string
    userId: string
  }
  ['UserCampaignSocialContent']: {
    createdAt: string
    id: string
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    updatedAt: string
  }
  ['UserCompany']: {
    company: ModelTypes['Company']
    companyId: string
    createdAt: string
    isAllBrandAccess?: boolean | undefined
    updatedAt: string
    user: ModelTypes['User']
    userBrands: Array<ModelTypes['UserBrand']>
    userId: string
  }
  ['UserInvitation']: {
    accessLevel?: ModelTypes['AccessLevel'] | undefined
    agencyId?: string | undefined
    brandIds?: Array<string | undefined> | undefined
    company?: ModelTypes['Company'] | undefined
    companyId?: string | undefined
    createdAt: string
    email: string
    firstName: string
    id: string
    isAllBrandAccess?: boolean | undefined
    lastName: string
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    role?: ModelTypes['Role'] | undefined
    status: ModelTypes['UserInvitationStatus']
    updatedAt: string
  }
  ['AccessLevel']: AccessLevel
  ['BenchmarkType']: BenchmarkType
  ['BrandApiType']: BrandApiType
  ['BrandApiVersion']: BrandApiVersion
  ['CampaignCreatorApprovalAction']: CampaignCreatorApprovalAction
  ['CampaignCreatorStatus']: CampaignCreatorStatus
  ['Channel']: Channel
  ['ContentType']: ContentType
  ['ContentUploadType']: ContentUploadType
  ['CreatorApprovalStatus']: CreatorApprovalStatus
  ['CreatorType']: CreatorType
  ['CreditAction']: CreditAction
  ['Currency']: Currency
  ['DraftStatus']: DraftStatus
  ['InvoicePaymentType']: InvoicePaymentType
  ['InvoiceStatus']: InvoiceStatus
  ['ItemClassification']: ItemClassification
  ['ListingBadge']: ListingBadge
  ['ListingFulfillmentType']: ListingFulfillmentType
  ['ModelSearchStringFieldInput']: ModelSearchStringFieldInput
  ['ModelSortDirection']: ModelSortDirection
  ['PaymentTermsType']: PaymentTermsType
  ['PerformanceComparisonMetric']: PerformanceComparisonMetric
  ['PerformanceSummaryTitleType']: PerformanceSummaryTitleType
  ['PricingCreatorType']: PricingCreatorType
  ['ProductCreationSource']: ProductCreationSource
  ['ProductFeedbackStatus']: ProductFeedbackStatus
  ['ProposalGoal']: ProposalGoal
  ['ProposalStatus']: ProposalStatus
  ['RecommendationType']: RecommendationType
  ['Region']: Region
  ['Role']: Role
  ['ScrapeRequestStatus']: ScrapeRequestStatus
  ['Store']: Store
  ['UploadRequestType']: UploadRequestType
  ['UserInvitationStatus']: UserInvitationStatus
  ['UserStatus']: UserStatus
  ['AdjustCampaignStartDateInput']: {
    campaignId?: string | undefined
    newCampaignStartDate?: string | undefined
  }
  ['AdjustCreditInput']: {
    action: ModelTypes['CreditAction']
    associatedProposalId?: string | undefined
    id: string
    label?: string | undefined
    numCredits: number
  }
  ['AdjustProductRecommendationVisibilityInput']: {
    id: string
    isVisible: boolean
  }
  ['ApproveContentInput']: {
    userCampaignSocialContentId: string
  }
  ['CreateBrandInput']: {
    amazonBrandStoreUrl?: string | undefined
    brandApis?: Array<ModelTypes['BrandApiType']> | undefined
    companyId: string
    id?: string | undefined
    isBrandStoreScrapeEnabled?: boolean | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    logo?: string | undefined
    name: string
    nameAudioKey?: string | undefined
    region: ModelTypes['Region']
    walmartScrapeUrl?: string | undefined
  }
  ['CreateProductFromUrlInput']: {
    brandId: string
    productUrl: string
  }
  ['CreateProposalSlideInput']: {
    creatorPricing: Array<ModelTypes['CreatorPricingInput']>
    exchangeRate: number
    goal: ModelTypes['ProposalGoal']
    productId: string
    ratingGoal?: number | undefined
  }
  ['CreatorPricingInput']: {
    numCreators: number
    price: number
    type: ModelTypes['PricingCreatorType']
  }
  ['ModelBrandApiSkKeyConditionInput']: {
    beginsWith?: ModelTypes['BrandApiType'] | undefined
    between?: Array<ModelTypes['BrandApiType'] | undefined> | undefined
    eq?: ModelTypes['BrandApiType'] | undefined
    ge?: ModelTypes['BrandApiType'] | undefined
    gt?: ModelTypes['BrandApiType'] | undefined
    le?: ModelTypes['BrandApiType'] | undefined
    lt?: ModelTypes['BrandApiType'] | undefined
  }
  ['ModelCreatorPricingSkKeyConditionInput']: {
    beginsWith?: ModelTypes['Store'] | undefined
    between?: Array<ModelTypes['Store'] | undefined> | undefined
    eq?: ModelTypes['Store'] | undefined
    ge?: ModelTypes['Store'] | undefined
    gt?: ModelTypes['Store'] | undefined
    le?: ModelTypes['Store'] | undefined
    lt?: ModelTypes['Store'] | undefined
  }
  ['ModelInputCreateAgency']: {
    clientPricingId?: string | undefined
    createdAt?: string | undefined
    id: string
    logo: string
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: ModelTypes['PaymentTermsType'] | undefined
    updatedAt?: string | undefined
    websiteUrl: string
  }
  ['ModelInputCreateCampaignBenchmark']: {
    benchmarkGoal: number
    benchmarkType: ModelTypes['BenchmarkType']
    benchmarkedProductIds: Array<string>
    campaignId: string
    createdAt?: string | undefined
    skuId: string
    updatedAt?: string | undefined
  }
  ['ModelInputCreateCampaignPerformance']: {
    campaignId: string
    comparisonMetric?: ModelTypes['PerformanceComparisonMetric'] | undefined
    comparisonMetricValue?: number | undefined
    createdAt?: string | undefined
    isBsrDataUnavailable?: boolean | undefined
    isBsrVisible?: boolean | undefined
    isTtrDataUnavailable?: boolean | undefined
    isTtrVisible?: boolean | undefined
    isVisible?: boolean | undefined
    promotedProductId: string
    skuId: string
    summaryTitle?: ModelTypes['PerformanceSummaryTitleType'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputCreateCampaignPerformanceProduct']: {
    bestSellerRankDataUploaded?: boolean | undefined
    campaignId: string
    comparisonMetric?: ModelTypes['PerformanceComparisonMetric'] | undefined
    createdAt?: string | undefined
    id: string
    isCategoryAverage?: boolean | undefined
    isVisible?: boolean | undefined
    productImage?: string | undefined
    productName: string
    productPerformanceRecords?:
      | Array<ModelTypes['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']>
      | undefined
    productUrl?: string | undefined
    reviewDataUploaded?: boolean | undefined
    skuId: string
    updatedAt?: string | undefined
  }
  ['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']: {
    date: string
    rating?: number | undefined
    reviewCount?: number | undefined
    salesRank?: number | undefined
  }
  ['ModelInputCreateCompany']: {
    agencyId?: string | undefined
    createdAt?: string | undefined
    id: string
    logo?: string | undefined
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: ModelTypes['PaymentTermsType'] | undefined
    updatedAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['ModelInputCreatePWProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined
    keywords?: Array<ModelTypes['ModelInputCreatePWProductSeoSummaryKeywordsInput']> | undefined
    productSEOSummaryRecords?:
      | Array<ModelTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']>
      | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputCreatePWProductSeoSummaryKeywordsInput']: {
    badges?: Array<ModelTypes['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']> | undefined
    highestRank?: number | undefined
    impressions: number
    keyword: string
    pageOneWin?: boolean | undefined
    pageOneWinDate?: string | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredStartRank?: number | undefined
    startRank?: number | undefined
  }
  ['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']: {
    badge: ModelTypes['ListingBadge']
    date?: string | undefined
  }
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']: {
    categoryRanks?:
      | Array<ModelTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']>
      | undefined
    impressions?: number | undefined
    isOutOfStock?: boolean | undefined
    topPageWins?: number | undefined
    weekIndex: number
  }
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: {
    category: string
    rank: number
  }
  ['ModelInputCreatePricing']: {
    createdAt?: string | undefined
    creatorPricing?: Array<ModelTypes['ModelInputCreatePricingCreatorPricingInput']> | undefined
    id: string
    store: ModelTypes['Store']
    updatedAt?: string | undefined
  }
  ['ModelInputCreatePricingCreatorPricingInput']: {
    price: number
    type: ModelTypes['PricingCreatorType']
  }
  ['ModelInputCreateProposal']: {
    benchmarkProducts?: Array<string> | undefined
    billingContacts?: Array<ModelTypes['ModelInputCreateProposalBillingContactsInput']> | undefined
    brandId: string
    createdAt?: string | undefined
    createdByUserId?: string | undefined
    creatorPricing?: Array<ModelTypes['ModelInputCreateProposalCreatorPricingInput']> | undefined
    creditActionId?: string | undefined
    durationWeeks?: number | undefined
    estimatedAverageRating?: number | undefined
    estimatedUnitsSoldPerMonth?: number | undefined
    estimatedUnitsSoldPerWeek?: number | undefined
    exchangeRate?: number | undefined
    goal: ModelTypes['ProposalGoal']
    hiddenEndRating?: boolean | undefined
    hiddenNumReviews?: boolean | undefined
    hiddenPdpTraffic?: boolean | undefined
    hiddenRetailEfficiency?: boolean | undefined
    hiddenSeo?: boolean | undefined
    hiddenSocialContent?: boolean | undefined
    hiddenUgcContent?: boolean | undefined
    hubspotDealId?: string | undefined
    id: string
    invoiceDueDate?: string | undefined
    invoiceId?: string | undefined
    invoiceLink?: string | undefined
    invoiceNumber?: string | undefined
    invoicePONumber?: string | undefined
    invoicePaidDate?: string | undefined
    invoiceStatus?: ModelTypes['InvoiceStatus'] | undefined
    isDailyScheduling?: boolean | undefined
    launchDate: string
    liftCloseoutBonus?: number | undefined
    liftNumCreators?: number | undefined
    operatingCost?: number | undefined
    paymentType?: ModelTypes['InvoicePaymentType'] | undefined
    productId: string
    productPrice?: number | undefined
    productPriceOverride?: number | undefined
    productRating?: number | undefined
    productRatingCount?: number | undefined
    productVariationSkus?: Array<string> | undefined
    ratingGoal?: number | undefined
    referralCost?: number | undefined
    searchTerms?: Array<string> | undefined
    status: ModelTypes['ProposalStatus']
    statusChangedAt: string
    submittedAt?: string | undefined
    submittedByUserId?: string | undefined
    title?: string | undefined
    totalCredits?: number | undefined
    totalCreditsPaid?: number | undefined
    totalProductCostCredits?: number | undefined
    updatedAt?: string | undefined
    updatedByUserId?: string | undefined
  }
  ['ModelInputCreateProposalBillingContactsInput']: {
    email: string
    name: string
  }
  ['ModelInputCreateProposalCreatorPricingInput']: {
    closeoutBonus?: number | undefined
    contentRequirements?:
      | Array<ModelTypes['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']>
      | undefined
    hidden?: boolean | undefined
    numCreators: number
    price: number
    schedule: Array<number>
    type: ModelTypes['CreatorType']
  }
  ['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']: {
    bonus: number
    contentType: ModelTypes['ContentType']
  }
  ['ModelInputCreateScrapeRequestJob']: {
    brandId?: string | undefined
    createdAt?: string | undefined
    expirationTime: string
    id: string
    message?: string | undefined
    sku?: string | undefined
    skuId?: string | undefined
    status: ModelTypes['ScrapeRequestStatus']
    updatedAt?: string | undefined
  }
  ['ModelInputDeleteCampaignPerformance']: {
    campaignId: string
    skuId: string
  }
  ['ModelInputDeleteCampaignPerformanceProduct']: {
    id: string
  }
  ['ModelInputDeletePricing']: {
    id: string
    store: ModelTypes['Store']
  }
  ['ModelInputDeleteProposal']: {
    id: string
  }
  ['ModelInputDeleteUserInvitation']: {
    id: string
  }
  ['ModelInputUpdateAgency']: {
    clientPricingId?: string | undefined
    createdAt?: string | undefined
    id: string
    logo?: string | undefined
    name?: string | undefined
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: ModelTypes['PaymentTermsType'] | undefined
    updatedAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['ModelInputUpdateCampaignBenchmark']: {
    benchmarkGoal?: number | undefined
    benchmarkType?: ModelTypes['BenchmarkType'] | undefined
    benchmarkedProductIds?: Array<string | undefined> | undefined
    campaignId: string
    createdAt?: string | undefined
    skuId: string
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateCampaignPerformance']: {
    campaignId: string
    comparisonMetric?: ModelTypes['PerformanceComparisonMetric'] | undefined
    comparisonMetricValue?: number | undefined
    createdAt?: string | undefined
    isBsrDataUnavailable?: boolean | undefined
    isBsrVisible?: boolean | undefined
    isTtrDataUnavailable?: boolean | undefined
    isTtrVisible?: boolean | undefined
    isVisible?: boolean | undefined
    promotedProductId?: string | undefined
    skuId: string
    summaryTitle?: ModelTypes['PerformanceSummaryTitleType'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateCampaignPerformanceProduct']: {
    bestSellerRankDataUploaded?: boolean | undefined
    campaignId?: string | undefined
    comparisonMetric?: ModelTypes['PerformanceComparisonMetric'] | undefined
    createdAt?: string | undefined
    id: string
    isCategoryAverage?: boolean | undefined
    isVisible?: boolean | undefined
    productImage?: string | undefined
    productName?: string | undefined
    productPerformanceRecords?:
      | Array<ModelTypes['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput'] | undefined>
      | undefined
    productUrl?: string | undefined
    reviewDataUploaded?: boolean | undefined
    skuId?: string | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput']: {
    date?: string | undefined
    rating?: number | undefined
    reviewCount?: number | undefined
    salesRank?: number | undefined
  }
  ['ModelInputUpdateCompany']: {
    agencyId?: string | undefined
    createdAt?: string | undefined
    id: string
    logo?: string | undefined
    name?: string | undefined
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: ModelTypes['PaymentTermsType'] | undefined
    updatedAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['ModelInputUpdatePWProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined
    keywords?: Array<ModelTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInput'] | undefined> | undefined
    productSEOSummaryRecords?:
      | Array<ModelTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput'] | undefined>
      | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInput']: {
    badges?: Array<ModelTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput'] | undefined> | undefined
    highestRank?: number | undefined
    impressions?: number | undefined
    keyword?: string | undefined
    pageOneWin?: boolean | undefined
    pageOneWinDate?: string | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredStartRank?: number | undefined
    startRank?: number | undefined
  }
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput']: {
    badge: ModelTypes['ListingBadge']
    date?: string | undefined
  }
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput']: {
    categoryRanks?:
      | Array<
          ModelTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput'] | undefined
        >
      | undefined
    impressions?: number | undefined
    isOutOfStock?: boolean | undefined
    topPageWins?: number | undefined
    weekIndex?: number | undefined
  }
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: {
    category: string
    rank: number
  }
  ['ModelInputUpdatePricing']: {
    createdAt?: string | undefined
    creatorPricing?: Array<ModelTypes['ModelInputUpdatePricingCreatorPricingInput'] | undefined> | undefined
    id: string
    store: ModelTypes['Store']
    updatedAt?: string | undefined
  }
  ['ModelInputUpdatePricingCreatorPricingInput']: {
    price?: number | undefined
    type?: ModelTypes['PricingCreatorType'] | undefined
  }
  ['ModelInputUpdateProductKeywordSummary']: {
    campaignId: string
    campaignId_keyword?: string | undefined
    createdAt?: string | undefined
    hasAmazonChoiceBadge?: boolean | undefined
    keyword: string
    organicHighestRank?: number | undefined
    organicPageOneWin?: boolean | undefined
    organicPageOneWinDate?: string | undefined
    organicStartRank?: number | undefined
    searchVolume?: number | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredPageOneWin?: boolean | undefined
    sponsoredPageOneWinDate?: string | undefined
    sponsoredStartRank?: number | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined
    productSeoSummaryRecords?:
      | Array<ModelTypes['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput'] | undefined>
      | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput']: {
    fromDate?: string | undefined
    isOutOfStock?: boolean | undefined
    organicPageOneWins?: number | undefined
    searchVolume?: number | undefined
    sponsoredPageOneWins?: number | undefined
    toDate?: string | undefined
  }
  ['ModelInputUpdateProposal']: {
    benchmarkProducts?: Array<string | undefined> | undefined
    billingContacts?: Array<ModelTypes['ModelInputUpdateProposalBillingContactsInput'] | undefined> | undefined
    brandId?: string | undefined
    createdAt?: string | undefined
    createdByUserId?: string | undefined
    creatorPricing?: Array<ModelTypes['ModelInputUpdateProposalCreatorPricingInput'] | undefined> | undefined
    creditActionId?: string | undefined
    durationWeeks?: number | undefined
    estimatedAverageRating?: number | undefined
    estimatedUnitsSoldPerMonth?: number | undefined
    estimatedUnitsSoldPerWeek?: number | undefined
    exchangeRate?: number | undefined
    goal?: ModelTypes['ProposalGoal'] | undefined
    hiddenEndRating?: boolean | undefined
    hiddenNumReviews?: boolean | undefined
    hiddenPdpTraffic?: boolean | undefined
    hiddenRetailEfficiency?: boolean | undefined
    hiddenSeo?: boolean | undefined
    hiddenSocialContent?: boolean | undefined
    hiddenUgcContent?: boolean | undefined
    hubspotDealId?: string | undefined
    id: string
    invoiceDueDate?: string | undefined
    invoiceId?: string | undefined
    invoiceLink?: string | undefined
    invoiceNumber?: string | undefined
    invoicePONumber?: string | undefined
    invoicePaidDate?: string | undefined
    invoiceStatus?: ModelTypes['InvoiceStatus'] | undefined
    isDailyScheduling?: boolean | undefined
    launchDate?: string | undefined
    liftCloseoutBonus?: number | undefined
    liftNumCreators?: number | undefined
    operatingCost?: number | undefined
    paymentType?: ModelTypes['InvoicePaymentType'] | undefined
    productId?: string | undefined
    productPrice?: number | undefined
    productPriceOverride?: number | undefined
    productRating?: number | undefined
    productRatingCount?: number | undefined
    productVariationSkus?: Array<string | undefined> | undefined
    ratingGoal?: number | undefined
    referralCost?: number | undefined
    searchTerms?: Array<string | undefined> | undefined
    status?: ModelTypes['ProposalStatus'] | undefined
    statusChangedAt?: string | undefined
    submittedAt?: string | undefined
    submittedByUserId?: string | undefined
    title?: string | undefined
    totalCredits?: number | undefined
    totalCreditsPaid?: number | undefined
    totalProductCostCredits?: number | undefined
    updatedAt?: string | undefined
    updatedByUserId?: string | undefined
  }
  ['ModelInputUpdateProposalBillingContactsInput']: {
    email?: string | undefined
    name?: string | undefined
  }
  ['ModelInputUpdateProposalCreatorPricingInput']: {
    closeoutBonus?: number | undefined
    contentRequirements?:
      | Array<ModelTypes['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput'] | undefined>
      | undefined
    hidden?: boolean | undefined
    numCreators?: number | undefined
    price?: number | undefined
    schedule?: Array<number | undefined> | undefined
    type?: ModelTypes['CreatorType'] | undefined
  }
  ['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput']: {
    bonus: number
    contentType: ModelTypes['ContentType']
  }
  ['ModelInputUpdateScrapeRequestJob']: {
    brandId?: string | undefined
    createdAt?: string | undefined
    expirationTime?: string | undefined
    id: string
    message?: string | undefined
    sku?: string | undefined
    skuId?: string | undefined
    status?: ModelTypes['ScrapeRequestStatus'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateUser']: {
    accessLevel?: ModelTypes['AccessLevel'] | undefined
    createdAt?: string | undefined
    email?: string | undefined
    firstName?: string | undefined
    id: string
    invitationId?: string | undefined
    lastActiveAt?: string | undefined
    lastName?: string | undefined
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    profilePicture?: string | undefined
    role?: ModelTypes['Role'] | undefined
    status?: ModelTypes['UserStatus'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateUserAgency']: {
    agencyId: string
    createdAt?: string | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['ModelInputUpdateUserCampaignSocialContent']: {
    createdAt?: string | undefined
    id: string
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateUserInvitation']: {
    accessLevel?: ModelTypes['AccessLevel'] | undefined
    agencyId?: string | undefined
    brandIds?: Array<string | undefined> | undefined
    companyId?: string | undefined
    createdAt?: string | undefined
    email?: string | undefined
    firstName?: string | undefined
    id: string
    isAllBrandAccess?: boolean | undefined
    lastName?: string | undefined
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    role?: ModelTypes['Role'] | undefined
    status?: ModelTypes['UserInvitationStatus'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelIntKeyConditionInput']: {
    beginsWith?: number | undefined
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
  }
  ['ModelMomentumInvitationSkKeyConditionInput']: {
    beginsWith?: ModelTypes['UserInvitationStatus'] | undefined
    between?: Array<ModelTypes['UserInvitationStatus'] | undefined> | undefined
    eq?: ModelTypes['UserInvitationStatus'] | undefined
    ge?: ModelTypes['UserInvitationStatus'] | undefined
    gt?: ModelTypes['UserInvitationStatus'] | undefined
    le?: ModelTypes['UserInvitationStatus'] | undefined
    lt?: ModelTypes['UserInvitationStatus'] | undefined
  }
  ['ModelProductRecommendationsSkKeyConditionInput']: {
    beginsWith?: ModelTypes['RecommendationType'] | undefined
    between?: Array<ModelTypes['RecommendationType'] | undefined> | undefined
    eq?: ModelTypes['RecommendationType'] | undefined
    ge?: ModelTypes['RecommendationType'] | undefined
    gt?: ModelTypes['RecommendationType'] | undefined
    le?: ModelTypes['RecommendationType'] | undefined
    lt?: ModelTypes['RecommendationType'] | undefined
  }
  ['ModelSearchStringInput']: {
    field?: ModelTypes['ModelSearchStringFieldInput'] | undefined
    match?: string | undefined
    match_phrase_prefix?: string | undefined
    not?: boolean | undefined
    prefix?: string | undefined
    regexp?: string | undefined
    wildcard?: string | undefined
  }
  ['ModelStringKeyConditionInput']: {
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
  }
  ['ProductInput']: {
    brandId: string
    estimatedAvailabilityDate?: string | undefined
    id?: string | undefined
    image?: string | undefined
    name: string
    price: string
    store: ModelTypes['Store']
  }
  ['ProductSeoKeywordUploadRecord']: {
    date: string
    keyword: string
    keywordSales?: number | undefined
    organicRank?: number | undefined
    searchVolume?: number | undefined
    sponsoredRank?: number | undefined
  }
  ['RequestContentEditsInput']: {
    atTime?: number | undefined
    forAssetAtIndex?: number | undefined
    message?: string | undefined
    shouldSendToCreator: boolean
    userCampaignSocialContentId: string
    visibleToUser?: boolean | undefined
  }
  ['RequestExchangeRateInput']: {
    from: ModelTypes['Currency']
    to?: ModelTypes['Currency'] | undefined
  }
  ['SubmitCampaignCreatorApprovalInput']: {
    action: ModelTypes['CampaignCreatorApprovalAction']
    feedback?: string | undefined
    id: string
  }
  ['ToggleContentVisibilityInput']: {
    isHidden: boolean
    userCampaignSocialContentId: string
  }
  ['UpdateBrandInput']: {
    amazonBrandStoreUrl?: string | undefined
    companyId?: string | undefined
    enabledBrandApiTypes?: Array<ModelTypes['BrandApiType'] | undefined> | undefined
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    logo?: string | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    region?: ModelTypes['Region'] | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['UpdateManualProductInput']: {
    id: string
    image?: string | undefined
    name?: string | undefined
    price?: string | undefined
    store?: ModelTypes['Store'] | undefined
  }
  ['UploadCreatorContentInput']: {
    assetKeys?: Array<string> | undefined
    channel?: ModelTypes['Channel'] | undefined
    contentLink?: string | undefined
    contentType?: ModelTypes['ContentType'] | undefined
    socialContentId?: string | undefined
    uploadType: ModelTypes['ContentUploadType']
    userCampaignId: string
  }
  ['UploadProductSeoDataInput']: {
    campaignId: string
    campaignStartDate: string
    records: Array<ModelTypes['ProductSeoKeywordUploadRecord']>
    shouldUpdateSummary?: boolean | undefined
  }
  ['UserInvitationInput']: {
    accessLevel?: ModelTypes['AccessLevel'] | undefined
    agencyId?: string | undefined
    brandIds?: Array<string> | undefined
    companyId?: string | undefined
    email: string
    firstName?: string | undefined
    isAllBrandAccess?: boolean | undefined
    lastName?: string | undefined
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    role?: ModelTypes['Role'] | undefined
  }
}

export type GraphQLTypes = {
  ['AdjustCampaignStartDateOutput']: {
    __typename: 'AdjustCampaignStartDateOutput'
    startDate: string
  }
  ['Agency']: {
    __typename: 'Agency'
    clientPricing: Array<GraphQLTypes['Pricing']>
    clientPricingId?: string | undefined
    createdAt: string
    id: string
    logo: string
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: GraphQLTypes['PaymentTermsType'] | undefined
    pricing: Array<GraphQLTypes['Pricing']>
    updatedAt: string
    websiteUrl: string
  }
  ['ApproveContentResonse']: {
    __typename: 'ApproveContentResonse'
    userCampaignSocialContentId: string
  }
  ['BillingContact']: {
    __typename: 'BillingContact'
    email: string
    name: string
  }
  ['Brand']: {
    __typename: 'Brand'
    amazonBrandName?: string | undefined
    amazonBrandStoreUrl?: string | undefined
    brandApis: Array<GraphQLTypes['BrandApi']>
    companyId?: string | undefined
    createdAt: string
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    lastScrapedAt?: string | undefined
    logo?: string | undefined
    name: string
    nameAudioKey?: string | undefined
    pricing: Array<GraphQLTypes['Pricing']>
    products: GraphQLTypes['ModelItemsProduct']
    proposals: Array<GraphQLTypes['Proposal']>
    region: GraphQLTypes['Region']
    subscriptionCredits: Array<GraphQLTypes['SubscriptionCreditAction']>
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    updatedAt: string
    walmartBrandName?: string | undefined
    walmartLastScrapedAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['BrandApi']: {
    __typename: 'BrandApi'
    accessToken?: string | undefined
    api: GraphQLTypes['BrandApiType']
    apiVersion?: GraphQLTypes['BrandApiVersion'] | undefined
    brandId: string
    createdAt: string
    enabled?: boolean | undefined
    isIntegrated?: boolean | undefined
    refreshToken?: string | undefined
    updatedAt: string
  }
  ['Campaign']: {
    __typename: 'Campaign'
    brandId: string
    cost: number
    createdAt: string
    endDate?: string | undefined
    goal?: GraphQLTypes['ProposalGoal'] | undefined
    id: string
    numCreators: number
    numCreatorsJoined: number
    preLaunchDate?: string | undefined
    product?: GraphQLTypes['Product'] | undefined
    proposal?: GraphQLTypes['Proposal'] | undefined
    skuId: string
    startDate: string
    title: string
  }
  ['CampaignBenchmark']: {
    __typename: 'CampaignBenchmark'
    benchmarkGoal: number
    benchmarkType: GraphQLTypes['BenchmarkType']
    benchmarkedProductIds: Array<string>
    campaignId: string
    createdAt: string
    skuId: string
    updatedAt: string
  }
  ['CampaignClick']: {
    __typename: 'CampaignClick'
    clicks?: number | undefined
    createdAt: string
    date: string
    updatedAt: string
  }
  ['CampaignCreator']: {
    __typename: 'CampaignCreator'
    content: Array<GraphQLTypes['ContentGroup']>
    creator: GraphQLTypes['CampaignCreatorCreator']
    creatorType: GraphQLTypes['CreatorType']
    leftProductFeedback?: boolean | undefined
    purchaseDetails?: GraphQLTypes['PurchaseDetails'] | undefined
    status: GraphQLTypes['CampaignCreatorStatus']
    userCampaignId: string
  }
  ['CampaignCreatorCreator']: {
    __typename: 'CampaignCreatorCreator'
    email: string
    firstName: string
    id: string
    instagramProfile?: GraphQLTypes['SocialProfile'] | undefined
    lastName: string
    sponsoredPosts: Array<string>
    tiktokProfile?: GraphQLTypes['SocialProfile'] | undefined
    youtubeProfile?: GraphQLTypes['SocialProfile'] | undefined
  }
  ['CampaignCreatorForApproval']: {
    __typename: 'CampaignCreatorForApproval'
    approvalState: GraphQLTypes['CreatorApprovalState']
    creator: GraphQLTypes['CampaignCreatorCreator']
    creatorType: GraphQLTypes['CreatorType']
    dateOfBirth?: string | undefined
    locale?: GraphQLTypes['Region'] | undefined
    userCampaignId: string
  }
  ['CampaignDetails']: {
    __typename: 'CampaignDetails'
    anticipatedStartDate: string
    brandId: string
    cost: number
    createdAt: string
    creators: Array<GraphQLTypes['CampaignCreator']>
    endDate?: string | undefined
    expectedClicksCounts?: number | undefined
    expectedPlacementWins?: number | undefined
    expectedPremiumUgcPostCount?: number | undefined
    expectedReviewsCount?: number | undefined
    expectedSocialPostCount?: number | undefined
    expectedUgcPostCount?: number | undefined
    goal?: GraphQLTypes['ProposalGoal'] | undefined
    id: string
    liftCampaignId?: string | undefined
    numAdditionalBrandAdvocateCreators?: number | undefined
    numAdditionalBrandAdvocateCreatorsJoined?: number | undefined
    numBrandAdvocateCreators?: number | undefined
    numCreators: number
    numCreatorsJoined: number
    numPremiumUgcCreators?: number | undefined
    numSocialCreators?: number | undefined
    numUgcCreators?: number | undefined
    preLaunchDate?: string | undefined
    product: GraphQLTypes['Product']
    proposal?: GraphQLTypes['Proposal'] | undefined
    releaseSchedule?: GraphQLTypes['ReleaseSchedule'] | undefined
    skuId: string
    startDate: string
    title: string
  }
  ['CampaignPerformance']: {
    __typename: 'CampaignPerformance'
    campaignId: string
    comparisonMetric?: GraphQLTypes['PerformanceComparisonMetric'] | undefined
    comparisonMetricValue?: number | undefined
    createdAt: string
    isBsrDataUnavailable?: boolean | undefined
    isBsrVisible?: boolean | undefined
    isTtrDataUnavailable?: boolean | undefined
    isTtrVisible?: boolean | undefined
    isVisible?: boolean | undefined
    products: Array<GraphQLTypes['CampaignPerformanceProduct']>
    promotedProductId: string
    skuId: string
    summaryTitle?: GraphQLTypes['PerformanceSummaryTitleType'] | undefined
    updatedAt: string
  }
  ['CampaignPerformanceProduct']: {
    __typename: 'CampaignPerformanceProduct'
    bestSellerRankDataUploaded?: boolean | undefined
    campaignId: string
    comparisonMetric?: GraphQLTypes['PerformanceComparisonMetric'] | undefined
    createdAt: string
    id: string
    isCategoryAverage?: boolean | undefined
    isVisible?: boolean | undefined
    productImage?: string | undefined
    productName: string
    productPerformanceRecords: Array<GraphQLTypes['ProductPerformanceRecord']>
    productUrl?: string | undefined
    reviewDataUploaded?: boolean | undefined
    skuId: string
    updatedAt: string
  }
  ['CampaignProductReviewRatingMetric']: {
    __typename: 'CampaignProductReviewRatingMetric'
    fromDate: string
    momentumRating: number
    momentumRatingCount: number
    momentumReviewCount: number
    momentumReviewRating: number
    numRatings: number
    numReviews: number
    organicRating: number
    organicRatingCount: number
    rating: number
    ratingsInPeriod: number
    reviews: Array<GraphQLTypes['Review']>
    reviewsInPeriod: number
    toDate: string
    vineRating: number
    vineReviewCount: number
  }
  ['CategoryRank']: {
    __typename: 'CategoryRank'
    category: string
    rank: number
  }
  ['Company']: {
    __typename: 'Company'
    agency?: GraphQLTypes['Agency'] | undefined
    agencyId?: string | undefined
    brands: Array<GraphQLTypes['Brand']>
    createdAt: string
    id: string
    logo?: string | undefined
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: GraphQLTypes['PaymentTermsType'] | undefined
    pricing: Array<GraphQLTypes['Pricing']>
    updatedAt: string
    websiteUrl?: string | undefined
  }
  ['Content']: {
    __typename: 'Content'
    assets: Array<GraphQLTypes['ContentAsset']>
    caption?: string | undefined
    comments?: number | undefined
    engagements?: number | undefined
    id: string
    likes?: number | undefined
    qualityScore?: number | undefined
    views?: number | undefined
  }
  ['ContentAsset']: {
    __typename: 'ContentAsset'
    type: string
    url: string
  }
  ['ContentGroup']: {
    __typename: 'ContentGroup'
    channel: GraphQLTypes['Channel']
    content: Array<GraphQLTypes['Content']>
    contentType: GraphQLTypes['ContentType']
    createdAt: string
    groupId: string
    isFavorite: boolean
    isHidden: boolean
    platformContentLink?: string | undefined
    submittedAt: string
  }
  ['ContentRequirement']: {
    __typename: 'ContentRequirement'
    bonus: number
    contentType: GraphQLTypes['ContentType']
  }
  ['CreateCampaignOutput']: {
    __typename: 'CreateCampaignOutput'
    id: string
    invoiceLink?: string | undefined
  }
  ['CreateProposalSlideResult']: {
    __typename: 'CreateProposalSlideResult'
    link: string
  }
  ['CreatedByUser']: {
    __typename: 'CreatedByUser'
    firstName: string
    lastName: string
  }
  ['Creator']: {
    __typename: 'Creator'
    email: string
    firstName: string
    id: string
    instagramProfile?: GraphQLTypes['SocialProfile'] | undefined
    lastName: string
    tiktokProfile?: GraphQLTypes['SocialProfile'] | undefined
    youtubeProfile?: GraphQLTypes['SocialProfile'] | undefined
  }
  ['CreatorApprovalState']: {
    __typename: 'CreatorApprovalState'
    feedback?: string | undefined
    reviewedByUserEmail?: string | undefined
    reviewedByUserId?: string | undefined
    reviewedByUserName?: string | undefined
    status: GraphQLTypes['CreatorApprovalStatus']
    updatedAt: string
  }
  ['CreatorContent']: {
    __typename: 'CreatorContent'
    content: Array<GraphQLTypes['ContentGroup']>
    creator: GraphQLTypes['Creator']
    userCampaignId: string
  }
  ['CreatorDraftContent']: {
    __typename: 'CreatorDraftContent'
    creator: GraphQLTypes['Creator']
    draftContent?: Array<GraphQLTypes['DraftContent']> | undefined
    id: string
  }
  ['CreatorPricing']: {
    __typename: 'CreatorPricing'
    price: number
    type: GraphQLTypes['PricingCreatorType']
  }
  ['DraftContent']: {
    __typename: 'DraftContent'
    channel: GraphQLTypes['Channel']
    contentType: GraphQLTypes['ContentType']
    draftMedia?: Array<string> | undefined
    draftStatus: GraphQLTypes['DraftStatus']
    draftSubmittedAt: string
    draftText?: string | undefined
    id: string
    isHidden?: boolean | undefined
    reviewFeedback?: Array<GraphQLTypes['ReviewFeedback']> | undefined
    reviewedAt?: string | undefined
    reviewerFirstName?: string | undefined
    reviewerLastName?: string | undefined
  }
  ['EndCampaignOutput']: {
    __typename: 'EndCampaignOutput'
    endDate: string
  }
  ['EtailerProductMetrics']: {
    __typename: 'EtailerProductMetrics'
    createdAt: string
    date: string
    pageViews?: number | undefined
    skuId: string
    totalSalesAmount?: number | undefined
    unitsSold?: number | undefined
    updatedAt: string
  }
  ['LambdaResponse']: {
    __typename: 'LambdaResponse'
    code?: string | undefined
    message?: string | undefined
    success: boolean
  }
  ['ListingBadgeRecord']: {
    __typename: 'ListingBadgeRecord'
    badge: GraphQLTypes['ListingBadge']
    date?: string | undefined
  }
  ['ModelItemsAgency']: {
    __typename: 'ModelItemsAgency'
    items: Array<GraphQLTypes['Agency']>
    nextToken?: string | undefined
  }
  ['ModelItemsBrand']: {
    __typename: 'ModelItemsBrand'
    items: Array<GraphQLTypes['Brand']>
    nextToken?: string | undefined
  }
  ['ModelItemsBrandApi']: {
    __typename: 'ModelItemsBrandApi'
    items: Array<GraphQLTypes['BrandApi']>
    nextToken?: string | undefined
  }
  ['ModelItemsCampaignClick']: {
    __typename: 'ModelItemsCampaignClick'
    items: Array<GraphQLTypes['CampaignClick']>
    nextToken?: string | undefined
  }
  ['ModelItemsCompany']: {
    __typename: 'ModelItemsCompany'
    items: Array<GraphQLTypes['Company']>
    nextToken?: string | undefined
  }
  ['ModelItemsEtailerProductMetrics']: {
    __typename: 'ModelItemsEtailerProductMetrics'
    items: Array<GraphQLTypes['EtailerProductMetrics']>
    nextToken?: string | undefined
  }
  ['ModelItemsPricing']: {
    __typename: 'ModelItemsPricing'
    items: Array<GraphQLTypes['Pricing']>
    nextToken?: string | undefined
  }
  ['ModelItemsProduct']: {
    __typename: 'ModelItemsProduct'
    items: Array<GraphQLTypes['Product']>
    nextToken?: string | undefined
  }
  ['ModelItemsProductKeywordRecord']: {
    __typename: 'ModelItemsProductKeywordRecord'
    items: Array<GraphQLTypes['ProductKeywordRecord']>
    nextToken?: string | undefined
  }
  ['ModelItemsProductKeywordSummary']: {
    __typename: 'ModelItemsProductKeywordSummary'
    items: Array<GraphQLTypes['ProductKeywordSummary']>
    nextToken?: string | undefined
  }
  ['ModelItemsProductRatingSnapshot']: {
    __typename: 'ModelItemsProductRatingSnapshot'
    items: Array<GraphQLTypes['ProductRatingSnapshot']>
    nextToken?: string | undefined
  }
  ['ModelItemsProductRecommendation']: {
    __typename: 'ModelItemsProductRecommendation'
    items: Array<GraphQLTypes['ProductRecommendation']>
    nextToken?: string | undefined
  }
  ['ModelItemsProposal']: {
    __typename: 'ModelItemsProposal'
    items: Array<GraphQLTypes['Proposal']>
    nextToken?: string | undefined
  }
  ['ModelItemsScrapeRequestJob']: {
    __typename: 'ModelItemsScrapeRequestJob'
    items: Array<GraphQLTypes['ScrapeRequestJob']>
    nextToken?: string | undefined
  }
  ['ModelItemsSubscriptionCreditAction']: {
    __typename: 'ModelItemsSubscriptionCreditAction'
    items: Array<GraphQLTypes['SubscriptionCreditAction']>
    nextToken?: string | undefined
  }
  ['ModelItemsUser']: {
    __typename: 'ModelItemsUser'
    items: Array<GraphQLTypes['User']>
    nextToken?: string | undefined
  }
  ['ModelItemsUserAgency']: {
    __typename: 'ModelItemsUserAgency'
    items: Array<GraphQLTypes['UserAgency']>
    nextToken?: string | undefined
  }
  ['ModelItemsUserBrand']: {
    __typename: 'ModelItemsUserBrand'
    items: Array<GraphQLTypes['UserBrand']>
    nextToken?: string | undefined
  }
  ['ModelItemsUserCompany']: {
    __typename: 'ModelItemsUserCompany'
    items: Array<GraphQLTypes['UserCompany']>
    nextToken?: string | undefined
  }
  ['ModelItemsUserInvitation']: {
    __typename: 'ModelItemsUserInvitation'
    items: Array<GraphQLTypes['UserInvitation']>
    nextToken?: string | undefined
  }
  ['ModelSearchItemssearchBrandContent']: {
    __typename: 'ModelSearchItemssearchBrandContent'
    items: Array<GraphQLTypes['CreatorContent']>
    total?: number | undefined
  }
  ['ModelSearchItemssearchBrandCounts']: {
    __typename: 'ModelSearchItemssearchBrandCounts'
    items: Array<GraphQLTypes['SearchBrandCountsOutput']>
    total?: number | undefined
  }
  ['ModelSearchItemssearchBrandProducts']: {
    __typename: 'ModelSearchItemssearchBrandProducts'
    items: Array<GraphQLTypes['Product']>
    total?: number | undefined
  }
  ['ModelSearchItemssearchRecommendations']: {
    __typename: 'ModelSearchItemssearchRecommendations'
    items?: Array<GraphQLTypes['Product'] | undefined> | undefined
    total?: number | undefined
  }
  ['Mutation']: {
    __typename: 'Mutation'
    adjustCampaignStartDate: GraphQLTypes['AdjustCampaignStartDateOutput']
    adjustProductRecommendationVisibility: GraphQLTypes['Product']
    adjustSubscriptionCredits: GraphQLTypes['SubscriptionCreditAction']
    approveContent: GraphQLTypes['ApproveContentResonse']
    createAgency: GraphQLTypes['Agency']
    createBrand: GraphQLTypes['Brand']
    createCampaign: GraphQLTypes['CreateCampaignOutput']
    createCampaignBenchmark: GraphQLTypes['CampaignBenchmark']
    createCampaignPerformance: GraphQLTypes['CampaignPerformance']
    createCampaignPerformanceProduct: GraphQLTypes['CampaignPerformanceProduct']
    createCompany: GraphQLTypes['Company']
    createManualProduct: GraphQLTypes['Product']
    createPWProductSeoSummary: GraphQLTypes['PWProductSeoSummary']
    createPricing: GraphQLTypes['Pricing']
    createProductFromUrl?: GraphQLTypes['Product'] | undefined
    createProposal: GraphQLTypes['Proposal']
    createProposalSlide: GraphQLTypes['CreateProposalSlideResult']
    createScrapeRequestJob: GraphQLTypes['ScrapeRequestJob']
    deactivateUser: GraphQLTypes['User']
    deleteCampaignPerformance: GraphQLTypes['CampaignPerformance']
    deleteCampaignPerformanceProduct: GraphQLTypes['CampaignPerformanceProduct']
    deletePricing: GraphQLTypes['Pricing']
    deleteProposal: GraphQLTypes['Proposal']
    deleteUserInvitation: GraphQLTypes['UserInvitation']
    endCampaign: GraphQLTypes['EndCampaignOutput']
    generateFeedbackReport: GraphQLTypes['ProductFeedbackSummary']
    inviteUserToMomentum: GraphQLTypes['UserInvitation']
    pingActivity: boolean
    reactivateUser: GraphQLTypes['User']
    refreshRecommendations: GraphQLTypes['RefreshRecommendationsOutput']
    requestContentEdits?: Array<GraphQLTypes['ReviewFeedback']> | undefined
    requestUploadFileLink: GraphQLTypes['RequestUploadFileLinkResult']
    resetPassword: GraphQLTypes['LambdaResponse']
    signupUser: GraphQLTypes['LambdaResponse']
    submitCampaignCreatorApproval: GraphQLTypes['CampaignCreatorForApproval']
    toggleContentVisibility: GraphQLTypes['ToggleContentVisibilityResonse']
    updateAgency: GraphQLTypes['Agency']
    updateBrand: GraphQLTypes['Brand']
    updateCampaignBenchmark: GraphQLTypes['CampaignBenchmark']
    updateCampaignPerformance: GraphQLTypes['CampaignPerformance']
    updateCampaignPerformanceProduct: GraphQLTypes['CampaignPerformanceProduct']
    updateCompany: GraphQLTypes['Company']
    updateManualProduct: GraphQLTypes['Product']
    updatePWProductSeoSummary: GraphQLTypes['PWProductSeoSummary']
    updatePricing: GraphQLTypes['Pricing']
    updateProductKeywordSummary: GraphQLTypes['ProductKeywordSummary']
    updateProductSeoSummary: GraphQLTypes['ProductSeoSummary']
    updateProposal: GraphQLTypes['Proposal']
    updateScrapeRequestJob: GraphQLTypes['ScrapeRequestJob']
    updateUser: GraphQLTypes['User']
    updateUserAgency: GraphQLTypes['UserAgency']
    updateUserCampaignSocialContent: GraphQLTypes['UserCampaignSocialContent']
    updateUserInvitation: GraphQLTypes['UserInvitation']
    uploadCreatorContent: GraphQLTypes['ContentGroup']
    uploadProductSeoData?: GraphQLTypes['ProductSeoSummary'] | undefined
  }
  ['PWProductSEOSummaryKeyword']: {
    __typename: 'PWProductSEOSummaryKeyword'
    badges?: Array<GraphQLTypes['ListingBadgeRecord']> | undefined
    highestRank?: number | undefined
    impressions: number
    keyword: string
    pageOneWin?: boolean | undefined
    pageOneWinDate?: string | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredStartRank?: number | undefined
    startRank?: number | undefined
  }
  ['PWProductSEOSummaryRecord']: {
    __typename: 'PWProductSEOSummaryRecord'
    categoryRanks?: Array<GraphQLTypes['CategoryRank']> | undefined
    impressions?: number | undefined
    isOutOfStock?: boolean | undefined
    topPageWins?: number | undefined
    weekIndex: number
  }
  ['PWProductSeoSummary']: {
    __typename: 'PWProductSeoSummary'
    campaignId: string
    createdAt: string
    keywords: Array<GraphQLTypes['PWProductSEOSummaryKeyword']>
    productSEOSummaryRecords: Array<GraphQLTypes['PWProductSEOSummaryRecord']>
    updatedAt: string
  }
  ['Pricing']: {
    __typename: 'Pricing'
    createdAt: string
    creatorPricing: Array<GraphQLTypes['CreatorPricing']>
    id: string
    store: GraphQLTypes['Store']
    updatedAt: string
  }
  ['Product']: {
    __typename: 'Product'
    active?: string | undefined
    availabilityDate?: string | undefined
    brandId: string
    categories?: Array<string> | undefined
    childSkus: GraphQLTypes['ModelItemsProduct']
    createdAt: string
    createdByUserId?: string | undefined
    creationSource?: GraphQLTypes['ProductCreationSource'] | undefined
    creationSourceUrl?: string | undefined
    estimatedAvailabilityDate?: string | undefined
    id: string
    image?: string | undefined
    initialEtailerProductMetric?: GraphQLTypes['EtailerProductMetrics'] | undefined
    isRecommendationsHidden?: boolean | undefined
    itemClassification?: GraphQLTypes['ItemClassification'] | undefined
    lastScrapedAt?: string | undefined
    listingFulfillmentType?: GraphQLTypes['ListingFulfillmentType'] | undefined
    listingLink?: string | undefined
    name: string
    parentSku?: GraphQLTypes['Product'] | undefined
    parentSkuId?: string | undefined
    price?: string | undefined
    priceCents?: number | undefined
    productImageKey?: string | undefined
    ratingSnapshots: GraphQLTypes['ModelItemsProductRatingSnapshot']
    ratingSummary?: GraphQLTypes['ProductRatingSnapshot'] | undefined
    recommendations: Array<GraphQLTypes['ProductRecommendation']>
    skuId?: string | undefined
    store: GraphQLTypes['Store']
    updatedAt: string
  }
  ['ProductFeedbackSummary']: {
    __typename: 'ProductFeedbackSummary'
    campaignId: string
    createdAt: string
    failureReason?: string | undefined
    jsonDownloadUrl?: string | undefined
    status: GraphQLTypes['ProductFeedbackStatus']
    updatedAt: string
  }
  ['ProductKeywordRecord']: {
    __typename: 'ProductKeywordRecord'
    campaignId: string
    campaignId_keyword: string
    createdAt: string
    date: string
    keyword: string
    keywordSales?: number | undefined
    organicRank?: number | undefined
    searchVolume?: number | undefined
    sponsoredRank?: number | undefined
    updatedAt: string
  }
  ['ProductKeywordSummary']: {
    __typename: 'ProductKeywordSummary'
    campaignId: string
    campaignId_keyword: string
    createdAt: string
    hasAmazonChoiceBadge?: boolean | undefined
    keyword: string
    organicHighestRank?: number | undefined
    organicPageOneWin?: boolean | undefined
    organicPageOneWinDate?: string | undefined
    organicStartRank?: number | undefined
    records: Array<GraphQLTypes['ProductKeywordRecord']>
    searchVolume?: number | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredPageOneWin?: boolean | undefined
    sponsoredPageOneWinDate?: string | undefined
    sponsoredStartRank?: number | undefined
    updatedAt: string
  }
  ['ProductPerformanceRecord']: {
    __typename: 'ProductPerformanceRecord'
    date: string
    rating?: number | undefined
    reviewCount?: number | undefined
    salesRank?: number | undefined
  }
  ['ProductRatingSnapshot']: {
    __typename: 'ProductRatingSnapshot'
    numRatings?: number | undefined
    numReviews?: number | undefined
    rating?: number | undefined
    sku: string
    skuId: string
    snapshotAt: string
  }
  ['ProductRecommendation']: {
    __typename: 'ProductRecommendation'
    createdAt: string
    recommendationType: GraphQLTypes['RecommendationType']
    skuId: string
    updatedAt: string
  }
  ['ProductSeoSummary']: {
    __typename: 'ProductSeoSummary'
    campaignId: string
    createdAt: string
    productSeoSummaryRecords: Array<GraphQLTypes['ProductSeoSummaryRecord']>
    updatedAt: string
  }
  ['ProductSeoSummaryRecord']: {
    __typename: 'ProductSeoSummaryRecord'
    fromDate: string
    isOutOfStock?: boolean | undefined
    organicPageOneWins?: number | undefined
    searchVolume?: number | undefined
    sponsoredPageOneWins?: number | undefined
    toDate: string
  }
  ['Proposal']: {
    __typename: 'Proposal'
    benchmarkProducts?: Array<string> | undefined
    billingContacts?: Array<GraphQLTypes['BillingContact']> | undefined
    brand: GraphQLTypes['Brand']
    brandId: string
    createdAt: string
    createdBy?: GraphQLTypes['User'] | undefined
    createdByUserId?: string | undefined
    creatorPricing: Array<GraphQLTypes['ProposalCreatorPricing']>
    creditActionId?: string | undefined
    durationWeeks?: number | undefined
    estimatedAverageRating?: number | undefined
    estimatedUnitsSoldPerMonth?: number | undefined
    estimatedUnitsSoldPerWeek?: number | undefined
    exchangeRate?: number | undefined
    goal: GraphQLTypes['ProposalGoal']
    hiddenEndRating?: boolean | undefined
    hiddenNumReviews?: boolean | undefined
    hiddenPdpTraffic?: boolean | undefined
    hiddenRetailEfficiency?: boolean | undefined
    hiddenSeo?: boolean | undefined
    hiddenSocialContent?: boolean | undefined
    hiddenUgcContent?: boolean | undefined
    hubspotDealId?: string | undefined
    id: string
    invoiceDueDate?: string | undefined
    invoiceId?: string | undefined
    invoiceLink?: string | undefined
    invoiceNumber?: string | undefined
    invoicePONumber?: string | undefined
    invoicePaidDate?: string | undefined
    invoiceStatus?: GraphQLTypes['InvoiceStatus'] | undefined
    isDailyScheduling?: boolean | undefined
    launchDate: string
    liftCloseoutBonus?: number | undefined
    liftNumCreators?: number | undefined
    operatingCost?: number | undefined
    paymentType?: GraphQLTypes['InvoicePaymentType'] | undefined
    product?: GraphQLTypes['Product'] | undefined
    productId: string
    productPrice?: number | undefined
    productPriceOverride?: number | undefined
    productRating?: number | undefined
    productRatingCount?: number | undefined
    productVariationSkus?: Array<string> | undefined
    ratingGoal?: number | undefined
    referralCost?: number | undefined
    searchTerms?: Array<string> | undefined
    status: GraphQLTypes['ProposalStatus']
    statusChangedAt: string
    submittedAt?: string | undefined
    submittedBy?: GraphQLTypes['User'] | undefined
    submittedByUserId?: string | undefined
    title?: string | undefined
    totalCredits?: number | undefined
    totalCreditsPaid?: number | undefined
    totalProductCostCredits?: number | undefined
    updatedAt: string
    updatedBy?: GraphQLTypes['User'] | undefined
    updatedByUserId?: string | undefined
  }
  ['ProposalCreatorPricing']: {
    __typename: 'ProposalCreatorPricing'
    closeoutBonus?: number | undefined
    contentRequirements?: Array<GraphQLTypes['ContentRequirement']> | undefined
    hidden?: boolean | undefined
    numCreators: number
    price: number
    schedule: Array<number>
    type: GraphQLTypes['CreatorType']
  }
  ['PurchaseDetails']: {
    __typename: 'PurchaseDetails'
    amountCents: number
    purchaseDate: string
  }
  ['Query']: {
    __typename: 'Query'
    companiesByAgency: GraphQLTypes['ModelItemsCompany']
    generateCompanyRollupReport: string
    getAgency?: GraphQLTypes['Agency'] | undefined
    getAmazonAuthorizationUri: string
    getBenchmarkMetrics?: Array<GraphQLTypes['EtailerProductMetrics']> | undefined
    getBrand?: GraphQLTypes['Brand'] | undefined
    getBrandApi?: GraphQLTypes['BrandApi'] | undefined
    getCampaign?: GraphQLTypes['CampaignDetails'] | undefined
    getCampaignBenchmark?: GraphQLTypes['CampaignBenchmark'] | undefined
    getCampaignPerformance?: GraphQLTypes['CampaignPerformance'] | undefined
    getCampaignPerformanceProduct?: GraphQLTypes['CampaignPerformanceProduct'] | undefined
    getCampaignReviewMetrics: Array<GraphQLTypes['CampaignProductReviewRatingMetric']>
    getCompany?: GraphQLTypes['Company'] | undefined
    getPWProductSeoSummary?: GraphQLTypes['PWProductSeoSummary'] | undefined
    getPricing?: GraphQLTypes['Pricing'] | undefined
    getProduct?: GraphQLTypes['Product'] | undefined
    getProductFeedbackSummary?: GraphQLTypes['ProductFeedbackSummary'] | undefined
    getProductRecommendation?: GraphQLTypes['ProductRecommendation'] | undefined
    getProductSeoSummary?: GraphQLTypes['ProductSeoSummary'] | undefined
    getProposal?: GraphQLTypes['Proposal'] | undefined
    getUser?: GraphQLTypes['User'] | undefined
    getUserAgency?: GraphQLTypes['UserAgency'] | undefined
    getUserBrand?: GraphQLTypes['UserBrand'] | undefined
    getUserCompany?: GraphQLTypes['UserCompany'] | undefined
    getUserInvitation?: GraphQLTypes['UserInvitation'] | undefined
    listAgencies: GraphQLTypes['ModelItemsAgency']
    listBrandApis: GraphQLTypes['ModelItemsBrandApi']
    listBrandCampaigns: Array<GraphQLTypes['Campaign']>
    listBrandsByActive: GraphQLTypes['ModelItemsBrand']
    listBrandsByActiveBySubscriptionEndsAt: GraphQLTypes['ModelItemsBrand']
    listBrandsByCompanyId: GraphQLTypes['ModelItemsBrand']
    listCampaignClicks: GraphQLTypes['ModelItemsCampaignClick']
    listCampaignCreatorsForApproval: Array<GraphQLTypes['CampaignCreatorForApproval']>
    listCompanies: GraphQLTypes['ModelItemsCompany']
    listContentPendingApproval: Array<GraphQLTypes['CreatorDraftContent']>
    listEtailerProductMetrics: GraphQLTypes['ModelItemsEtailerProductMetrics']
    listInvitationsByAgency: GraphQLTypes['ModelItemsUserInvitation']
    listInvitationsByCompany: GraphQLTypes['ModelItemsUserInvitation']
    listInvitationsByStatus: GraphQLTypes['ModelItemsUserInvitation']
    listPricings: GraphQLTypes['ModelItemsPricing']
    listProductKeywordRecords: GraphQLTypes['ModelItemsProductKeywordRecord']
    listProductKeywordSummaries: GraphQLTypes['ModelItemsProductKeywordSummary']
    listProductRecommendations: GraphQLTypes['ModelItemsProductRecommendation']
    listProducts: GraphQLTypes['ModelItemsProduct']
    listProductsByBrandId: GraphQLTypes['ModelItemsProduct']
    listProposals: GraphQLTypes['ModelItemsProposal']
    listProposalsByBrandId: GraphQLTypes['ModelItemsProposal']
    listSubscriptionCreditActions: GraphQLTypes['ModelItemsSubscriptionCreditAction']
    listUserAgencies: GraphQLTypes['ModelItemsUserAgency']
    listUserAgenciesByAgencyId: GraphQLTypes['ModelItemsUserAgency']
    listUserBrands: GraphQLTypes['ModelItemsUserBrand']
    listUserCompanies: GraphQLTypes['ModelItemsUserCompany']
    listUserCompaniesByCompanyId: GraphQLTypes['ModelItemsUserCompany']
    listUsers: GraphQLTypes['ModelItemsUser']
    requestExchangeRate: GraphQLTypes['RequestExchangeRateOutput']
    scrapeRequestByBrandIdBySku: GraphQLTypes['ModelItemsScrapeRequestJob']
    searchBrandContent: GraphQLTypes['ModelSearchItemssearchBrandContent']
    searchBrandCounts: GraphQLTypes['ModelSearchItemssearchBrandCounts']
    searchBrandProducts: GraphQLTypes['ModelSearchItemssearchBrandProducts']
    searchRecommendations: GraphQLTypes['ModelSearchItemssearchRecommendations']
  }
  ['RefreshRecommendationsOutput']: {
    __typename: 'RefreshRecommendationsOutput'
    isSuccess: boolean
  }
  ['ReleaseSchedule']: {
    __typename: 'ReleaseSchedule'
    brandAdvocatesWeeklySchedule: Array<number>
    premiumUgcWeeklySchedule: Array<number>
    socialWeeklySchedule: Array<number>
    ugcWeeklySchedule: Array<number>
  }
  ['RequestExchangeRateOutput']: {
    __typename: 'RequestExchangeRateOutput'
    rate: number
  }
  ['RequestUploadFileLinkResult']: {
    __typename: 'RequestUploadFileLinkResult'
    key: string
    link: string
  }
  ['Review']: {
    __typename: 'Review'
    attributedUserId?: string | undefined
    id: string
    isVine?: boolean | undefined
    name?: string | undefined
    rating?: number | undefined
    reviewedAt: string
    text?: string | undefined
    title?: string | undefined
  }
  ['ReviewFeedback']: {
    __typename: 'ReviewFeedback'
    atTime?: number | undefined
    createdAt?: string | undefined
    createdByFirstName?: string | undefined
    createdByLastName?: string | undefined
    forAssetAtIndex?: number | undefined
    message?: string | undefined
    visibleToUser?: boolean | undefined
  }
  ['ScrapeRequestJob']: {
    __typename: 'ScrapeRequestJob'
    brandId?: string | undefined
    createdAt: string
    expirationTime: string
    id: string
    message?: string | undefined
    sku?: string | undefined
    skuId?: string | undefined
    status: GraphQLTypes['ScrapeRequestStatus']
    updatedAt: string
  }
  ['SearchBrandCountsOutput']: {
    __typename: 'SearchBrandCountsOutput'
    brandId: string
    totalProducts: number
    totalRecommendations: number
  }
  ['SocialProfile']: {
    __typename: 'SocialProfile'
    engagementRate?: number | undefined
    followers?: number | undefined
    handle: string
    isPrimary?: boolean | undefined
  }
  ['SubscriptionCreditAction']: {
    __typename: 'SubscriptionCreditAction'
    action: GraphQLTypes['CreditAction']
    actionCreatedByFirstName?: string | undefined
    actionCreatedByIP?: string | undefined
    actionCreatedById: string
    actionCreatedByLastName?: string | undefined
    actionId: string
    associatedProposalId?: string | undefined
    createdAt: string
    id: string
    label?: string | undefined
    numCredits: number
    proposal?: GraphQLTypes['Proposal'] | undefined
    updatedAt: string
  }
  ['ToggleContentVisibilityResonse']: {
    __typename: 'ToggleContentVisibilityResonse'
    isHidden: boolean
    userCampaignSocialContentId: string
  }
  ['User']: {
    __typename: 'User'
    accessLevel?: GraphQLTypes['AccessLevel'] | undefined
    companies: Array<GraphQLTypes['UserCompany']>
    createdAt: string
    email: string
    firstName: string
    id: string
    invitationId?: string | undefined
    lastActiveAt?: string | undefined
    lastName: string
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    profilePicture?: string | undefined
    role?: GraphQLTypes['Role'] | undefined
    status: GraphQLTypes['UserStatus']
    updatedAt: string
  }
  ['UserAgency']: {
    __typename: 'UserAgency'
    agency: GraphQLTypes['Agency']
    agencyId: string
    companies: Array<GraphQLTypes['Company']>
    createdAt: string
    updatedAt: string
    user: GraphQLTypes['User']
    userId: string
  }
  ['UserBrand']: {
    __typename: 'UserBrand'
    brand: GraphQLTypes['Brand']
    brandId: string
    companyId: string
    createdAt: string
    updatedAt: string
    userId: string
  }
  ['UserCampaignSocialContent']: {
    __typename: 'UserCampaignSocialContent'
    createdAt: string
    id: string
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    updatedAt: string
  }
  ['UserCompany']: {
    __typename: 'UserCompany'
    company: GraphQLTypes['Company']
    companyId: string
    createdAt: string
    isAllBrandAccess?: boolean | undefined
    updatedAt: string
    user: GraphQLTypes['User']
    userBrands: Array<GraphQLTypes['UserBrand']>
    userId: string
  }
  ['UserInvitation']: {
    __typename: 'UserInvitation'
    accessLevel?: GraphQLTypes['AccessLevel'] | undefined
    agencyId?: string | undefined
    brandIds?: Array<string | undefined> | undefined
    company?: GraphQLTypes['Company'] | undefined
    companyId?: string | undefined
    createdAt: string
    email: string
    firstName: string
    id: string
    isAllBrandAccess?: boolean | undefined
    lastName: string
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    role?: GraphQLTypes['Role'] | undefined
    status: GraphQLTypes['UserInvitationStatus']
    updatedAt: string
  }
  ['AccessLevel']: AccessLevel
  ['BenchmarkType']: BenchmarkType
  ['BrandApiType']: BrandApiType
  ['BrandApiVersion']: BrandApiVersion
  ['CampaignCreatorApprovalAction']: CampaignCreatorApprovalAction
  ['CampaignCreatorStatus']: CampaignCreatorStatus
  ['Channel']: Channel
  ['ContentType']: ContentType
  ['ContentUploadType']: ContentUploadType
  ['CreatorApprovalStatus']: CreatorApprovalStatus
  ['CreatorType']: CreatorType
  ['CreditAction']: CreditAction
  ['Currency']: Currency
  ['DraftStatus']: DraftStatus
  ['InvoicePaymentType']: InvoicePaymentType
  ['InvoiceStatus']: InvoiceStatus
  ['ItemClassification']: ItemClassification
  ['ListingBadge']: ListingBadge
  ['ListingFulfillmentType']: ListingFulfillmentType
  ['ModelSearchStringFieldInput']: ModelSearchStringFieldInput
  ['ModelSortDirection']: ModelSortDirection
  ['PaymentTermsType']: PaymentTermsType
  ['PerformanceComparisonMetric']: PerformanceComparisonMetric
  ['PerformanceSummaryTitleType']: PerformanceSummaryTitleType
  ['PricingCreatorType']: PricingCreatorType
  ['ProductCreationSource']: ProductCreationSource
  ['ProductFeedbackStatus']: ProductFeedbackStatus
  ['ProposalGoal']: ProposalGoal
  ['ProposalStatus']: ProposalStatus
  ['RecommendationType']: RecommendationType
  ['Region']: Region
  ['Role']: Role
  ['ScrapeRequestStatus']: ScrapeRequestStatus
  ['Store']: Store
  ['UploadRequestType']: UploadRequestType
  ['UserInvitationStatus']: UserInvitationStatus
  ['UserStatus']: UserStatus
  ['AdjustCampaignStartDateInput']: {
    campaignId?: string | undefined
    newCampaignStartDate?: string | undefined
  }
  ['AdjustCreditInput']: {
    action: GraphQLTypes['CreditAction']
    associatedProposalId?: string | undefined
    id: string
    label?: string | undefined
    numCredits: number
  }
  ['AdjustProductRecommendationVisibilityInput']: {
    id: string
    isVisible: boolean
  }
  ['ApproveContentInput']: {
    userCampaignSocialContentId: string
  }
  ['CreateBrandInput']: {
    amazonBrandStoreUrl?: string | undefined
    brandApis?: Array<GraphQLTypes['BrandApiType']> | undefined
    companyId: string
    id?: string | undefined
    isBrandStoreScrapeEnabled?: boolean | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    logo?: string | undefined
    name: string
    nameAudioKey?: string | undefined
    region: GraphQLTypes['Region']
    walmartScrapeUrl?: string | undefined
  }
  ['CreateProductFromUrlInput']: {
    brandId: string
    productUrl: string
  }
  ['CreateProposalSlideInput']: {
    creatorPricing: Array<GraphQLTypes['CreatorPricingInput']>
    exchangeRate: number
    goal: GraphQLTypes['ProposalGoal']
    productId: string
    ratingGoal?: number | undefined
  }
  ['CreatorPricingInput']: {
    numCreators: number
    price: number
    type: GraphQLTypes['PricingCreatorType']
  }
  ['ModelBrandApiSkKeyConditionInput']: {
    beginsWith?: GraphQLTypes['BrandApiType'] | undefined
    between?: Array<GraphQLTypes['BrandApiType'] | undefined> | undefined
    eq?: GraphQLTypes['BrandApiType'] | undefined
    ge?: GraphQLTypes['BrandApiType'] | undefined
    gt?: GraphQLTypes['BrandApiType'] | undefined
    le?: GraphQLTypes['BrandApiType'] | undefined
    lt?: GraphQLTypes['BrandApiType'] | undefined
  }
  ['ModelCreatorPricingSkKeyConditionInput']: {
    beginsWith?: GraphQLTypes['Store'] | undefined
    between?: Array<GraphQLTypes['Store'] | undefined> | undefined
    eq?: GraphQLTypes['Store'] | undefined
    ge?: GraphQLTypes['Store'] | undefined
    gt?: GraphQLTypes['Store'] | undefined
    le?: GraphQLTypes['Store'] | undefined
    lt?: GraphQLTypes['Store'] | undefined
  }
  ['ModelInputCreateAgency']: {
    clientPricingId?: string | undefined
    createdAt?: string | undefined
    id: string
    logo: string
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: GraphQLTypes['PaymentTermsType'] | undefined
    updatedAt?: string | undefined
    websiteUrl: string
  }
  ['ModelInputCreateCampaignBenchmark']: {
    benchmarkGoal: number
    benchmarkType: GraphQLTypes['BenchmarkType']
    benchmarkedProductIds: Array<string>
    campaignId: string
    createdAt?: string | undefined
    skuId: string
    updatedAt?: string | undefined
  }
  ['ModelInputCreateCampaignPerformance']: {
    campaignId: string
    comparisonMetric?: GraphQLTypes['PerformanceComparisonMetric'] | undefined
    comparisonMetricValue?: number | undefined
    createdAt?: string | undefined
    isBsrDataUnavailable?: boolean | undefined
    isBsrVisible?: boolean | undefined
    isTtrDataUnavailable?: boolean | undefined
    isTtrVisible?: boolean | undefined
    isVisible?: boolean | undefined
    promotedProductId: string
    skuId: string
    summaryTitle?: GraphQLTypes['PerformanceSummaryTitleType'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputCreateCampaignPerformanceProduct']: {
    bestSellerRankDataUploaded?: boolean | undefined
    campaignId: string
    comparisonMetric?: GraphQLTypes['PerformanceComparisonMetric'] | undefined
    createdAt?: string | undefined
    id: string
    isCategoryAverage?: boolean | undefined
    isVisible?: boolean | undefined
    productImage?: string | undefined
    productName: string
    productPerformanceRecords?:
      | Array<GraphQLTypes['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']>
      | undefined
    productUrl?: string | undefined
    reviewDataUploaded?: boolean | undefined
    skuId: string
    updatedAt?: string | undefined
  }
  ['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']: {
    date: string
    rating?: number | undefined
    reviewCount?: number | undefined
    salesRank?: number | undefined
  }
  ['ModelInputCreateCompany']: {
    agencyId?: string | undefined
    createdAt?: string | undefined
    id: string
    logo?: string | undefined
    name: string
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: GraphQLTypes['PaymentTermsType'] | undefined
    updatedAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['ModelInputCreatePWProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined
    keywords?: Array<GraphQLTypes['ModelInputCreatePWProductSeoSummaryKeywordsInput']> | undefined
    productSEOSummaryRecords?:
      | Array<GraphQLTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']>
      | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputCreatePWProductSeoSummaryKeywordsInput']: {
    badges?: Array<GraphQLTypes['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']> | undefined
    highestRank?: number | undefined
    impressions: number
    keyword: string
    pageOneWin?: boolean | undefined
    pageOneWinDate?: string | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredStartRank?: number | undefined
    startRank?: number | undefined
  }
  ['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']: {
    badge: GraphQLTypes['ListingBadge']
    date?: string | undefined
  }
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']: {
    categoryRanks?:
      | Array<GraphQLTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']>
      | undefined
    impressions?: number | undefined
    isOutOfStock?: boolean | undefined
    topPageWins?: number | undefined
    weekIndex: number
  }
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: {
    category: string
    rank: number
  }
  ['ModelInputCreatePricing']: {
    createdAt?: string | undefined
    creatorPricing?: Array<GraphQLTypes['ModelInputCreatePricingCreatorPricingInput']> | undefined
    id: string
    store: GraphQLTypes['Store']
    updatedAt?: string | undefined
  }
  ['ModelInputCreatePricingCreatorPricingInput']: {
    price: number
    type: GraphQLTypes['PricingCreatorType']
  }
  ['ModelInputCreateProposal']: {
    benchmarkProducts?: Array<string> | undefined
    billingContacts?: Array<GraphQLTypes['ModelInputCreateProposalBillingContactsInput']> | undefined
    brandId: string
    createdAt?: string | undefined
    createdByUserId?: string | undefined
    creatorPricing?: Array<GraphQLTypes['ModelInputCreateProposalCreatorPricingInput']> | undefined
    creditActionId?: string | undefined
    durationWeeks?: number | undefined
    estimatedAverageRating?: number | undefined
    estimatedUnitsSoldPerMonth?: number | undefined
    estimatedUnitsSoldPerWeek?: number | undefined
    exchangeRate?: number | undefined
    goal: GraphQLTypes['ProposalGoal']
    hiddenEndRating?: boolean | undefined
    hiddenNumReviews?: boolean | undefined
    hiddenPdpTraffic?: boolean | undefined
    hiddenRetailEfficiency?: boolean | undefined
    hiddenSeo?: boolean | undefined
    hiddenSocialContent?: boolean | undefined
    hiddenUgcContent?: boolean | undefined
    hubspotDealId?: string | undefined
    id: string
    invoiceDueDate?: string | undefined
    invoiceId?: string | undefined
    invoiceLink?: string | undefined
    invoiceNumber?: string | undefined
    invoicePONumber?: string | undefined
    invoicePaidDate?: string | undefined
    invoiceStatus?: GraphQLTypes['InvoiceStatus'] | undefined
    isDailyScheduling?: boolean | undefined
    launchDate: string
    liftCloseoutBonus?: number | undefined
    liftNumCreators?: number | undefined
    operatingCost?: number | undefined
    paymentType?: GraphQLTypes['InvoicePaymentType'] | undefined
    productId: string
    productPrice?: number | undefined
    productPriceOverride?: number | undefined
    productRating?: number | undefined
    productRatingCount?: number | undefined
    productVariationSkus?: Array<string> | undefined
    ratingGoal?: number | undefined
    referralCost?: number | undefined
    searchTerms?: Array<string> | undefined
    status: GraphQLTypes['ProposalStatus']
    statusChangedAt: string
    submittedAt?: string | undefined
    submittedByUserId?: string | undefined
    title?: string | undefined
    totalCredits?: number | undefined
    totalCreditsPaid?: number | undefined
    totalProductCostCredits?: number | undefined
    updatedAt?: string | undefined
    updatedByUserId?: string | undefined
  }
  ['ModelInputCreateProposalBillingContactsInput']: {
    email: string
    name: string
  }
  ['ModelInputCreateProposalCreatorPricingInput']: {
    closeoutBonus?: number | undefined
    contentRequirements?:
      | Array<GraphQLTypes['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']>
      | undefined
    hidden?: boolean | undefined
    numCreators: number
    price: number
    schedule: Array<number>
    type: GraphQLTypes['CreatorType']
  }
  ['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']: {
    bonus: number
    contentType: GraphQLTypes['ContentType']
  }
  ['ModelInputCreateScrapeRequestJob']: {
    brandId?: string | undefined
    createdAt?: string | undefined
    expirationTime: string
    id: string
    message?: string | undefined
    sku?: string | undefined
    skuId?: string | undefined
    status: GraphQLTypes['ScrapeRequestStatus']
    updatedAt?: string | undefined
  }
  ['ModelInputDeleteCampaignPerformance']: {
    campaignId: string
    skuId: string
  }
  ['ModelInputDeleteCampaignPerformanceProduct']: {
    id: string
  }
  ['ModelInputDeletePricing']: {
    id: string
    store: GraphQLTypes['Store']
  }
  ['ModelInputDeleteProposal']: {
    id: string
  }
  ['ModelInputDeleteUserInvitation']: {
    id: string
  }
  ['ModelInputUpdateAgency']: {
    clientPricingId?: string | undefined
    createdAt?: string | undefined
    id: string
    logo?: string | undefined
    name?: string | undefined
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: GraphQLTypes['PaymentTermsType'] | undefined
    updatedAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['ModelInputUpdateCampaignBenchmark']: {
    benchmarkGoal?: number | undefined
    benchmarkType?: GraphQLTypes['BenchmarkType'] | undefined
    benchmarkedProductIds?: Array<string | undefined> | undefined
    campaignId: string
    createdAt?: string | undefined
    skuId: string
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateCampaignPerformance']: {
    campaignId: string
    comparisonMetric?: GraphQLTypes['PerformanceComparisonMetric'] | undefined
    comparisonMetricValue?: number | undefined
    createdAt?: string | undefined
    isBsrDataUnavailable?: boolean | undefined
    isBsrVisible?: boolean | undefined
    isTtrDataUnavailable?: boolean | undefined
    isTtrVisible?: boolean | undefined
    isVisible?: boolean | undefined
    promotedProductId?: string | undefined
    skuId: string
    summaryTitle?: GraphQLTypes['PerformanceSummaryTitleType'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateCampaignPerformanceProduct']: {
    bestSellerRankDataUploaded?: boolean | undefined
    campaignId?: string | undefined
    comparisonMetric?: GraphQLTypes['PerformanceComparisonMetric'] | undefined
    createdAt?: string | undefined
    id: string
    isCategoryAverage?: boolean | undefined
    isVisible?: boolean | undefined
    productImage?: string | undefined
    productName?: string | undefined
    productPerformanceRecords?:
      | Array<GraphQLTypes['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput'] | undefined>
      | undefined
    productUrl?: string | undefined
    reviewDataUploaded?: boolean | undefined
    skuId?: string | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput']: {
    date?: string | undefined
    rating?: number | undefined
    reviewCount?: number | undefined
    salesRank?: number | undefined
  }
  ['ModelInputUpdateCompany']: {
    agencyId?: string | undefined
    createdAt?: string | undefined
    id: string
    logo?: string | undefined
    name?: string | undefined
    paymentTermsCustomNetDays?: number | undefined
    paymentTermsType?: GraphQLTypes['PaymentTermsType'] | undefined
    updatedAt?: string | undefined
    websiteUrl?: string | undefined
  }
  ['ModelInputUpdatePWProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined
    keywords?: Array<GraphQLTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInput'] | undefined> | undefined
    productSEOSummaryRecords?:
      | Array<GraphQLTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput'] | undefined>
      | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInput']: {
    badges?: Array<GraphQLTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput'] | undefined> | undefined
    highestRank?: number | undefined
    impressions?: number | undefined
    keyword?: string | undefined
    pageOneWin?: boolean | undefined
    pageOneWinDate?: string | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredStartRank?: number | undefined
    startRank?: number | undefined
  }
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput']: {
    badge: GraphQLTypes['ListingBadge']
    date?: string | undefined
  }
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput']: {
    categoryRanks?:
      | Array<
          GraphQLTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput'] | undefined
        >
      | undefined
    impressions?: number | undefined
    isOutOfStock?: boolean | undefined
    topPageWins?: number | undefined
    weekIndex?: number | undefined
  }
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: {
    category: string
    rank: number
  }
  ['ModelInputUpdatePricing']: {
    createdAt?: string | undefined
    creatorPricing?: Array<GraphQLTypes['ModelInputUpdatePricingCreatorPricingInput'] | undefined> | undefined
    id: string
    store: GraphQLTypes['Store']
    updatedAt?: string | undefined
  }
  ['ModelInputUpdatePricingCreatorPricingInput']: {
    price?: number | undefined
    type?: GraphQLTypes['PricingCreatorType'] | undefined
  }
  ['ModelInputUpdateProductKeywordSummary']: {
    campaignId: string
    campaignId_keyword?: string | undefined
    createdAt?: string | undefined
    hasAmazonChoiceBadge?: boolean | undefined
    keyword: string
    organicHighestRank?: number | undefined
    organicPageOneWin?: boolean | undefined
    organicPageOneWinDate?: string | undefined
    organicStartRank?: number | undefined
    searchVolume?: number | undefined
    sponsoredHighestRank?: number | undefined
    sponsoredPageOneWin?: boolean | undefined
    sponsoredPageOneWinDate?: string | undefined
    sponsoredStartRank?: number | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateProductSeoSummary']: {
    campaignId: string
    createdAt?: string | undefined
    productSeoSummaryRecords?:
      | Array<GraphQLTypes['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput'] | undefined>
      | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput']: {
    fromDate?: string | undefined
    isOutOfStock?: boolean | undefined
    organicPageOneWins?: number | undefined
    searchVolume?: number | undefined
    sponsoredPageOneWins?: number | undefined
    toDate?: string | undefined
  }
  ['ModelInputUpdateProposal']: {
    benchmarkProducts?: Array<string | undefined> | undefined
    billingContacts?: Array<GraphQLTypes['ModelInputUpdateProposalBillingContactsInput'] | undefined> | undefined
    brandId?: string | undefined
    createdAt?: string | undefined
    createdByUserId?: string | undefined
    creatorPricing?: Array<GraphQLTypes['ModelInputUpdateProposalCreatorPricingInput'] | undefined> | undefined
    creditActionId?: string | undefined
    durationWeeks?: number | undefined
    estimatedAverageRating?: number | undefined
    estimatedUnitsSoldPerMonth?: number | undefined
    estimatedUnitsSoldPerWeek?: number | undefined
    exchangeRate?: number | undefined
    goal?: GraphQLTypes['ProposalGoal'] | undefined
    hiddenEndRating?: boolean | undefined
    hiddenNumReviews?: boolean | undefined
    hiddenPdpTraffic?: boolean | undefined
    hiddenRetailEfficiency?: boolean | undefined
    hiddenSeo?: boolean | undefined
    hiddenSocialContent?: boolean | undefined
    hiddenUgcContent?: boolean | undefined
    hubspotDealId?: string | undefined
    id: string
    invoiceDueDate?: string | undefined
    invoiceId?: string | undefined
    invoiceLink?: string | undefined
    invoiceNumber?: string | undefined
    invoicePONumber?: string | undefined
    invoicePaidDate?: string | undefined
    invoiceStatus?: GraphQLTypes['InvoiceStatus'] | undefined
    isDailyScheduling?: boolean | undefined
    launchDate?: string | undefined
    liftCloseoutBonus?: number | undefined
    liftNumCreators?: number | undefined
    operatingCost?: number | undefined
    paymentType?: GraphQLTypes['InvoicePaymentType'] | undefined
    productId?: string | undefined
    productPrice?: number | undefined
    productPriceOverride?: number | undefined
    productRating?: number | undefined
    productRatingCount?: number | undefined
    productVariationSkus?: Array<string | undefined> | undefined
    ratingGoal?: number | undefined
    referralCost?: number | undefined
    searchTerms?: Array<string | undefined> | undefined
    status?: GraphQLTypes['ProposalStatus'] | undefined
    statusChangedAt?: string | undefined
    submittedAt?: string | undefined
    submittedByUserId?: string | undefined
    title?: string | undefined
    totalCredits?: number | undefined
    totalCreditsPaid?: number | undefined
    totalProductCostCredits?: number | undefined
    updatedAt?: string | undefined
    updatedByUserId?: string | undefined
  }
  ['ModelInputUpdateProposalBillingContactsInput']: {
    email?: string | undefined
    name?: string | undefined
  }
  ['ModelInputUpdateProposalCreatorPricingInput']: {
    closeoutBonus?: number | undefined
    contentRequirements?:
      | Array<GraphQLTypes['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput'] | undefined>
      | undefined
    hidden?: boolean | undefined
    numCreators?: number | undefined
    price?: number | undefined
    schedule?: Array<number | undefined> | undefined
    type?: GraphQLTypes['CreatorType'] | undefined
  }
  ['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput']: {
    bonus: number
    contentType: GraphQLTypes['ContentType']
  }
  ['ModelInputUpdateScrapeRequestJob']: {
    brandId?: string | undefined
    createdAt?: string | undefined
    expirationTime?: string | undefined
    id: string
    message?: string | undefined
    sku?: string | undefined
    skuId?: string | undefined
    status?: GraphQLTypes['ScrapeRequestStatus'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateUser']: {
    accessLevel?: GraphQLTypes['AccessLevel'] | undefined
    createdAt?: string | undefined
    email?: string | undefined
    firstName?: string | undefined
    id: string
    invitationId?: string | undefined
    lastActiveAt?: string | undefined
    lastName?: string | undefined
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    profilePicture?: string | undefined
    role?: GraphQLTypes['Role'] | undefined
    status?: GraphQLTypes['UserStatus'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateUserAgency']: {
    agencyId: string
    createdAt?: string | undefined
    updatedAt?: string | undefined
    userId: string
  }
  ['ModelInputUpdateUserCampaignSocialContent']: {
    createdAt?: string | undefined
    id: string
    isFavorite?: boolean | undefined
    isHidden?: boolean | undefined
    updatedAt?: string | undefined
  }
  ['ModelInputUpdateUserInvitation']: {
    accessLevel?: GraphQLTypes['AccessLevel'] | undefined
    agencyId?: string | undefined
    brandIds?: Array<string | undefined> | undefined
    companyId?: string | undefined
    createdAt?: string | undefined
    email?: string | undefined
    firstName?: string | undefined
    id: string
    isAllBrandAccess?: boolean | undefined
    lastName?: string | undefined
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    role?: GraphQLTypes['Role'] | undefined
    status?: GraphQLTypes['UserInvitationStatus'] | undefined
    updatedAt?: string | undefined
  }
  ['ModelIntKeyConditionInput']: {
    beginsWith?: number | undefined
    between?: Array<number | undefined> | undefined
    eq?: number | undefined
    ge?: number | undefined
    gt?: number | undefined
    le?: number | undefined
    lt?: number | undefined
  }
  ['ModelMomentumInvitationSkKeyConditionInput']: {
    beginsWith?: GraphQLTypes['UserInvitationStatus'] | undefined
    between?: Array<GraphQLTypes['UserInvitationStatus'] | undefined> | undefined
    eq?: GraphQLTypes['UserInvitationStatus'] | undefined
    ge?: GraphQLTypes['UserInvitationStatus'] | undefined
    gt?: GraphQLTypes['UserInvitationStatus'] | undefined
    le?: GraphQLTypes['UserInvitationStatus'] | undefined
    lt?: GraphQLTypes['UserInvitationStatus'] | undefined
  }
  ['ModelProductRecommendationsSkKeyConditionInput']: {
    beginsWith?: GraphQLTypes['RecommendationType'] | undefined
    between?: Array<GraphQLTypes['RecommendationType'] | undefined> | undefined
    eq?: GraphQLTypes['RecommendationType'] | undefined
    ge?: GraphQLTypes['RecommendationType'] | undefined
    gt?: GraphQLTypes['RecommendationType'] | undefined
    le?: GraphQLTypes['RecommendationType'] | undefined
    lt?: GraphQLTypes['RecommendationType'] | undefined
  }
  ['ModelSearchStringInput']: {
    field?: GraphQLTypes['ModelSearchStringFieldInput'] | undefined
    match?: string | undefined
    match_phrase_prefix?: string | undefined
    not?: boolean | undefined
    prefix?: string | undefined
    regexp?: string | undefined
    wildcard?: string | undefined
  }
  ['ModelStringKeyConditionInput']: {
    beginsWith?: string | undefined
    between?: Array<string | undefined> | undefined
    eq?: string | undefined
    ge?: string | undefined
    gt?: string | undefined
    le?: string | undefined
    lt?: string | undefined
  }
  ['ProductInput']: {
    brandId: string
    estimatedAvailabilityDate?: string | undefined
    id?: string | undefined
    image?: string | undefined
    name: string
    price: string
    store: GraphQLTypes['Store']
  }
  ['ProductSeoKeywordUploadRecord']: {
    date: string
    keyword: string
    keywordSales?: number | undefined
    organicRank?: number | undefined
    searchVolume?: number | undefined
    sponsoredRank?: number | undefined
  }
  ['RequestContentEditsInput']: {
    atTime?: number | undefined
    forAssetAtIndex?: number | undefined
    message?: string | undefined
    shouldSendToCreator: boolean
    userCampaignSocialContentId: string
    visibleToUser?: boolean | undefined
  }
  ['RequestExchangeRateInput']: {
    from: GraphQLTypes['Currency']
    to?: GraphQLTypes['Currency'] | undefined
  }
  ['SubmitCampaignCreatorApprovalInput']: {
    action: GraphQLTypes['CampaignCreatorApprovalAction']
    feedback?: string | undefined
    id: string
  }
  ['ToggleContentVisibilityInput']: {
    isHidden: boolean
    userCampaignSocialContentId: string
  }
  ['UpdateBrandInput']: {
    amazonBrandStoreUrl?: string | undefined
    companyId?: string | undefined
    enabledBrandApiTypes?: Array<GraphQLTypes['BrandApiType'] | undefined> | undefined
    id: string
    isBrandStoreScrapeEnabled?: boolean | undefined
    isWalmartScrapeEnabled?: boolean | undefined
    logo?: string | undefined
    name?: string | undefined
    nameAudioKey?: string | undefined
    region?: GraphQLTypes['Region'] | undefined
    subscriptionEndsAt?: string | undefined
    subscriptionStartsAt?: string | undefined
    walmartScrapeUrl?: string | undefined
  }
  ['UpdateManualProductInput']: {
    id: string
    image?: string | undefined
    name?: string | undefined
    price?: string | undefined
    store?: GraphQLTypes['Store'] | undefined
  }
  ['UploadCreatorContentInput']: {
    assetKeys?: Array<string> | undefined
    channel?: GraphQLTypes['Channel'] | undefined
    contentLink?: string | undefined
    contentType?: GraphQLTypes['ContentType'] | undefined
    socialContentId?: string | undefined
    uploadType: GraphQLTypes['ContentUploadType']
    userCampaignId: string
  }
  ['UploadProductSeoDataInput']: {
    campaignId: string
    campaignStartDate: string
    records: Array<GraphQLTypes['ProductSeoKeywordUploadRecord']>
    shouldUpdateSummary?: boolean | undefined
  }
  ['UserInvitationInput']: {
    accessLevel?: GraphQLTypes['AccessLevel'] | undefined
    agencyId?: string | undefined
    brandIds?: Array<string> | undefined
    companyId?: string | undefined
    email: string
    firstName?: string | undefined
    isAllBrandAccess?: boolean | undefined
    lastName?: string | undefined
    phoneNumber?: string | undefined
    primaryBrandId?: string | undefined
    role?: GraphQLTypes['Role'] | undefined
  }
}
export enum AccessLevel {
  FULL_ACCESS = 'FULL_ACCESS',
  VIEW_ONLY = 'VIEW_ONLY'
}
export enum BenchmarkType {
  REVENUE = 'REVENUE',
  TRAFFIC = 'TRAFFIC',
  UNITS_SOLD = 'UNITS_SOLD'
}
export enum BrandApiType {
  amazon_eu_seller = 'amazon_eu_seller',
  amazon_eu_vendor = 'amazon_eu_vendor',
  amazon_fe_seller = 'amazon_fe_seller',
  amazon_fe_vendor = 'amazon_fe_vendor',
  amazon_na_seller = 'amazon_na_seller',
  amazon_na_vendor = 'amazon_na_vendor',
  shopify = 'shopify'
}
export enum BrandApiVersion {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3'
}
export enum CampaignCreatorApprovalAction {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT'
}
export enum CampaignCreatorStatus {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
  IN_PROGRESS = 'IN_PROGRESS'
}
export enum Channel {
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  UGC = 'UGC',
  YOUTUBE = 'YOUTUBE'
}
export enum ContentType {
  IG_POST = 'IG_POST',
  IG_REEL = 'IG_REEL',
  IG_STORY = 'IG_STORY',
  IG_VIDEO = 'IG_VIDEO',
  REVIEW_PHOTO = 'REVIEW_PHOTO',
  REVIEW_TEXT = 'REVIEW_TEXT',
  TT_STORY = 'TT_STORY',
  TT_VIDEO = 'TT_VIDEO',
  UGC_PHOTO = 'UGC_PHOTO',
  UGC_VIDEO = 'UGC_VIDEO',
  YT_SHORT = 'YT_SHORT',
  YT_SHOUTOUT = 'YT_SHOUTOUT',
  YT_VIDEO = 'YT_VIDEO'
}
export enum ContentUploadType {
  FILE = 'FILE',
  LINK = 'LINK'
}
export enum CreatorApprovalStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}
export enum CreatorType {
  ADVOCATE = 'ADVOCATE',
  IG = 'IG',
  PREMIUM_UGC = 'PREMIUM_UGC',
  TT = 'TT',
  UGC = 'UGC',
  YOUTUBE = 'YOUTUBE'
}
export enum CreditAction {
  ADD = 'ADD',
  INFO_INVOICE_ISSUED = 'INFO_INVOICE_ISSUED',
  INFO_INVOICE_NOT_PAID = 'INFO_INVOICE_NOT_PAID',
  INFO_INVOICE_PAID = 'INFO_INVOICE_PAID',
  REMOVE = 'REMOVE'
}
export enum Currency {
  AUD = 'AUD',
  CAD = 'CAD',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  MXN = 'MXN',
  SGD = 'SGD',
  USD = 'USD'
}
export enum DraftStatus {
  APPROVED = 'APPROVED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  REJECTED = 'REJECTED',
  REQUIRES_ADMIN_APPROVAL = 'REQUIRES_ADMIN_APPROVAL',
  REQUIRES_BRAND_APPROVAL = 'REQUIRES_BRAND_APPROVAL',
  REQUIRES_MODERATOR_APPROVAL = 'REQUIRES_MODERATOR_APPROVAL',
  REQUIRES_SYSTEM_APPROVAL = 'REQUIRES_SYSTEM_APPROVAL',
  REQUIRES_USER_CHANGES = 'REQUIRES_USER_CHANGES',
  USER_ACKNOWLEDGED_REJECTED = 'USER_ACKNOWLEDGED_REJECTED'
}
export enum InvoicePaymentType {
  CREDITS = 'CREDITS',
  END_OF_MONTH_INVOICE = 'END_OF_MONTH_INVOICE',
  EXISTING_INVOICE = 'EXISTING_INVOICE',
  NEW_INVOICE = 'NEW_INVOICE'
}
export enum InvoiceStatus {
  NOT_PAID = 'NOT_PAID',
  PAID = 'PAID'
}
export enum ItemClassification {
  BASE_PRODUCT = 'BASE_PRODUCT',
  PRODUCT_BUNDLE = 'PRODUCT_BUNDLE',
  VARIATION_PARENT = 'VARIATION_PARENT'
}
export enum ListingBadge {
  AMAZON_CHOICE = 'AMAZON_CHOICE'
}
export enum ListingFulfillmentType {
  HYBRID = 'HYBRID',
  SELLER = 'SELLER',
  VENDOR = 'VENDOR'
}
export enum ModelSearchStringFieldInput {
  exists = 'exists'
}
export enum ModelSortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}
export enum PaymentTermsType {
  DEFAULT_7_DAYS = 'DEFAULT_7_DAYS',
  NET_CUSTOM = 'NET_CUSTOM'
}
export enum PerformanceComparisonMetric {
  BEST_SELLER_RANK = 'BEST_SELLER_RANK',
  REVIEWS = 'REVIEWS'
}
export enum PerformanceSummaryTitleType {
  CATEGORY = 'CATEGORY',
  SIMILAR_PRODUCT = 'SIMILAR_PRODUCT'
}
export enum PricingCreatorType {
  ADVOCATE = 'ADVOCATE',
  PREMIUM_UGC = 'PREMIUM_UGC',
  SOCIAL = 'SOCIAL',
  UGC = 'UGC'
}
export enum ProductCreationSource {
  BRAND_STORE_SCRAPE = 'BRAND_STORE_SCRAPE',
  CATEGORY_PAGE_SCRAPE = 'CATEGORY_PAGE_SCRAPE',
  MOMENTUM_MANUAL = 'MOMENTUM_MANUAL',
  MOMENTUM_SCRAPE = 'MOMENTUM_SCRAPE',
  SELLER_INTEGRATION = 'SELLER_INTEGRATION',
  VENDOR_INTEGRATION = 'VENDOR_INTEGRATION',
  WESPONSORED_SCRAPE = 'WESPONSORED_SCRAPE'
}
export enum ProductFeedbackStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS'
}
export enum ProposalGoal {
  BOOST_RATING = 'BOOST_RATING',
  EVERGREEN = 'EVERGREEN',
  EXISTING_PRODUCT = 'EXISTING_PRODUCT',
  OTHER = 'OTHER',
  PRODUCT_LAUNCH = 'PRODUCT_LAUNCH'
}
export enum ProposalStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  SUBMITTED = 'SUBMITTED'
}
export enum RecommendationType {
  BOOST_RATING = 'BOOST_RATING',
  PRODUCT_LAUNCH = 'PRODUCT_LAUNCH'
}
export enum Region {
  CA = 'CA',
  DE = 'DE',
  UK = 'UK',
  US = 'US'
}
export enum Role {
  AGENCY = 'AGENCY',
  BILLING = 'BILLING',
  BRAND_MANAGER = 'BRAND_MANAGER',
  ECOMMERCE_MANAGER = 'ECOMMERCE_MANAGER',
  EXECUTIVE = 'EXECUTIVE',
  LEGAL = 'LEGAL',
  OTHER = 'OTHER',
  SOCIAL_MANAGER = 'SOCIAL_MANAGER'
}
export enum ScrapeRequestStatus {
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS'
}
export enum Store {
  amazon = 'amazon',
  amazon_ca = 'amazon_ca',
  amazon_de = 'amazon_de',
  amazon_uk = 'amazon_uk',
  bestBuy = 'bestBuy',
  costco = 'costco',
  dtc = 'dtc',
  kroger = 'kroger',
  other = 'other',
  shopify = 'shopify',
  target = 'target',
  walmart = 'walmart'
}
export enum UploadRequestType {
  UPLOAD_AGENCY_LOGO = 'UPLOAD_AGENCY_LOGO',
  UPLOAD_BRAND_AUDIO = 'UPLOAD_BRAND_AUDIO',
  UPLOAD_BRAND_LOGO = 'UPLOAD_BRAND_LOGO',
  UPLOAD_CAMPAIGN_PRODUCT_IMAGE = 'UPLOAD_CAMPAIGN_PRODUCT_IMAGE',
  UPLOAD_COMPANY_LOGO = 'UPLOAD_COMPANY_LOGO',
  UPLOAD_PRODUCT_IMAGE = 'UPLOAD_PRODUCT_IMAGE',
  UPLOAD_SOCIAL_CONTENT = 'UPLOAD_SOCIAL_CONTENT'
}
export enum UserInvitationStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING'
}
export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

type ZEUS_VARIABLES = {
  ['AccessLevel']: ValueTypes['AccessLevel']
  ['BenchmarkType']: ValueTypes['BenchmarkType']
  ['BrandApiType']: ValueTypes['BrandApiType']
  ['BrandApiVersion']: ValueTypes['BrandApiVersion']
  ['CampaignCreatorApprovalAction']: ValueTypes['CampaignCreatorApprovalAction']
  ['CampaignCreatorStatus']: ValueTypes['CampaignCreatorStatus']
  ['Channel']: ValueTypes['Channel']
  ['ContentType']: ValueTypes['ContentType']
  ['ContentUploadType']: ValueTypes['ContentUploadType']
  ['CreatorApprovalStatus']: ValueTypes['CreatorApprovalStatus']
  ['CreatorType']: ValueTypes['CreatorType']
  ['CreditAction']: ValueTypes['CreditAction']
  ['Currency']: ValueTypes['Currency']
  ['DraftStatus']: ValueTypes['DraftStatus']
  ['InvoicePaymentType']: ValueTypes['InvoicePaymentType']
  ['InvoiceStatus']: ValueTypes['InvoiceStatus']
  ['ItemClassification']: ValueTypes['ItemClassification']
  ['ListingBadge']: ValueTypes['ListingBadge']
  ['ListingFulfillmentType']: ValueTypes['ListingFulfillmentType']
  ['ModelSearchStringFieldInput']: ValueTypes['ModelSearchStringFieldInput']
  ['ModelSortDirection']: ValueTypes['ModelSortDirection']
  ['PaymentTermsType']: ValueTypes['PaymentTermsType']
  ['PerformanceComparisonMetric']: ValueTypes['PerformanceComparisonMetric']
  ['PerformanceSummaryTitleType']: ValueTypes['PerformanceSummaryTitleType']
  ['PricingCreatorType']: ValueTypes['PricingCreatorType']
  ['ProductCreationSource']: ValueTypes['ProductCreationSource']
  ['ProductFeedbackStatus']: ValueTypes['ProductFeedbackStatus']
  ['ProposalGoal']: ValueTypes['ProposalGoal']
  ['ProposalStatus']: ValueTypes['ProposalStatus']
  ['RecommendationType']: ValueTypes['RecommendationType']
  ['Region']: ValueTypes['Region']
  ['Role']: ValueTypes['Role']
  ['ScrapeRequestStatus']: ValueTypes['ScrapeRequestStatus']
  ['Store']: ValueTypes['Store']
  ['UploadRequestType']: ValueTypes['UploadRequestType']
  ['UserInvitationStatus']: ValueTypes['UserInvitationStatus']
  ['UserStatus']: ValueTypes['UserStatus']
  ['AdjustCampaignStartDateInput']: ValueTypes['AdjustCampaignStartDateInput']
  ['AdjustCreditInput']: ValueTypes['AdjustCreditInput']
  ['AdjustProductRecommendationVisibilityInput']: ValueTypes['AdjustProductRecommendationVisibilityInput']
  ['ApproveContentInput']: ValueTypes['ApproveContentInput']
  ['CreateBrandInput']: ValueTypes['CreateBrandInput']
  ['CreateProductFromUrlInput']: ValueTypes['CreateProductFromUrlInput']
  ['CreateProposalSlideInput']: ValueTypes['CreateProposalSlideInput']
  ['CreatorPricingInput']: ValueTypes['CreatorPricingInput']
  ['ModelBrandApiSkKeyConditionInput']: ValueTypes['ModelBrandApiSkKeyConditionInput']
  ['ModelCreatorPricingSkKeyConditionInput']: ValueTypes['ModelCreatorPricingSkKeyConditionInput']
  ['ModelInputCreateAgency']: ValueTypes['ModelInputCreateAgency']
  ['ModelInputCreateCampaignBenchmark']: ValueTypes['ModelInputCreateCampaignBenchmark']
  ['ModelInputCreateCampaignPerformance']: ValueTypes['ModelInputCreateCampaignPerformance']
  ['ModelInputCreateCampaignPerformanceProduct']: ValueTypes['ModelInputCreateCampaignPerformanceProduct']
  ['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']: ValueTypes['ModelInputCreateCampaignPerformanceProductProductPerformanceRecordsInput']
  ['ModelInputCreateCompany']: ValueTypes['ModelInputCreateCompany']
  ['ModelInputCreatePWProductSeoSummary']: ValueTypes['ModelInputCreatePWProductSeoSummary']
  ['ModelInputCreatePWProductSeoSummaryKeywordsInput']: ValueTypes['ModelInputCreatePWProductSeoSummaryKeywordsInput']
  ['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']: ValueTypes['ModelInputCreatePWProductSeoSummaryKeywordsInputBadgesInput']
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']: ValueTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInput']
  ['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: ValueTypes['ModelInputCreatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']
  ['ModelInputCreatePricing']: ValueTypes['ModelInputCreatePricing']
  ['ModelInputCreatePricingCreatorPricingInput']: ValueTypes['ModelInputCreatePricingCreatorPricingInput']
  ['ModelInputCreateProposal']: ValueTypes['ModelInputCreateProposal']
  ['ModelInputCreateProposalBillingContactsInput']: ValueTypes['ModelInputCreateProposalBillingContactsInput']
  ['ModelInputCreateProposalCreatorPricingInput']: ValueTypes['ModelInputCreateProposalCreatorPricingInput']
  ['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']: ValueTypes['ModelInputCreateProposalCreatorPricingInputContentRequirementsInput']
  ['ModelInputCreateScrapeRequestJob']: ValueTypes['ModelInputCreateScrapeRequestJob']
  ['ModelInputDeleteCampaignPerformance']: ValueTypes['ModelInputDeleteCampaignPerformance']
  ['ModelInputDeleteCampaignPerformanceProduct']: ValueTypes['ModelInputDeleteCampaignPerformanceProduct']
  ['ModelInputDeletePricing']: ValueTypes['ModelInputDeletePricing']
  ['ModelInputDeleteProposal']: ValueTypes['ModelInputDeleteProposal']
  ['ModelInputDeleteUserInvitation']: ValueTypes['ModelInputDeleteUserInvitation']
  ['ModelInputUpdateAgency']: ValueTypes['ModelInputUpdateAgency']
  ['ModelInputUpdateCampaignBenchmark']: ValueTypes['ModelInputUpdateCampaignBenchmark']
  ['ModelInputUpdateCampaignPerformance']: ValueTypes['ModelInputUpdateCampaignPerformance']
  ['ModelInputUpdateCampaignPerformanceProduct']: ValueTypes['ModelInputUpdateCampaignPerformanceProduct']
  ['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput']: ValueTypes['ModelInputUpdateCampaignPerformanceProductProductPerformanceRecordsInput']
  ['ModelInputUpdateCompany']: ValueTypes['ModelInputUpdateCompany']
  ['ModelInputUpdatePWProductSeoSummary']: ValueTypes['ModelInputUpdatePWProductSeoSummary']
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInput']: ValueTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInput']
  ['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput']: ValueTypes['ModelInputUpdatePWProductSeoSummaryKeywordsInputBadgesInput']
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput']: ValueTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInput']
  ['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']: ValueTypes['ModelInputUpdatePWProductSeoSummaryProductSEOSummaryRecordsInputCategoryRanksInput']
  ['ModelInputUpdatePricing']: ValueTypes['ModelInputUpdatePricing']
  ['ModelInputUpdatePricingCreatorPricingInput']: ValueTypes['ModelInputUpdatePricingCreatorPricingInput']
  ['ModelInputUpdateProductKeywordSummary']: ValueTypes['ModelInputUpdateProductKeywordSummary']
  ['ModelInputUpdateProductSeoSummary']: ValueTypes['ModelInputUpdateProductSeoSummary']
  ['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput']: ValueTypes['ModelInputUpdateProductSeoSummaryProductSeoSummaryRecordsInput']
  ['ModelInputUpdateProposal']: ValueTypes['ModelInputUpdateProposal']
  ['ModelInputUpdateProposalBillingContactsInput']: ValueTypes['ModelInputUpdateProposalBillingContactsInput']
  ['ModelInputUpdateProposalCreatorPricingInput']: ValueTypes['ModelInputUpdateProposalCreatorPricingInput']
  ['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput']: ValueTypes['ModelInputUpdateProposalCreatorPricingInputContentRequirementsInput']
  ['ModelInputUpdateScrapeRequestJob']: ValueTypes['ModelInputUpdateScrapeRequestJob']
  ['ModelInputUpdateUser']: ValueTypes['ModelInputUpdateUser']
  ['ModelInputUpdateUserAgency']: ValueTypes['ModelInputUpdateUserAgency']
  ['ModelInputUpdateUserCampaignSocialContent']: ValueTypes['ModelInputUpdateUserCampaignSocialContent']
  ['ModelInputUpdateUserInvitation']: ValueTypes['ModelInputUpdateUserInvitation']
  ['ModelIntKeyConditionInput']: ValueTypes['ModelIntKeyConditionInput']
  ['ModelMomentumInvitationSkKeyConditionInput']: ValueTypes['ModelMomentumInvitationSkKeyConditionInput']
  ['ModelProductRecommendationsSkKeyConditionInput']: ValueTypes['ModelProductRecommendationsSkKeyConditionInput']
  ['ModelSearchStringInput']: ValueTypes['ModelSearchStringInput']
  ['ModelStringKeyConditionInput']: ValueTypes['ModelStringKeyConditionInput']
  ['ProductInput']: ValueTypes['ProductInput']
  ['ProductSeoKeywordUploadRecord']: ValueTypes['ProductSeoKeywordUploadRecord']
  ['RequestContentEditsInput']: ValueTypes['RequestContentEditsInput']
  ['RequestExchangeRateInput']: ValueTypes['RequestExchangeRateInput']
  ['SubmitCampaignCreatorApprovalInput']: ValueTypes['SubmitCampaignCreatorApprovalInput']
  ['ToggleContentVisibilityInput']: ValueTypes['ToggleContentVisibilityInput']
  ['UpdateBrandInput']: ValueTypes['UpdateBrandInput']
  ['UpdateManualProductInput']: ValueTypes['UpdateManualProductInput']
  ['UploadCreatorContentInput']: ValueTypes['UploadCreatorContentInput']
  ['UploadProductSeoDataInput']: ValueTypes['UploadProductSeoDataInput']
  ['UserInvitationInput']: ValueTypes['UserInvitationInput']
}
