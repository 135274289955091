import Metric from '@momentum/components/metric'
import { useBrandContext } from '@momentum/routes/brand/context/BrandContext'
import { useCampaignContext } from '@momentum/routes/campaign/context/CampaignContext'
import { CampaignTimeline } from '@momentum/routes/campaign/overview/proposal/CampaignTimeline'
import { ROUTES, ROUTE_NAMES_CAMPAIGN, ROUTE_NAMES_CAMPAIGN_OVERVIEW } from '@momentum/routes/RouteNames'
import { CreatorTypeLabels } from '@momentum/utils/creatorUtils'
import { errorToast, successToast } from '@momentum/utils/toastUtils'
import { Button, ButtonGroup, Divider, Paper, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { calculateResults, convertToCreatorInputFromProposalCreatorPricing } from '@productwindtom/shared-momentum'
import { InvoiceStatus } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { Form, SubmitButton } from '@productwindtom/ui-base'
import { sumBy } from 'lodash'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { Navigate, generatePath } from 'react-router-dom'
import { getCampaignStartDate } from '../../context/queries'
import { CampaignImpact } from './CampaignImpact'

type EditProposalForm = {
  invoiceStatus?: InvoiceStatus
  invoicePaidDate?: DateTime
  invoiceDueDate?: DateTime
  campaignLaunchDate?: DateTime
  benchmarkProducts?: string[]
  searchTerms?: string[]
}

enum DeliverablesTab {
  IMPACT,
  TIMELINE
}

export const OverviewProposal = () => {
  const [tabSelected, setTabSelected] = useState(DeliverablesTab.IMPACT)
  const {
    brandId,
    campaignDetails: { id, proposal, product, createdAt, anticipatedStartDate, cost },
    updateCampaignProposal,
    adjustCampaignDate
  } = useCampaignContext()
  const { company } = useBrandContext()

  if (!proposal) {
    return (
      <Navigate
        to={generatePath(
          [ROUTES.CAMPAIGN, ROUTE_NAMES_CAMPAIGN.OVERVIEW, ROUTE_NAMES_CAMPAIGN_OVERVIEW.SUMMARY].join('/'),
          { brandId, campaignId: id }
        )}
      />
    )
  }

  const productCost =
    proposal.productPriceOverride || proposal.productPrice || (product?.priceCents ? product.priceCents : 0)

  const results = calculateResults(proposal.goal, {
    creators: convertToCreatorInputFromProposalCreatorPricing(proposal.creatorPricing || []),
    ratingGoal: proposal.ratingGoal,
    productCost,
    exchangeRate: proposal.exchangeRate || 1,
    store: product.store,
    ratingsSummary: {
      rating: proposal.productRating,
      numRatings: proposal.productRatingCount
    },
    estimatedAverageRating: proposal.estimatedAverageRating
  })

  const handleSave = async (values: EditProposalForm) => {
    const currentAnticipatedStartDate = (await getCampaignStartDate(id))?.anticipatedStartDate

    if (currentAnticipatedStartDate !== anticipatedStartDate) {
      errorToast('The current campaign start date is outdated. Please refresh the page and try again.')

      return
    }

    if (
      values.campaignLaunchDate &&
      values.campaignLaunchDate?.toISODate() !== DateTime.fromISO(anticipatedStartDate).toISODate()
    ) {
      await adjustCampaignDate(values.campaignLaunchDate.toISO()!)
    }

    await updateCampaignProposal({
      invoiceStatus: values.invoiceStatus,
      invoicePaidDate: values.invoicePaidDate?.toISODate(),
      invoiceDueDate: values.invoiceDueDate?.toISODate(),
      searchTerms: values.searchTerms,
      benchmarkProducts: values.benchmarkProducts
    })
    successToast('Proposal saved')
  }

  return (
    <Form
      onSubmit={handleSave}
      defaultValues={{
        invoiceStatus: proposal.invoiceStatus,
        invoicePaidDate: proposal.invoicePaidDate ? DateTime.fromISO(proposal.invoicePaidDate) : undefined,
        invoiceDueDate: proposal.invoiceDueDate ? DateTime.fromISO(proposal.invoiceDueDate) : undefined,
        launchDate: proposal.launchDate ? DateTime.fromISO(proposal.launchDate) : undefined,
        campaignLaunchDate: anticipatedStartDate ? DateTime.fromISO(anticipatedStartDate) : undefined,
        benchmarkProducts: proposal.benchmarkProducts || [],
        searchTerms: proposal.searchTerms || []
      }}
    >
      <Stack spacing={5}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant={'h4'}>
            Campaign {tabSelected === DeliverablesTab.IMPACT ? 'impact' : 'timeline'}
          </Typography>
          <ButtonGroup
            color="primary"
            variant="outlined"
            size="medium"
            disableRipple
            disableElevation
            style={{
              alignSelf: 'flex-end'
            }}
          >
            <Button
              variant={tabSelected === DeliverablesTab.IMPACT ? 'contained' : 'outlined'}
              value={DeliverablesTab.IMPACT}
              onClick={() => setTabSelected(DeliverablesTab.IMPACT)}
            >
              Deliverables
            </Button>
            <Button
              variant={tabSelected === DeliverablesTab.TIMELINE ? 'contained' : 'outlined'}
              value={DeliverablesTab.TIMELINE}
              onClick={() => setTabSelected(DeliverablesTab.TIMELINE)}
            >
              Timeline
            </Button>
          </ButtonGroup>
        </Stack>

        <Stack spacing={3}>
          <Paper sx={{ px: 4, py: 2 }}>
            <Stack>
              <Typography variant={'label1'} data-cy="totalCreators">
                {sumBy(proposal.creatorPricing, c => c.numCreators).toLocaleString()} total creators
              </Typography>
              <Stack direction={'row'} justifyContent={'space-evenly'}>
                {proposal.creatorPricing
                  .filter(c => c.numCreators)
                  .map(cp => (
                    <Metric key={cp.type} label={CreatorTypeLabels[cp.type]} value={cp.numCreators?.toLocaleString()} />
                  ))}
              </Stack>
            </Stack>
          </Paper>
          <Stack spacing={3} px={4}>
            {tabSelected === DeliverablesTab.IMPACT && (
              <CampaignImpact proposal={proposal} proposalResults={results} product={product} />
            )}
            {tabSelected === DeliverablesTab.TIMELINE && <CampaignTimeline proposal={proposal} />}

            <Stack>
              <Stack py={2} spacing={1}>
                {!!proposal.submittedBy && (
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant={'label1'}>Campaign created by:</Typography>
                    <Typography variant={'label1'}>
                      {[proposal.submittedBy?.firstName, proposal.submittedBy?.lastName].filter(notEmpty).join(' ')}
                    </Typography>
                  </Stack>
                )}
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'label1'}>Campaign created on: </Typography>
                  <Typography variant={'label1'}>
                    {DateTime.fromISO(createdAt).toLocaleString(DateTime.DATE_SHORT)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'label1'}>Creator cost</Typography>
                  <Typography variant={'label1'}>
                    {((proposal.totalCredits || 0) - (proposal.totalProductCostCredits || 0)).toLocaleString()} credits
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'label1'}>Product cost</Typography>
                  <Typography variant={'label1'}>
                    {proposal.totalProductCostCredits?.toLocaleString()} credits
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
            </Stack>
            <Stack>
              <Stack py={2} direction={'row'} justifyContent={'space-between'}>
                <Typography variant={'h4'}>
                  {company.agencyId ? 'Campaign cost (agency to end client)' : 'Campaign cost'}
                </Typography>

                <Typography variant={'h4'}>{proposal.totalCredits?.toLocaleString()} credits</Typography>
              </Stack>
            </Stack>
            {company.agencyId && (
              <Stack>
                <Stack py={2} direction={'row'} justifyContent={'space-between'}>
                  <Typography variant={'h4'}>Campaign cost (agency to ProductWind)</Typography>
                  <Typography variant={'h4'}>
                    {((proposal.totalProductCostCredits ?? 0) + cost / 100).toLocaleString()} credits
                  </Typography>
                </Stack>
              </Stack>
            )}
            <Divider />
            <Box>
              <SubmitButton variant={'contained'} size={'small'} disableOnDirty>
                Save proposal
              </SubmitButton>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Form>
  )
}
