import { InvoicePaymentType, ProposalGoal } from '@productwindtom/shared-momentum-zeus-types'
import { DateTime } from 'luxon'
import * as yup from 'yup'
import { StringSchema } from 'yup'

export const HUBSPOT_ACCOUNT_ID = '7854176'

const commonFormValidation = {
  brandId: yup.string().required('Required'),
  status: yup.string().required('Required')
}

export const creatorPricingValidation = yup
  .array()
  .of(
    yup.object({
      type: yup.string().required('Required'),
      price: yup.number().required('Required'),
      numCreators: yup.number().optional(),
      socialCreatorPricing: yup
        .array()
        .of(
          yup.object({
            type: yup.string().required('Required'),
            price: yup.number().required('Required'),
            numCreators: yup.number().required('Required')
          })
        )
        .test(
          'numCreators',
          'The number of creators does not match the total creators committed to the client.',
          function (socialCreatorPricing, ctx) {
            const { numCreators } = this.parent

            if (!socialCreatorPricing) return true

            const totalSocialCreators = socialCreatorPricing?.reduce((total, item) => total + item.numCreators, 0)

            if (totalSocialCreators !== numCreators) {
              return ctx.createError({
                message: 'The number of creators does not match the total creators committed to the client.'
              })
            } else {
              return true
            }
          }
        )
    })
  )
  .required('Required')
  .min(1)

const creatorProductsFormValidation = {
  ...commonFormValidation,
  goal: yup.string().required('Required'),
  productId: yup.string().required('Required'),
  exchangeRate: yup.number().required('Required'),
  ratingGoal: yup
    .number()
    .when('goal', {
      is: ProposalGoal.BOOST_RATING,
      then: s => s.required('Required'),
      otherwise: s => s.optional().nullable()
    })
    .max(5, 'Rating cannot be larger than 5.0'),
  estimatedAverageRating: yup.number().optional().nullable().max(5, 'Rating cannot be larger than 5.0'),
  estimatedUnitsSoldPerWeek: yup.number().optional().nullable(),
  estimatedUnitsSoldPerMonth: yup
    .number()
    .moreThan(0, 'You must enter your estimated units sold in order to proceed to the next step.'),
  creatorPricing: creatorPricingValidation
}
export const creatorProductsFormValidationSchema = yup.object(creatorProductsFormValidation)

const timelineFormValidation = {
  ...commonFormValidation,
  launchDate: yup.mixed<DateTime>().optional(),
  creatorPricing: creatorPricingValidation,
  searchTerms: yup.array<StringSchema>().required('Required')
}

export const timelineFormValidationSchema = yup.object(timelineFormValidation)

const reviewFormValidation = {
  ...commonFormValidation,
  paymentType: yup.string<InvoicePaymentType>().optional().nullable(),
  invoiceLink: yup.string().optional().nullable(),
  invoiceDueDate: yup.mixed<DateTime>().optional().nullable(),
  invoiceStatus: yup.string().optional().nullable(),
  invoicePaidDate: yup.mixed<DateTime>().optional().nullable(),
  billingContacts: yup
    .array(
      yup.object({
        name: yup.string(),
        email: yup.string().email('Invalid email')
      })
    )
    .when('paymentType', {
      is: InvoicePaymentType.NEW_INVOICE,
      then: s =>
        s
          .test(
            'min valid',
            'You must enter at least one billing email and contact name in order to schedule the campaign.',
            v => !!v && v.some(r => r.email && r.name)
          )
          .required('Required')
    }),
  hubspotDealLink: yup
    .string()
    .trim()
    .notRequired()
    .url('Invalid URL')
    .matches(
      /*
      Matches the following URLs:
      https://app.hubspot.com/contacts/7854176/deal/{id}
      https://app.hubspot.com/contacts/7854176/record/0-3/{id}
      */
      new RegExp(`https:\/\/app\\.hubspot\\.com\\/contacts\\/${HUBSPOT_ACCOUNT_ID}\/(deal|record\\/0-3)\\/\\d+`),
      'Invalid Hubspot URL'
    )
}
export const reviewFormValidationSchema = yup.object(reviewFormValidation)

export const proposalFormValidation = yup.object({
  ...commonFormValidation,
  ...creatorProductsFormValidation,
  ...timelineFormValidation,
  ...reviewFormValidation
})
