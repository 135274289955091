import { Stack, Typography, Box } from '@mui/material'
import React, { useState, useEffect, useMemo } from 'react'
import { Brand, getBrandWithSubscriptions } from './queries'
import { useUserSessionContext } from '@momentum/contexts/UserSession'
import Loading from '@momentum/components/loading'
import { keyBy } from 'lodash'
import { DateTime } from 'luxon'
import { CreditAction } from '@productwindtom/shared-momentum-zeus-types'
import { gridClasses } from '@mui/x-data-grid-pro'
import { DataGrid } from '@momentum/components/table'
import { tableDefinition, BrandAlert, RowData } from '@momentum/routes/all-subscriptions/tableDefinition'
import { Search } from '@mui/icons-material'
import { FormProvider, useForm } from 'react-hook-form'
import { TextInput, SelectInput, CheckInput } from '@productwindtom/ui-base'

type FilterData = {
  search?: string
  status?: boolean
  alerts: BrandAlert
  hideAgency?: boolean
}

export const AllSubscriptions = () => {
  const { companies, agencies } = useUserSessionContext()
  const [brands, setBrands] = useState<Brand[]>()

  const keyedCompanies = keyBy(companies, 'id')

  useEffect(() => {
    getBrandWithSubscriptions().then(setBrands)
  }, [])

  const methods = useForm<FilterData>({
    mode: 'onChange'
  })

  const { watch } = methods

  const filterData = watch()

  const searchFilter = (filterData.search || '').trim().toLowerCase()
  const now = DateTime.now()

  const mappedBrands = useMemo(() => {
    return (brands || []).map(b => ({
      ...b,
      companyName: keyedCompanies[b.companyId || '']?.name,
      agencyId: keyedCompanies[b.companyId || '']?.agencyId,
      creditsRemaining: getRemainingCredits(b),
      active: !!b.subscriptionEndsAt && DateTime.fromISO(b.subscriptionEndsAt) > now,
      alert: getBrandAlert(b)
    }))
  }, [brands, companies, agencies])

  const filteredBrands = useMemo(
    () => mappedBrands.filter(b => handleFilter(b, { ...filterData, search: searchFilter })),
    [mappedBrands, filterData]
  )

  if (!brands) {
    return <Loading />
  }

  return (
    <Stack pb={4}>
      <Stack mb={5} mt={3} spacing={1}>
        <Typography variant={'h3'} data-cy={'profileHeader'}>
          All subscriptions
        </Typography>
        <Typography variant={'h5'}>Momentum admin</Typography>
      </Stack>

      <Stack spacing={4}>
        <FormProvider {...methods}>
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} spacing={2}>
              <TextInput
                name="search"
                placeholder="Search companies and brands"
                sx={{ width: 325 }}
                InputProps={{
                  endAdornment: <Search />
                }}
              />
              <Box>
                <SelectInput
                  fullWidth
                  name="status"
                  placeholder="Filter by status"
                  sx={{ width: 250 }}
                  options={[
                    { value: true, label: 'Active subscriptions' },
                    { value: false, label: 'Inactive subscriptions' }
                  ]}
                  disableTags
                />
              </Box>
              <Box>
                <SelectInput
                  fullWidth
                  name="alerts"
                  placeholder="Filter by alerts"
                  sx={{ width: 250 }}
                  options={[
                    { value: BrandAlert.ENDING_SOON_HIGH, label: BrandAlert.ENDING_SOON_HIGH },
                    { value: BrandAlert.ENDING_SOON_MEDIUM, label: BrandAlert.ENDING_SOON_MEDIUM }
                  ]}
                  disableTags
                />
              </Box>
            </Stack>
            <CheckInput
              name={'hideAgency'}
              label={'Hide agency brands'}
              checkboxProps={{ size: 'medium' }}
              controlLabelProps={{
                slotProps: {
                  typography: { color: 'grey.A700', variant: 'label3' }
                }
              }}
            />
          </Stack>
        </FormProvider>
        <DataGrid
          autoHeight
          rows={filteredBrands}
          columns={tableDefinition}
          disableColumnMenu={true}
          disableColumnReorder={true}
          pagination
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'active',
                  sort: 'desc'
                },
                {
                  field: 'subscriptionEndsAt',
                  sort: 'asc'
                }
              ]
            },
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          pageSizeOptions={[10, 25, 50]}
          sx={{
            [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
              outline: 'none'
            },
            [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
              outline: 'none'
            },
            [`& .${gridClasses.columnHeader}`]: {
              height: 'auto !important',
              paddingBottom: '8px'
            },
            [`& .${gridClasses.columnHeaderTitle}`]: {
              whiteSpace: 'normal'
            },
            [`& .${gridClasses.columnHeaderTitleContainer}`]: {
              alignItems: 'flex-start'
            },
            [`& .${gridClasses.iconButtonContainer}`]: {
              paddingTop: '4px'
            }
          }}
        />
      </Stack>
    </Stack>
  )
}

const getRemainingCredits = (brand: Brand) => {
  return (brand.subscriptionCredits || []).reduce((total, ele) => {
    const multiplier = ele.action === CreditAction.ADD ? 1 : ele.action === CreditAction.REMOVE ? -1 : 0

    return total + ele.numCredits * multiplier
  }, 0)
}

const getBrandAlert = (brand: Brand) => {
  const now = DateTime.now()
  const thirtyDayDateAlert = now.plus({ days: 45 })
  const sixtyDayDateAlert = now.plus({ days: 90 })
  if (brand.subscriptionEndsAt && DateTime.fromISO(brand.subscriptionEndsAt) > now) {
    if (DateTime.fromISO(brand.subscriptionEndsAt) < thirtyDayDateAlert) {
      return BrandAlert.ENDING_SOON_HIGH
    } else if (DateTime.fromISO(brand.subscriptionEndsAt) < sixtyDayDateAlert) {
      return BrandAlert.ENDING_SOON_MEDIUM
    }
  }
}

const handleFilter = (brand: RowData, filter: FilterData) => {
  if (filter.hideAgency && brand.agencyId) {
    return false
  }

  if (filter.status != null && brand.active !== filter.status) {
    return false
  }

  if (filter.alerts && brand.alert !== filter.alerts) {
    return false
  }
  const searchFilter = (filter.search || '').trim().toLowerCase()

  if (searchFilter) {
    return brand.name.toLowerCase().includes(searchFilter) || brand.companyName?.toLowerCase().includes(searchFilter)
  }
  return true
}
