import { GridColDef } from '@mui/x-data-grid-premium'
import { Brand } from './queries'
import { DateTime } from 'luxon'
import { Chip, Stack, Typography } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export enum BrandAlert {
  ENDING_SOON_HIGH = 'Ending in 45 days',
  ENDING_SOON_MEDIUM = 'Ending in 90 days'
}

export type RowData = Brand & {
  companyName?: string
  agencyId?: string
  creditsRemaining: number
  active: boolean
  alert?: BrandAlert
}

export const tableDefinition: GridColDef<RowData>[] = [
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 1
  },
  {
    field: 'name',
    headerName: 'Brand',
    flex: 1
  },
  {
    field: 'active',
    headerName: 'Status',
    width: 128,
    renderCell: ({ value }) =>
      value ? <Chip label={'Active'} color={'success'} size={'small'} /> : <Chip label={'Inactive'} size={'small'} />
  },
  {
    field: 'alert',
    headerName: 'Alerts',
    width: 160,
    renderCell: ({ value }) => (value ? <AlertCell alert={value} /> : null)
  },
  {
    field: 'subscriptionStartsAt',
    headerName: 'Subscription start date',
    width: 128,
    valueFormatter: value => (value ? DateTime.fromISO(value).toLocaleString() : undefined)
  },
  {
    field: 'subscriptionEndsAt',
    headerName: 'Subscription end date',
    width: 128,
    valueFormatter: value => (value ? DateTime.fromISO(value).toLocaleString() : undefined)
  },
  {
    field: 'creditsRemaining',
    headerName: 'Credits remaining',
    type: 'number',
    align: 'center',
    headerAlign: 'left',
    width: 170
  }
]

const AlertCell = ({ alert }: { alert: BrandAlert }) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <WarningAmberIcon color={'warning'} fontSize={'mSmall'} />
      <Typography variant={'label3'}>{alert}</Typography>
    </Stack>
  )
}
