import { colors } from '@momentum/routes/campaign/e-commerce/performance/productColors'
import { CampaignPerformanceProduct } from '@momentum/routes/campaign/e-commerce/performance/queries'
import { defaultTooltipOptions } from '@momentum/utils/tooltipUtils'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { PerformanceComparisonMetric } from '@productwindtom/shared-momentum-zeus-types'
import { notEmpty } from '@productwindtom/shared-node'
import { first } from 'lodash'
import { Line } from 'react-chartjs-2'
import { useFormContext } from 'react-hook-form'

export const PerformanceGraph = () => {
  const { watch } = useFormContext()
  const theme = useTheme()
  const promotedProductId = watch('promotedProductId')
  const products = watch('products') as CampaignPerformanceProduct[]
  const comparisonMetric = watch('comparisonMetric') as PerformanceComparisonMetric
  const comparisonMetricValue = watch('comparisonMetricValue')

  const promotedProduct = products.find((product: CampaignPerformanceProduct) => product.id === promotedProductId)

  if (!promotedProduct) {
    return null
  }

  const recordSets = products
    .filter(p => p.id === promotedProductId || !p.comparisonMetric || p.comparisonMetric === comparisonMetric)
    .map(p => {
      const startingIndex = p.productPerformanceRecords.findIndex(record =>
        comparisonMetric === PerformanceComparisonMetric.REVIEWS ? record.reviewCount != null : record.salesRank != null
      )
      if (startingIndex >= 0 && comparisonMetric && comparisonMetricValue) {
        let endingIndex: number | undefined
        if (comparisonMetric === PerformanceComparisonMetric.REVIEWS) {
          endingIndex = p.productPerformanceRecords.findIndex(
            r => r.reviewCount && r.reviewCount >= comparisonMetricValue
          )
        } else if (comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK) {
          endingIndex = p.productPerformanceRecords.findIndex(r => r.salesRank && r.salesRank <= comparisonMetricValue)
        }

        return p.productPerformanceRecords
          .slice(startingIndex, endingIndex != null && endingIndex >= 1 ? endingIndex + 1 : undefined)
          .map(r => (comparisonMetric === PerformanceComparisonMetric.REVIEWS ? r.reviewCount : r.salesRank))
      }
    })

  //Promoted product
  const minDays = first(recordSets)?.length || 0

  const labels = Array.from({ length: minDays }, (_, i) => `Day ${i + 1}`)
  const datasets = recordSets.map((records, index) =>
    records
      ? {
          borderColor: theme.palette[colors[index % 5]!].main,
          pointBorderColor: theme.palette[colors[index % 5]!].main,
          pointBackgroundColor: theme.palette[colors[index % 5]!].main,
          data: records,
          hidden: !products[index].isVisible
        }
      : undefined
  )

  return (
    <Stack>
      <Box>
        <Typography variant={'label1'}>
          Time to {comparisonMetricValue?.toLocaleString()}{' '}
          {comparisonMetric === PerformanceComparisonMetric.REVIEWS ? 'reviews' : 'best seller rank'}
        </Typography>
      </Box>
      <Box position={'relative'}>
        <Line
          data={{
            labels: labels,
            datasets: datasets.filter(notEmpty)
          }}
          options={{
            elements: { point: { radius: 0, hitRadius: 10 } },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                ...defaultTooltipOptions,
                callbacks: {
                  label: item => {
                    const value = item.formattedValue
                    return `${value}`
                  },
                  title: () => '',
                  footer: () => ''
                }
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  drawOnChartArea: false,
                  drawTicks: false
                },
                reverse: comparisonMetric === PerformanceComparisonMetric.BEST_SELLER_RANK,
                offset: true,
                ticks: {
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightMedium as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  color: 'black',
                  padding: 16
                },
                border: {
                  color: 'black',
                  width: 3
                }
              },
              x: {
                ticks: {
                  minRotation: 45,
                  padding: 16,
                  font: {
                    family: theme.typography.fontFamily as string,
                    weight: theme.typography.fontWeightMedium as number,
                    size: theme.typography.subtitle2.fontSize as number
                  },
                  color: 'black'
                },
                grid: {
                  drawOnChartArea: false,
                  drawTicks: false
                },
                border: {
                  color: 'black',
                  width: 3
                }
              }
            }
          }}
          height={'560px'}
        />
      </Box>
    </Stack>
  )
}
